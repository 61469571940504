import TagManager from 'react-gtm-module'
import { EVENT_TYPE } from './constants'
import { ErrorPrescriptionContextData, PaymentContextData, SubscriptionContextData } from './types'

const TAG_MANAGER_CONFIG = {
  gtmId: process.env.REACT_APP_GA_GTM_ID || '',
  auth: process.env.REACT_APP_GA_GTM_AUTH || '',
  preview: process.env.REACT_APP_GA_GTM_PREVIEW || '',
}

class GoogleAnalyticsTagManager {
  constructor() {
    TagManager.initialize(TAG_MANAGER_CONFIG)
  }

  sendLoginEvent() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        type: EVENT_TYPE.ACTION,
        name: 'login',
      },
    })
  }

  sendSignUpEvent() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'sign_up',
        type: EVENT_TYPE.ACTION,
        name: 'sign up',
      },
    })
  }

  // should be monitored
  sendCancelSubscriptionEvent(contextData: SubscriptionContextData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancel_subscription',
        type: EVENT_TYPE.ACTION,
        name: 'cancel subscription',
        contextData,
      },
    })
  }

  sendGetStartedPaymentEvent(contextData: PaymentContextData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'get_started_payment',
        type: EVENT_TYPE.ACTION,
        name: 'get started payment',
        contextData,
      },
    })
  }

  sendFinishOnboardingEvent(contextData: SubscriptionContextData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'onboarded',
        type: EVENT_TYPE.ACTION,
        name: 'onboarded',
        contextData,
      },
    })
  }

  // TODO: the event should be used in code
  sendPaySubscriptionEvent(contextData: SubscriptionContextData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pay_subscription',
        type: EVENT_TYPE.ACTION,
        name: 'paid',
        contextData,
      },
    })
  }

  sendCreatePrescriptionEvent(contextData: SubscriptionContextData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'create_prescription',
        type: EVENT_TYPE.ACTION,
        name: 'create prescription',
        contextData,
      },
    })
  }

  sendPrescriptionCreationErrorEvent(contextData: ErrorPrescriptionContextData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'prescription_creation_error',
        type: EVENT_TYPE.ACTION,
        name: 'prescription error',
        contextData,
      },
    })
  }
}

export default new GoogleAnalyticsTagManager()
