const rxpadAgreementContent = `<!DOCTYPE html>
<html lang="en-AU">
	<head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
		<meta charset="utf-8" />
		<title></title>
		<style>
			body { font-family:Arial; font-size:10pt }
			h1, h2, h3, h4, h5, h6, p { margin:0pt }
			li { margin-top:0pt; margin-bottom:0pt }
			h1 { margin-top:12pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; font-family:Calibri; font-size:11pt; font-weight:bold; color:#000000 }
			h2, 1, .AttributeHeading2, .Bold14, .H23, .Head2, .Header2, .Heading2Para2, .L20, .Level2Head, .Listlevel2, .Para2, .Section, .Sub-heading, .UNDERRUBRIK1-2, .body, .h20, .h2, .h2mainheading, .h2H2, .h21, .h22, .heading20, .heading2TOC, .l2, .list20, .list2, .p, .sub-para, .test { margin-left:28.35pt; margin-bottom:0pt; text-indent:-28.35pt; page-break-inside:auto; page-break-after:avoid; font-family:Arial; font-size:10pt; font-weight:bold; color:#000000 }
			h3, .Alt3, .Bold12, .CSub-SubItalic, .CSub-SubItalic1, .H30, .H310, .Head3, .Head31, .Head32, .Heading3a, .L3, .Level1-1, .Level1-2, .Level3, .Para3, .Sub2Para, .a, .h3, .h3subheading, .h31, .h32, .proj3, .proj31, .proj32, .proj33, .proj34, .proj35, .proj36, .proj37, .proj38, .proj39 { margin-top:2pt; margin-left:56.7pt; margin-bottom:0pt; text-indent:-28.35pt; page-break-inside:avoid; page-break-after:avoid; font-family:'Calibri Light'; font-size:12pt; font-weight:normal; color:#000000 }
			h4, .Alt4, .Alt41, .Alt411, .Alt412, .Alt42, .Alt421, .Alt43, .Alt431, .Alt44, .Alt45, .Alt46, .i0, .DSub-SubPlain, .H40, .H410, .H411, .H412, .H42, .H421, .H43, .H431, .H44, .H45, .H46, .ProposalTitle, .bb, .bl, .bullet, .h, .h4, .h4subsubheading, .h41, .i { margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-family:Calibri; font-size:11pt; font-weight:normal; font-style:normal; color:#000000 }
			h5 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; font-family:'Calibri Light'; font-size:10pt; font-weight:normal; color:#2e74b5 }
			h6 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; font-family:'Calibri Light'; font-size:10pt; font-weight:normal; color:#1f4d78 }
			.Heading7 { margin-top:0pt; margin-left:56.7pt; margin-bottom:12pt; text-indent:-28.35pt; page-break-inside:auto; page-break-after:avoid; border-top:0.75pt solid #000000; padding-top:6pt; font-family:'Arial Bold'; font-size:14pt; font-weight:bold; font-style:normal; color:#195988 }
			.Heading8 { margin-top:0pt; margin-bottom:12pt; text-indent:28.35pt; page-break-inside:auto; page-break-after:avoid; border-top:0.75pt solid #000000; padding-top:6pt; font-family:'Arial Bold'; font-size:14pt; font-weight:bold; color:#195988 }
			.Heading9 { margin-left:56.7pt; margin-bottom:12pt; text-indent:-56.7pt; page-break-inside:auto; page-break-after:avoid; font-family:Arial; font-size:14pt; font-weight:bold; font-style:normal; color:#195988 }
			.BalloonText { margin-bottom:0pt; font-family:'Segoe UI'; font-size:9pt }
			.BalloonTextPHPDOCX { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX0 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX1 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX10 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX2 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX3 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX4 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX5 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX6 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX7 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX8 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BalloonTextPHPDOCX9 { margin-bottom:0pt; font-family:Tahoma; font-size:8pt }
			.BodyText { margin-bottom:6pt; font-family:Arial; font-size:10pt }
			.CCPGDratingnotes { margin-top:6pt; margin-bottom:6pt; line-height:12pt; font-family:Calibri; font-size:9pt; color:#ff0000 }
			.CCPGHeading { margin-top:10pt; margin-bottom:0pt; page-break-inside:auto; page-break-after:avoid; line-height:120%; font-family:Calibri; font-size:16pt; font-weight:bold; color:#ed7d31 }
			.CCPGTitle { margin-top:10pt; margin-bottom:0pt; text-align:center; page-break-after:avoid; line-height:120%; font-family:Calibri; font-size:16pt; font-weight:bold; color:#ed7d31 }
			.CommentSubject { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.CommentText { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.DeedBackground { margin-top:6pt; margin-left:46.35pt; margin-bottom:0pt; text-indent:-18pt; line-height:120%; font-family:Calibri; font-size:11pt }
			.Footer { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.Header { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.LBCHeading7 { margin-left:85.05pt; margin-bottom:0pt; text-indent:-28.35pt; font-family:Arial; font-size:10pt }
			.LegalVision1 { margin-left:0pt; margin-bottom:3pt; text-indent:0pt; page-break-after:avoid; font-family:Calibri; font-size:10pt; font-weight:bold }
			.LegalVision2 { margin-left:34pt; margin-bottom:3pt; text-indent:-34pt; page-break-after:auto; font-family:Calibri; font-size:9pt; font-weight:bold }
			.LegalVision3 { margin-left:68.05pt; margin-bottom:3pt; text-indent:-34.05pt; font-family:Calibri; font-size:9pt }
			.LegalVision4 { margin-left:102.05pt; margin-bottom:3pt; text-indent:-34pt; text-align:justify; page-break-inside:avoid; font-size:9pt }
			.LegalVision5 { margin-left:0pt; margin-bottom:6pt; text-indent:0pt; text-align:justify; page-break-inside:avoid; font-size:9pt }
			.LegalVision6 { margin-left:0pt; margin-bottom:6pt; text-indent:0pt; text-align:justify; page-break-inside:avoid; font-size:9pt }
			.ListParagraph { margin-left:36pt; margin-bottom:10pt; line-height:115%; font-family:Cambria; font-size:11pt }
			.ListParagraphPHPDOCX { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX0 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX1 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX10 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX2 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX3 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX4 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX5 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX6 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX7 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX8 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.ListParagraphPHPDOCX9 { margin-left:36pt; margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.NormalWeb { margin-top:5pt; margin-bottom:5pt; font-family:Calibri; font-size:12pt }
			.NormalIndent { margin-left:36pt; margin-bottom:4pt; font-family:Calibri; font-size:11pt }
			.Normalbullet1 { margin-left:36pt; margin-bottom:4pt; text-indent:-36pt; font-family:Calibri; font-size:11pt }
			.PrecInstructionNumber { margin-top:3pt; margin-left:14.2pt; margin-bottom:3pt; text-indent:-14.2pt; line-height:12pt; font-family:Arial; font-size:8pt }
			.Revision { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.Standard1 { margin-top:6pt; margin-left:36pt; margin-bottom:6pt; text-indent:-36pt; text-align:justify; font-family:Verdana; font-size:11pt; font-weight:bold }
			.Standard2 { margin-top:6pt; margin-left:36pt; margin-bottom:6pt; text-indent:-36pt; text-align:justify; font-family:Verdana; font-size:11pt }
			.Standard3 { margin-top:6pt; margin-left:72pt; margin-bottom:6pt; text-indent:-36pt; text-align:justify; font-family:Verdana; font-size:11pt }
			.Standard4 { margin-top:6pt; margin-left:108pt; margin-bottom:6pt; text-indent:-36pt; text-align:justify; font-family:Verdana; font-size:11pt }
			.Standard5 { margin-top:6pt; margin-left:144pt; margin-bottom:6pt; text-indent:-36pt; text-align:justify; font-family:Verdana; font-size:11pt }
			.Standard6 { margin-top:6pt; margin-left:180pt; margin-bottom:6pt; text-indent:-36pt; text-align:justify; font-family:Verdana; font-size:11pt }
			.SubtitlePHPDOCX { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX0 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX1 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX10 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX2 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX3 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX4 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX5 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX6 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX7 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX8 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.SubtitlePHPDOCX9 { margin-bottom:0pt; font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			.TOC1 { margin-top:6pt; margin-bottom:0pt; font-family:Calibri; font-size:12pt; font-weight:bold }
			.TOC2 { margin-left:10pt; margin-bottom:0pt; font-family:Calibri; font-size:11pt; font-weight:bold }
			.TOC3 { margin-left:20pt; margin-bottom:0pt; font-family:Calibri; font-size:11pt }
			.TOC4 { margin-left:30pt; margin-bottom:0pt; font-family:Calibri; font-size:10pt }
			.TOC5 { margin-left:40pt; margin-bottom:0pt; font-family:Calibri; font-size:10pt }
			.TOC6 { margin-left:50pt; margin-bottom:0pt; font-family:Calibri; font-size:10pt }
			.TOC7 { margin-left:60pt; margin-bottom:0pt; font-family:Calibri; font-size:10pt }
			.TOC8 { margin-left:70pt; margin-bottom:0pt; font-family:Calibri; font-size:10pt }
			.TOC9 { margin-left:80pt; margin-bottom:0pt; font-family:Calibri; font-size:10pt }
			.TOCHeading { margin-top:24pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%; font-family:Calibri; font-size:14pt; font-weight:normal }
			.TitlePHPDOCX { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX0 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX1 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX10 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX2 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX3 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX4 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX5 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX6 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX7 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX8 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.TitlePHPDOCX9 { margin-bottom:15pt; border-bottom:1pt solid #5b9bd5; padding-bottom:4pt; font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			.annotationsubjectPHPDOCX { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX0 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX1 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX10 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX2 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX3 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX4 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX5 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX6 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX7 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX8 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationsubjectPHPDOCX9 { margin-bottom:0pt; font-family:Arial; font-size:10pt; font-weight:bold }
			.annotationtextPHPDOCX { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX0 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX1 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX10 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX2 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX3 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX4 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX5 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX6 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX7 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX8 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.annotationtextPHPDOCX9 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX0 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX1 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX10 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX2 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX3 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX4 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX5 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX6 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX7 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX8 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.endnoteTextPHPDOCX9 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX0 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX1 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX10 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX2 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX3 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX4 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX5 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX6 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX7 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX8 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.footnoteTextPHPDOCX9 { margin-bottom:0pt; font-family:Arial; font-size:10pt }
			.subsection { margin-top:5pt; margin-bottom:5pt; font-family:'Times New Roman'; font-size:12pt }
			.subsection2 { margin-top:5pt; margin-bottom:5pt; font-family:'Times New Roman'; font-size:12pt }
			span.BalloonTextChar { font-family:'Segoe UI'; font-size:9pt }
			span.BalloonTextCharPHPDOCX { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX0 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX1 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX10 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX2 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX3 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX4 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX5 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX6 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX7 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX8 { font-family:Tahoma; font-size:8pt }
			span.BalloonTextCharPHPDOCX9 { font-family:Tahoma; font-size:8pt }
			span.BodyTextChar { font-family:Arial; font-size:10pt }
			span.CommentReference { font-size:8pt }
			span.CommentSubjectChar { font-family:Arial; font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX0 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX1 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX10 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX2 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX3 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX4 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX5 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX6 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX7 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX8 { font-size:10pt; font-weight:bold }
			span.CommentSubjectCharPHPDOCX9 { font-size:10pt; font-weight:bold }
			span.CommentTextChar { font-family:Arial; font-size:10pt }
			span.CommentTextCharPHPDOCX { font-size:10pt }
			span.CommentTextCharPHPDOCX0 { font-size:10pt }
			span.CommentTextCharPHPDOCX1 { font-size:10pt }
			span.CommentTextCharPHPDOCX10 { font-size:10pt }
			span.CommentTextCharPHPDOCX2 { font-size:10pt }
			span.CommentTextCharPHPDOCX3 { font-size:10pt }
			span.CommentTextCharPHPDOCX4 { font-size:10pt }
			span.CommentTextCharPHPDOCX5 { font-size:10pt }
			span.CommentTextCharPHPDOCX6 { font-size:10pt }
			span.CommentTextCharPHPDOCX7 { font-size:10pt }
			span.CommentTextCharPHPDOCX8 { font-size:10pt }
			span.CommentTextCharPHPDOCX9 { font-size:10pt }
			span.Emphasis, span.CCPGbody { font-family:Calibri; font-size:9pt; font-style:normal }
			span.FollowedHyperlink { text-decoration:underline; color:#954f72 }
			span.Heading1Char { font-family:Calibri; font-weight:bold }
			span.Heading2Char, span. 1Char, span. Char, span.AttributeHeading2Char, span.H2Char0, span.Head2Char, span.Header2Char, span.L2Char0, span.Listlevel2Char, span.Para2Char, span.Sub-headingChar, span.bodyChar, span.h2Char, span.h21Char, span.heading2Char0, span.heading2TOCChar, span.l2Char, span.list2Char0, span.list2Char, span.pChar, span.testChar { font-family:Arial; font-size:10pt; font-weight:bold }
			span.Heading3Char, span.Alt3Char, span. Char, span.mChar, span.CSub-SubItalicChar, span.CSub-SubItalic1Char, span.H3Char0, span.H31Char0, span.Head3Char, span.Head31Char, span.Head32Char, span.Heading3aChar, span.L3Char, span.Level1-2Char, span.Para3Char, span.Sub2ParaChar, span.h3Char, span.h3subheadingChar, span.h31Char, span.h32Char { font-family:'Calibri Light'; font-size:12pt }
			span.Heading4Char, span.Alt4Char, span.Alt41Char, span.Alt42Char, span.iChar0, span.ndsub-clauseChar, span. Char, span.DSub-SubPlainChar, span.H4Char0, span.H41Char0, span.H42Char, span.H43Char, span.H44Char, span.ProposalTitleChar, span.bbChar, span.blChar, span.bulletChar, span.h4Char, span.h4subsubheadingChar, span.h41Char, span.iChar { font-family:Calibri; color:#000000 }
			span.Heading5Char { font-family:'Calibri Light'; font-size:10pt; color:#2e74b5 }
			span.Heading6Char { font-family:'Calibri Light'; font-size:10pt; color:#1f4d78 }
			span.Heading7Char { font-family:'Arial Bold'; font-size:14pt; font-weight:bold; color:#195988 }
			span.Heading8Char { font-family:'Arial Bold'; font-size:14pt; font-weight:bold; color:#195988 }
			span.Heading9Char { font-family:Arial; font-size:14pt; font-weight:bold; color:#195988 }
			span.Hyperlink { text-decoration:underline; color:#0563c1 }
			span.LegalVision3Char { font-size:9pt }
			span.ListParagraphChar { font-family:Cambria }
			span.Mention { color:#2b579a; background-color:#e1dfdd }
			span.Strong, span.Background { font-weight:normal }
			span.SubtitleCarPHPDOCX { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX0 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX1 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX10 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX2 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX3 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX4 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX5 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX6 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX7 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX8 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.SubtitleCarPHPDOCX9 { font-family:'Calibri Light'; font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#5b9bd5 }
			span.TitleCarPHPDOCX { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX0 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX1 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX10 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX2 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX3 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX4 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX5 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX6 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX7 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX8 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.TitleCarPHPDOCX9 { font-family:'Calibri Light'; font-size:26pt; letter-spacing:0.25pt; color:#323e4f }
			span.UnresolvedMention { color:#605e5c; background-color:#e1dfdd }
			span.UnresolvedMention1 { color:#605e5c; background-color:#e1dfdd }
			span.UnresolvedMention2 { color:#605e5c; background-color:#e1dfdd }
			span.UnresolvedMention3 { color:#605e5c; background-color:#e1dfdd }
			span.annotationreferencePHPDOCX { font-size:8pt }
			span.annotationreferencePHPDOCX0 { font-size:8pt }
			span.annotationreferencePHPDOCX1 { font-size:8pt }
			span.annotationreferencePHPDOCX10 { font-size:8pt }
			span.annotationreferencePHPDOCX2 { font-size:8pt }
			span.annotationreferencePHPDOCX3 { font-size:8pt }
			span.annotationreferencePHPDOCX4 { font-size:8pt }
			span.annotationreferencePHPDOCX5 { font-size:8pt }
			span.annotationreferencePHPDOCX6 { font-size:8pt }
			span.annotationreferencePHPDOCX7 { font-size:8pt }
			span.annotationreferencePHPDOCX8 { font-size:8pt }
			span.annotationreferencePHPDOCX9 { font-size:8pt }
			span.endnoteReferencePHPDOCX { vertical-align:super }
			span.endnoteReferencePHPDOCX0 { vertical-align:super }
			span.endnoteReferencePHPDOCX1 { vertical-align:super }
			span.endnoteReferencePHPDOCX10 { vertical-align:super }
			span.endnoteReferencePHPDOCX2 { vertical-align:super }
			span.endnoteReferencePHPDOCX3 { vertical-align:super }
			span.endnoteReferencePHPDOCX4 { vertical-align:super }
			span.endnoteReferencePHPDOCX5 { vertical-align:super }
			span.endnoteReferencePHPDOCX6 { vertical-align:super }
			span.endnoteReferencePHPDOCX7 { vertical-align:super }
			span.endnoteReferencePHPDOCX8 { vertical-align:super }
			span.endnoteReferencePHPDOCX9 { vertical-align:super }
			span.endnoteTextCarPHPDOCX { font-size:10pt }
			span.endnoteTextCarPHPDOCX0 { font-size:10pt }
			span.endnoteTextCarPHPDOCX1 { font-size:10pt }
			span.endnoteTextCarPHPDOCX10 { font-size:10pt }
			span.endnoteTextCarPHPDOCX2 { font-size:10pt }
			span.endnoteTextCarPHPDOCX3 { font-size:10pt }
			span.endnoteTextCarPHPDOCX4 { font-size:10pt }
			span.endnoteTextCarPHPDOCX5 { font-size:10pt }
			span.endnoteTextCarPHPDOCX6 { font-size:10pt }
			span.endnoteTextCarPHPDOCX7 { font-size:10pt }
			span.endnoteTextCarPHPDOCX8 { font-size:10pt }
			span.endnoteTextCarPHPDOCX9 { font-size:10pt }
			span.footnoteReferencePHPDOCX { vertical-align:super }
			span.footnoteReferencePHPDOCX0 { vertical-align:super }
			span.footnoteReferencePHPDOCX1 { vertical-align:super }
			span.footnoteReferencePHPDOCX10 { vertical-align:super }
			span.footnoteReferencePHPDOCX2 { vertical-align:super }
			span.footnoteReferencePHPDOCX3 { vertical-align:super }
			span.footnoteReferencePHPDOCX4 { vertical-align:super }
			span.footnoteReferencePHPDOCX5 { vertical-align:super }
			span.footnoteReferencePHPDOCX6 { vertical-align:super }
			span.footnoteReferencePHPDOCX7 { vertical-align:super }
			span.footnoteReferencePHPDOCX8 { vertical-align:super }
			span.footnoteReferencePHPDOCX9 { vertical-align:super }
			span.footnoteTextCarPHPDOCX { font-size:10pt }
			span.footnoteTextCarPHPDOCX0 { font-size:10pt }
			span.footnoteTextCarPHPDOCX1 { font-size:10pt }
			span.footnoteTextCarPHPDOCX10 { font-size:10pt }
			span.footnoteTextCarPHPDOCX2 { font-size:10pt }
			span.footnoteTextCarPHPDOCX3 { font-size:10pt }
			span.footnoteTextCarPHPDOCX4 { font-size:10pt }
			span.footnoteTextCarPHPDOCX5 { font-size:10pt }
			span.footnoteTextCarPHPDOCX6 { font-size:10pt }
			span.footnoteTextCarPHPDOCX7 { font-size:10pt }
			span.footnoteTextCarPHPDOCX8 { font-size:10pt }
			span.footnoteTextCarPHPDOCX9 { font-size:10pt }
			.awlist1 { list-style:none; counter-reset:awlistcounter1 10 }
			.awlist1 > li:before { content:'2.1' counter(awlistcounter1); counter-increment:awlistcounter1 }
			.awlist2 { list-style:none; counter-reset:awlistcounter2 1 }
			.awlist2 > li:before { content:'8.' counter(awlistcounter2); counter-increment:awlistcounter2 }
		</style>
	</head>
	<body>
		<div>
			<p style="text-align:center; font-size:12pt">
				<strong><span style="font-family:Calibri; color:#ed7d31">RxPad Platform Terms and Conditions</span></strong>
			</p>
			<p style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri">Welcome to RxPad! We provide a cloud-based platform (</span><strong><span style="font-family:Calibri; ">Platform</span></strong><span style="font-family:Calibri">)</span><strong><span style="font-family:Calibri; "> </span></strong><span style="font-family:Calibri">where health professionals can issue electronic prescriptions to their patients.</span>
			</p>
			<p style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri">In these Terms, when we say </span><strong><span style="font-family:Calibri; ">you </span></strong><span style="font-family:Calibri">or </span><strong><span style="font-family:Calibri; ">your</span></strong><span style="font-family:Calibri">, we mean both you and any entity you are authorised to represent (such as your employer). When we say </span><strong><span style="font-family:Calibri; ">we, us,</span></strong><span style="font-family:Calibri"> or </span><strong><span style="font-family:Calibri; ">our</span></strong><span style="font-family:Calibri">, we mean Telecare Australia Pty Ltd</span><strong><span style="font-family:Calibri; "> </span></strong><span style="font-family:Calibri">(ACN 632 722 911). </span>
			</p>
			<p style="margin-top:6pt; margin-bottom:6pt; page-break-after:avoid; font-size:9pt">
				<span style="font-family:Calibri">These Terms form our contract with you, and set out our obligations as a service provider and your obligations as a customer. You cannot use our Services unless you agree to these Terms.</span>
			</p>
			<p style="margin-top:6pt; margin-bottom:6pt; page-break-after:avoid; font-size:9pt">
				<span style="font-family:Calibri">Some capitalised words in these Terms have defined meanings, and each time that word is used in these Terms it has the same meaning. You can find a list of the defined words and their meaning at the end of these Terms.</span>
			</p>
			<p style="margin-top:6pt; margin-bottom:6pt; page-break-after:avoid; font-size:9pt">
				<span style="font-family:Calibri">For questions about these Terms, or to get in touch with us, please email: support@rxpad.com.au. </span>
			</p>
			<p style="margin-top:6pt; margin-bottom:6pt; page-break-after:avoid; border-bottom:1.5pt solid #000000; padding-bottom:1pt; font-size:9pt">
				<span style="font-family:Calibri">&#xa0;</span>
			</p>
			<p style="margin-top:6pt; margin-bottom:6pt; text-align:center; page-break-after:avoid; font-size:9pt">
				<strong><span style="font-family:Calibri; color:#ed7d31">DISCLAIMER</span></strong>
			</p>
			<div style="margin-top:6pt; margin-bottom:6pt; border-bottom:1.5pt solid #000000; clear:both">
				<p style="page-break-after:avoid; padding-bottom:6pt; font-size:9pt">
					<span style="font-family:Calibri">RxPad is a software as a service platform only. By using the Platform, you acknowledge and agree that nothing on the Platform (and anything provided therein) may be taken to be medical advice, or advice about appropriate treatments, medications or prescriptions. The Platform cannot diagnose, treat, cure, or prevent any disease or medical condition. We provide the Platform as a software as a service platform provider for the sole purposes of allowing you to issue electronic prescriptions (</span><strong><span style="font-family:Calibri; ">eScripts</span></strong><span style="font-family:Calibri">)</span><strong><span style="font-family:Calibri; "> </span></strong><span style="font-family:Calibri">to your patients via email or text message. </span>
				</p>
				<p style="page-break-after:avoid; padding-bottom:6pt; font-size:9pt">
					<span style="font-family:Calibri">We do not in any way seek to provide you confirmation that the eScripts generated by the Platform are accurate, correct or complete, nor does the Platform verify the accuracy of any medical advice you provide to your patients. You acknowledge and agree that it is your responsibility to verify, and where necessary, correct, any eScripts generated by the Platform.</span>
				</p>
				<p style="page-break-after:avoid; padding-bottom:1pt; font-size:9pt">
					<span style="font-family:Calibri">&#xa0;</span>
				</p>
			</div>
			<p style="margin-top:6pt; margin-bottom:6pt; text-align:center; page-break-after:avoid; font-size:9pt">
				<strong><span style="font-family:Calibri; color:#ed7d31">OUR DISCLOSURES</span></strong>
			</p>
			<p style="margin-top:4pt; margin-bottom:4pt; font-size:9pt">
				<span style="font-family:Calibri">Please read these Terms carefully before you accept. We draw your attention to the fact that:</span>
			</p>
			<ul style="margin:0pt; padding-left:0pt">
				<li style="margin-top:4pt; margin-left:12.09pt; margin-bottom:4pt; padding-left:3.76pt; font-family:serif; font-size:9pt">
					<span style="font-family:Calibri">we will handle your personal information in accordance with our privacy policy, available at </span><a href="https://www.telecare.com.au/privacy-policy" style="text-decoration:none"><span class="Hyperlink" style="font-family:Calibri">https://www.telecare.com.au/privacy-policy</span></a><span style="font-family:Calibri">;</span>
				</li>
				<li style="margin-top:4pt; margin-left:12.09pt; margin-bottom:4pt; padding-left:3.76pt; font-family:serif; font-size:9pt">
					<span style="font-family:Calibri">subject to your Consumer Law Rights:</span>
					<ul style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
						<li style="margin-top:4pt; margin-left:10.4pt; margin-bottom:4pt; padding-left:3.75pt">
							<span style="font-family:Calibri">our aggregate liability to you for any Liability arising from or in connection with these Terms will be limited to AU$100;</span>
						</li>
						<li style="margin-top:4pt; margin-left:10.4pt; margin-bottom:4pt; padding-left:3.75pt">
							<span style="font-family:Calibri">we will not be liable for Consequential Loss or delays or failures in performance caused or contributed to by an event or circumstance beyond our reasonable control;</span>
						</li>
					</ul>
				</li>
				<li style="margin-top:4pt; margin-left:12.09pt; margin-bottom:4pt; padding-left:3.76pt; font-family:serif; font-size:9pt">
					<span style="font-family:Calibri">we may amend these Terms at any time, by providing written notice to you, and by clicking “I accept” or continuing to use our Platform after the notice or 30 days after notification (whichever date is earlier), you agree to the amended Terms. If you do not agree to the amendment, you may close your Account with effect from the date of the change in these Terms by providing written notice to us; and</span>
				</li>
				<li style="margin-top:4pt; margin-left:12.09pt; margin-bottom:4pt; padding-left:3.76pt; font-family:serif; font-size:9pt">
					<span style="font-family:Calibri">where you engage third parties to operate alongside our Services (for example, any third-party software systems you wish to integrate with our Platform), those third parties are independent of us and you are responsible for (meaning we will not be liable for) the goods or services they provide, unless we expressly agree otherwise under clause 2.8.</span>
				</li>
			</ul>
			<div style="margin-top:6pt; margin-bottom:6pt; border-bottom:1.5pt solid #000000; clear:both">
				<h2 style="margin-left:0pt; text-indent:0pt; page-break-after:auto; padding-bottom:6pt; font-size:9pt">
					<span style="font-family:Calibri">These Terms do not intend to limit your rights and remedies at law, including any of your Consumer Law Rights.</span>
				</h2>
				<h2 style="margin-left:0pt; text-indent:0pt; page-break-after:auto; padding-bottom:1pt; font-size:9pt">
					<span style="font-family:Calibri; font-weight:normal">&#xa0;</span>
				</h2>
			</div>
			<ol style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Engagement and Term
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">1.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">These Terms apply from when you sign up for an Account, until the date on which your Account is terminated in accordance with these Terms. We grant you a right to use our Services for this period of time only (which may be suspended or revoked in accordance with these Terms).</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">1.2</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You must be at least 18 years old to use our Platform and you hold all necessary licenses with relevant regulatory boards and/or authorities necessary to enable you to legally prescribe medication and issue the relevant prescriptions to your patients.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri"><span style="font-weight:normal">1.3</span></span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref140654086"><span style="font-family:Calibri">Variations: </span><span style="font-family:Calibri; font-weight:normal">We may amend these Terms at any time, by providing written notice to you. By clicking “I accept” or continuing to use our Platform after the notice or 30 days after notification (whichever date is earlier), you agree to the amended Terms. If you do not agree to the amendment, you may close your Account with effect from the date of the change in these Terms by providing written notice to us. If you close your Account, you will no longer be able to access our Services (including our Platform) on and from the date of cancellation.</span></a>
			</h2>
			<ol start="2" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Our Services
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal; color:#bfbfbf"><span style="color:#000000">2.1</span></span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref130217422"><span style="font-family:Calibri; font-weight:normal">We provide the following services to you:</span></a>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">access to our Platform; and</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">access to our troubleshooting support, provided at our discretion during business hours as set out on the Platform (</span><span style="font-family:Calibri">Support Services</span><span style="font-family:Calibri; font-weight:normal">),</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:0pt; margin-bottom:6pt; text-indent:28.35pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(collectively, our</span><span style="font-family:Calibri"> Services</span><span style="font-family:Calibri; font-weight:normal">).</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.2</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">If you require Support Services, you may request these by getting in touch with us through our Platform.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.3</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Once you have created an Account on our Platform , you will be able to use the Platform to create prescriptions. Each prescription will be digitally processed as an eScript and delivered to the patient via SMS or email. Typically, this process is completed within approximately one hour after we have received all relevant information. However, please note that there can be delays if the Medicare details or Individual Healthcare Identifier (</span><span style="font-family:Calibri">IHI</span><span style="font-family:Calibri; font-weight:normal">) provided are either missing or incorrect. The IHI is a crucial piece of information needed to generate scripts, particularly if the patient does not possess a Medicare card. Details received beyond our operating hours may be addressed the following business day. We strive to provide efficient service, but it is crucial for you to provide accurate and complete information to avoid any unnecessary delays in patients receiving their eScripts. </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.4</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Once an eScript has been issued via the Platform you may:</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">use the 'Cancel' feature to cancel the eScript before the eScript has been dispensed. For the avoidance of doubt, once the eScript has been dispensed, it cannot be cancelled; and</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">for repeat prescriptions, use the 'Cease' feature to cease the patient's access to the eScript. Unless the 'Cease' feature is used, repeat prescriptions will stay live until all of the remaining repeats have been dispensed or the eScript expires. </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.5</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Unless we agree otherwise, Support Services cannot be used to support any other products or services, and does not include training, installation of software or hardware, software development or the modification, deletion or recovery of data or any on-site services.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.6</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Where we require access to your computer systems in order to provide our Services, you agree to provide us with such access free from risk to the safety of our employees and contractors.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.7</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Our Services do not constitute, and are not a substitute for, medical or risk management advice.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.8</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We will not be responsible for any other services unless expressly set out on in these Terms or on our Platform.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.9</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref130205179"><span style="font-family:Calibri">Additional Services: </span><span style="font-family:Calibri; font-weight:normal">If you require additional services, we may, in our sole discretion, provide such additional services (to be scoped and priced in a separate contract provided by us).</span></a>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">2.10</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref72927150"></a><a id="_Ref89155714"><span style="font-family:Calibri">Beta Services: </span><span style="font-family:Calibri; font-weight:normal">If we provide you with access to any new or beta services, you acknowledge that because of the developmental nature of such services, you use them at your own risk and we have no obligation to maintain or provide error corrections. Any new or beta services we provide you with access to are for evaluation purposes only and not for production use, and we may discontinue those services at any time at our sole discretion.</span></a>
			</h2>
			<ol start="11" class="awlist1" style="margin:0pt; padding-left:0pt">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; text-indent:-28.35pt; line-height:normal; font-family:Calibri; font-size:9pt">
					<span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref135908657"><strong>Third Party Products or Services: </strong></a><a id="_Hlk134055808">Where you engage third parties to operate alongside our Services (for example, any third-party software systems you wish to integrate with our Platform), those third parties are independent of us and you are responsible for (meaning we will not be liable for) the goods or services they provide, unless we expressly agree otherwise under clause 2.8.</a>
				</li>
			</ol>
			<ol start="3" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Account 
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">3.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You must sign up for an Account in order to access and use our Platform.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">3.2</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You may register for an Account using your Google account (</span><span style="font-family:Calibri">Single Sign-On Account</span><span style="font-family:Calibri; font-weight:normal">). If you sign in to your Account using a Single Sign-On Account, you authorise us to access information from your Single Sign-On Account including your name and contact information.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">3.3</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">While you have an Account with us, you agree to:</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">keep your information up-to-date (and ensure it remains true, accurate and complete);</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">keep usernames and passwords secure and confidential, and protect them from misuse or being stolen; and</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(c)</span><span style="width:19.09pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">notify us if you become aware of, or have reason to suspect, any unauthorised access to your Account or any logins linked to your Account.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">3.4</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We may suspend your access to our Services where we reasonably believe there has been any unauthorised access to or use of our Services (such as the unauthorised sharing of login details for our Platform). If we suspend your access to our Services, we will let you know within a reasonable time of doing so, and we will work with you to resolve the matter, or if it cannot be resolved, then we may terminate your Account and your access to our Services will end.</span>
			</h2>
			<ol start="4" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Trial
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">4.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Under these Terms, your use of the Platform is on a free trial basis. </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">4.2</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref140659583"><span style="font-family:Calibri; font-weight:normal">We reserve the right to amend these Terms by revoking the free trial and introducing paid membership tiers for your continued use of our Platform as per clause 1.3.</span></a>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">4.3</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">If clause 4.2 applies and you do not choose a membership tier before the end of the free trial period as notified to you, your access to the Platform will finish at the end of the free trial.</span>
			</h2>
			<ol start="5" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Platform Licence
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">5.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">While you have an Account, we grant you a right to use our Platform (which may be suspended or revoked in accordance with these Terms). This right cannot be passed on or transferred to any other person.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">5.2</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You must not:</span>
			</h2>
			<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.66pt; margin-bottom:6pt; line-height:normal; padding-left:14.04pt; font-family:Calibri; font-size:9pt">
					access or use our Platform in any way that is improper or breaches any laws, infringes any person's rights (for example, intellectual property rights and privacy rights), or gives rise to any civil or criminal liability;&#xa0; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					interfere with or interrupt the supply of our Platform, or any other person's access to or use of our Platform;&#xa0; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.16pt; margin-bottom:6pt; line-height:normal; padding-left:14.54pt; font-family:Calibri; font-size:9pt">
					introduce any viruses or other malicious software code into our Platform;&#xa0; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					use any unauthorised or modified version of our Platform, including but not limited to for the purpose of building similar or competitive software or for the purpose of obtaining unauthorised access to our Platform;
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.83pt; margin-bottom:6pt; line-height:normal; padding-left:13.87pt; font-family:Calibri; font-size:9pt">
					attempt to access any data or log into any server or account that you are not expressly authorised to access;&#xa0; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:41.1pt; margin-bottom:6pt; line-height:normal; padding-left:15.6pt; font-family:Calibri; font-size:9pt">
					use our Platform in any way that involves service bureau use, outsourcing, renting, reselling, sublicensing, concurrent use of a single user login, or time-sharing;
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.59pt; margin-bottom:6pt; line-height:normal; padding-left:14.11pt; font-family:Calibri; font-size:9pt">
					circumvent user authentication or security of any of our networks, accounts or hosts or those of any third party; or 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					access or use our Platform to transmit, publish or communicate material that is, defamatory, offensive, abusive, indecent, menacing, harassing or unwanted.
				</li>
			</ol>
			<ol start="6" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					<a id="_Ref142051742">Referral Program</a>
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">6.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We offer a referral program to all users of our Platform (</span><span style="font-family:Calibri">RxRewards</span><span style="font-family:Calibri; font-weight:normal">), where you may refer new users (</span><span style="font-family:Calibri">Referree</span><span style="font-family:Calibri; font-weight:normal">) to the Platform using the referral code set out in your Account.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">6.2</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Once the Referree has registered on the Platform using your reward code: </span>
			</h2>
			<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.66pt; margin-bottom:6pt; line-height:normal; padding-left:3.69pt; font-family:Calibri; font-size:9pt">
					the Referree will be rewarded with an additional 15 days of free trial beyond the standard trial period offered by our Platform; and
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:3.27pt; font-family:Calibri; font-size:9pt">
					you will receive $20 in credit to your Account,
				</li>
			</ol>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri">together, the </span><strong><span style="font-family:Calibri; ">“Benefits”</span></strong><span style="font-family:Calibri">. </span>
			</p>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">6.3</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">The Benefits are non-transferable, non-exchangeable and hold no cash value. The Benefits can only be redeemed on our Platform and cannot be exchanged for monetary compensation.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">6.4</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You may refer multiple Referrees. </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">6.5</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We reserve the right to disqualify and withhold Benefits if we considered that you have engage in fraudulent activities or attempt to manipulate RxRewards.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">6.6</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We reserve the right to modify, suspend, or terminate RxRewards at any time, with or without notice, for any reason.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">6.7</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">By participating in RxRewards, you agree to abide by these terms and conditions set out in this clause 6.</span>
			</h2>
			<ol start="7" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					<a id="_Ref140487067">Warranties</a>
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">7.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You represent, warrant and agree that:</span>
			</h2>
			<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.66pt; margin-bottom:6pt; line-height:normal; padding-left:14.04pt; font-family:Calibri; font-size:9pt">
					you will promptly notify us of any changes to any of your Professional Registration Details or other qualifications, certifications, licenses and permits necessary for you to use the Platform and/or prescribe medications to patients; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					you will comply with all applicable codes, laws, standards and regulations, including but not limited to:
					<ol style="margin-right:0pt; margin-left:0pt; padding-left:0pt; list-style-type:lower-roman">
						<li class="ListParagraph" style="margin-top:6pt; margin-left:12.65pt; margin-bottom:6pt; line-height:normal; padding-left:13pt; font-family:Calibri; font-size:9pt">
							the Health Practitioner National Law; 
						</li>
						<li class="ListParagraph" style="margin-top:6pt; margin-left:12.65pt; margin-bottom:6pt; line-height:normal; padding-left:13pt; font-family:Calibri; font-size:9pt">
							the Medical Board of Australia Code of Conduct; and 
						</li>
						<li class="ListParagraph" style="margin-top:6pt; margin-left:12.65pt; margin-bottom:6pt; line-height:normal; padding-left:13pt; font-family:Calibri; font-size:9pt">
							any relevant State and Territory Health Records Acts; 
						</li>
					</ol>
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.16pt; margin-bottom:6pt; line-height:normal; padding-left:14.54pt; font-family:Calibri; font-size:9pt">
					you hold all necessary licenses with relevant regulatory boards and/or authorities necessary to enable you to legally prescribe medication and issue prescriptions to patients; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					you will promptly notify us of any breaches of any laws, rules, regulations, industry codes or serious claims for misconduct or professional negligence by you; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.83pt; margin-bottom:6pt; line-height:normal; padding-left:13.87pt; font-family:Calibri; font-size:9pt">
					you will maintain all insurance policies which a reasonably prudent healthcare professional in your position would maintain, including but not limited to professional indemnity cover and public liability insurances; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:41.1pt; margin-bottom:6pt; line-height:normal; padding-left:15.6pt; font-family:Calibri; font-size:9pt">
					when you prescribe medications to patients via the Platform, you will:
					<ol style="margin-right:0pt; margin-left:0pt; padding-left:0pt; list-style-type:lower-roman">
						<li class="ListParagraph" style="margin-top:6pt; margin-left:12.65pt; margin-bottom:6pt; line-height:normal; padding-left:13pt; font-family:Calibri; font-size:9pt">
							ensure all medications are prescribed in accordance with all regulatory and legislative requirements in addition to ethical and professional obligations relevant to the practice of medicine; and
						</li>
						<li class="ListParagraph" style="margin-top:6pt; margin-left:12.65pt; margin-bottom:6pt; line-height:normal; padding-left:13pt; font-family:Calibri; font-size:9pt">
							ensure that the patient understands that the prescription will be sent to them electronically;
						</li>
					</ol>
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.59pt; margin-bottom:6pt; line-height:normal; padding-left:14.11pt; font-family:Calibri; font-size:9pt">
					you are solely responsible for verifying the accuracy of any eScripts generated by the Platform; and
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					you are solely responsible for obtaining your patient's consent to use the Platform for each eScript. 
				</li>
			</ol>
			<ol start="8" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; page-break-after:avoid; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Availability, Disruption and Downtime
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">8.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">While we strive to always make our Services available to you, we do not make any promises that these will be available 100% of the time. Our Services may be disrupted during certain periods, including, for example, as a result of scheduled or emergency maintenance.</span>
			</h2>
			<ol start="2" class="awlist2" style="margin:0pt; padding-left:0pt">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; text-indent:-28.35pt; line-height:normal; font-family:Calibri; font-size:9pt">
					<span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Our Services (including our Platform) may interact with, or be reliant on, products or services provided by third parties, such as cloud hosting service providers. To the maximum extent permitted by law, we are not liable for disruptions or downtime caused or contributed to by these third parties.
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; color:#bfbfbf"><span style="font-weight:normal; color:#000000">8.3</span></span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We will try to provide you with reasonable notice, where possible, of any disruptions to your access to our Services.</span><span style="font-family:Calibri; color:#bfbfbf">&#xa0; </span>
			</h2>
			<ol start="9" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:11.97pt; margin-bottom:6pt; padding-left:16.38pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					<a id="_Ref499016234"></a><a id="_Ref534614947"></a><a id="_Ref130214277">Intellectual Property and Data</a>
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">9.1</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We own all intellectual property rights in our Services (including our Platform). This includes how our Platform looks and functions, as well as our copyrighted works, trademarks, inventions, designs and other intellectual property. You agree not to copy or otherwise misuse our intellectual property without our written permission (for example,</span><span style="font-family:Calibri"> </span><span style="font-family:Calibri; font-weight:normal">to reverse engineer or discover the source code of our intellectual property), and you must not alter or remove any confidentiality, copyright or other ownership notice placed on our Platform.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">9.2</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We may use any feedback or suggestions that you give us in any manner which we see fit (for example, to develop new features), and no benefit will be owed to you as a result of any use by us of your feedback or suggestions. </span>
			</h2>
			<p style="margin-top:6pt; margin-bottom:6pt; widows:0; orphans:0; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Your Data</span></strong>
			</p>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">9.3</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We do not own any of Your Data, but when you enter or upload any of Your Data into our Platform, you grant us the right to access, analyse, backup, copy, store, transmit, and otherwise use Your Data while you have an Account with us (and for a reasonable period of time afterwards). We may use Your Data (or disclose it to third party service providers) to:</span>
			</h2>
			<h3 style="margin-top:6pt; margin-bottom:6pt; page-break-inside:auto; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">supply our Services to you (for example, to enable you to access and use our Services), and otherwise perform our obligations under these Terms;</span>
			</h3>
			<h3 style="margin-top:6pt; margin-bottom:6pt; page-break-inside:auto; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">diagnose problems with our Services;</span>
			</h3>
			<h3 style="margin-top:6pt; margin-bottom:6pt; page-break-inside:auto; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri">(c)</span><span style="width:19.09pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">improve, develop and protect our Services;</span>
			</h3>
			<h3 style="margin-top:6pt; margin-bottom:6pt; page-break-inside:auto; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri">(d)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">send you information we think may be of interest to you based on your marketing preferences;</span>
			</h3>
			<h3 style="margin-top:6pt; margin-bottom:6pt; page-break-inside:auto; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri">(e)</span><span style="width:18.41pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">perform analytics for the purpose of remedying bugs or issues with our Platform; or</span>
			</h3>
			<h3 style="margin-top:6pt; margin-bottom:6pt; page-break-inside:auto; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri">(f)</span><span style="width:20.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">perform our obligations under these Terms (as reasonably required). </span>
			</h3>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">9.4</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You acknowledge and agree that because of the nature of the internet, the processing and transmission of Your Data by us may occur of various networks, and may be transferred unencrypted.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">9.5</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You are responsible for (meaning we are not liable for):</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">the integrity of Your Data on your systems, networks or any device controlled by you; and</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">backing up Your Data.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">9.6</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">When you use our Services, we may create anonymised statistical data from Your Data and usage of our Services (for example, through aggregation). Once anonymised, we own that data and may use it for our own purposes, such as to provide and improve our Services, to develop new services or product offerings, to identify business trends, and for other uses we communicate to you. This may include making such anonymised data publicly available, provided it is not compiled using a sample size small enough to make underlying portions of Your Data identifiable.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">9.7</span><span style="width:16.95pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">If you do not provide Your Data to us, it may impact your ability to receive our Services.</span>
			</h2>
			<ol start="10" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					<a id="_Ref497135650"></a><a id="_Ref499016294"></a><a id="_Ref83644745"></a><a id="_Ref130214282">Confidential Information </a>
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">10.1</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">While using our Services, you may share confidential information with us, and you may become aware of confidential information about us. You agree not to use our confidential information, and to take reasonable steps to protect our confidential information from being disclosed without our permission, and we agree to do the same for your confidential information. This also means making sure that any employees, contractors, professional advisors or agents of ours or yours only have access to confidential information on a 'need-to-know basis' (in other words, the disclosure is absolutely necessary), and that they also agree to not misuse or disclose such confidential information.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">10.2</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">However, either you or we may share confidential information with legal or regulatory authorities if required by law to do so.</span>
			</h2>
			<ol start="11" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					<a id="_Ref140487079">Personal Information</a>
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto">
				<span style="font-family:Calibri; font-size:9pt; font-weight:normal">11.1</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref69392375"></a><a id="_Ref534378674"></a><a id="_Ref535578343"></a><a id="_Ref497135679"><span style="font-family:Calibri; font-size:9pt; font-weight:normal">We collect, hold and disclose and use any Personal Information you provide to us in accordance with our privacy policy, available at </span></a><a href="https://www.telecare.com.au/privacy-policy" style="text-decoration:none"><span class="Hyperlink" style="font-family:Calibri; font-size:9pt; font-weight:normal">https://www.telecare.com.au/privacy-policy</span></a><span style="font-family:Calibri; font-size:9pt; font-weight:normal">, and applicable Privacy Laws.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">11.2</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref140486765"><span style="font-family:Calibri; font-weight:normal">Each Party agrees to comply with the legal requirements of the Australian Privacy Principles as set out in the </span><em><span style="font-family:Calibri; font-weight:normal; ">Privacy Act 1988 (</span></em><span style="font-family:Calibri; font-weight:normal">Cth) (</span><span style="font-family:Calibri">Privacy Act</span><span style="font-family:Calibri; font-weight:normal">) (as if it were an “APP entity” as defined in the Privacy Act) and any other applicable Privacy Laws that may apply to the use of the Platform. Each Party must not (and must procure that its personnel do not) do anything which may cause the other Party to be in breach of Privacy Laws.</span></a><span style="font-family:Calibri; font-weight:normal"> </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">11.3</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref140486766"><span style="font-family:Calibri; font-weight:normal">With respect to any Personal Information that you have access to, or share with us (including via the Platform and Your Data), you must:</span></a><span style="font-family:Calibri; font-weight:normal"> </span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">comply with all Privacy Laws;</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">provide a privacy collection notification to your patients in order to receive consent from your patient to share their Personal Information on the Platform and as required by us as per clause 11.4 and our privacy policy; and</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(c)</span><span style="width:19.09pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">only use the Personal Information in accordance with your patient's instructions and for the sole purpose for which it was disclosed. </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">11.4</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref140654700"><span style="font-family:Calibri; font-weight:normal">We may need to disclose Personal Information to third parties, such as our related companies or our service providers (for example, IT and administrative service providers and our professional advisors).</span></a>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">11.5</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">In respect of any Security Incident, you must (and procure that your personnel must):</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">notify us within 2 business days of becoming aware of the Security Incident;</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">comply with your obligations under Privacy Laws; </span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(c)</span><span style="width:19.09pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">provide us with all information we reasonably request; </span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(d)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">assist and fully cooperate with us, at your cost, in investigating and remedying the Security Incident; and </span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(e)</span><span style="width:18.41pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">take any other action, at your cost, that we reasonably deem necessary in connection with the Security Incident.</span>
			</h2>
			<ol start="12" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Consumer Law Rights
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">12.1</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref535578324"><span style="font-family:Calibri; font-weight:normal">In some jurisdictions, you may have guarantees, rights or other remedies provided by law (</span><span style="font-family:Calibri">Consumer Law Rights</span><span style="font-family:Calibri; font-weight:normal">), and these Terms do not restrict your Consumer Law Rights. We will only be bound by your Consumer Law Rights and the express wording of these Terms. Our liability for a breach of your Consumer Law Rights is, unless the laws of your jurisdiction state otherwise, limited to either resupplying our Services, or paying the cost of having our Services resupplied. </span></a>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">12.2</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">If you accept these Terms in Australia, nothing in these Terms should be interpreted to exclude, restrict or modify the application of, or any rights or remedies you may have under, any part of the Australian Consumer Law (as set out in Schedule 2 of the </span><em><span style="font-family:Calibri; font-weight:normal; ">Competition and Consumer Act 2010</span></em><span style="font-family:Calibri; font-weight:normal"> (Cth)).</span>
			</h2>
			<ol start="13" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Liability
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri"><span style="font-weight:normal">13.1</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">To the maximum extent permitted by law, we will not be liable for, and you release us from liability for, any Liability caused or contributed to by, arising from or in connection with:</span><span style="font-family:Calibri; font-weight:normal">&#xa0; </span>
			</h2>
			<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.66pt; margin-bottom:6pt; line-height:normal; padding-left:14.04pt; font-family:Calibri; font-size:9pt">
					your computing environment (for example, your hardware, software, information technology and telecommunications services and systems); 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					any medical advice you provide to your patients including your provision of eScripts to your patients via the Platform;
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.16pt; margin-bottom:6pt; line-height:normal; padding-left:14.54pt; font-family:Calibri; font-size:9pt">
					your failure to verify the accuracy of any eScripts generated by the Platform; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					your failure to obtain consent to share a patient's Personal Information with us, including via the Platform; and/or
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.83pt; margin-bottom:6pt; line-height:normal; padding-left:13.87pt; font-family:Calibri; font-size:9pt">
					any use of our Services by a person or entity other than you.&#xa0; 
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri"><span style="font-weight:normal">13.2</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref536693759"><span style="font-family:Calibri; font-weight:normal">Regardless of whatever else is stated in these Terms, to the maximum extent permitted by law:</span></a><span style="font-family:Calibri; font-weight:normal">&#xa0; </span>
			</h2>
			<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.66pt; margin-bottom:6pt; line-height:normal; padding-left:14.04pt; font-family:Calibri; font-size:9pt">
					neither we or you are liable for any Consequential Loss; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					a party's liability for any Liability under these Terms will be reduced proportionately to the extent the relevant Liability was caused or contributed to by the actions (or inactions) of the other party, including any failure by the other party to mitigate its loss; and
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.16pt; margin-bottom:6pt; line-height:normal; padding-left:14.54pt; font-family:Calibri; font-size:9pt">
					our aggregate liability to you for any Liability arising from or in connection with these Terms will be limited to AU$100.&#xa0; 
				</li>
			</ol>
			<ol start="14" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					<a id="_Ref136436548">Indemnity</a>
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">14.1</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Despite anything to the contrary, to the maximum extent permitted by law, you are liable for, and agree to indemnify us and hold us harmless in respect of any Liability that we may suffer, incur or otherwise become liable for, arising from or in connection with:</span>
			</h2>
			<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.66pt; margin-bottom:6pt; line-height:normal; padding-left:14.04pt; font-family:Calibri; font-size:9pt">
					any breach by you or your personnel of clauses 7, 10 or 11; 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:43.08pt; margin-bottom:6pt; line-height:normal; padding-left:13.62pt; font-family:Calibri; font-size:9pt">
					your provision of any electronic prescriptions to patients via the Platform, including the medications you prescribe to patients; or 
				</li>
				<li class="ListParagraph" style="margin-top:6pt; margin-left:42.16pt; margin-bottom:6pt; line-height:normal; padding-left:14.54pt; font-family:Calibri; font-size:9pt">
					your failure to verify the accuracy of the eScripts generated by the Platform. 
				</li>
			</ol>
			<ol start="15" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Termination
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">15.1</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">We may terminate these Terms (meaning you will lose access to our Services, including access to your Account) if:</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">you breach these Terms and do not remedy that breach within 14 days of us notifying you of that breach;</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">you breach these Terms and that breach cannot be remedied; or</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(c)</span><span style="width:19.09pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">you experience an insolvency event (including but not limited to bankruptcy, receivership, voluntary administration, liquidation, or entering into creditors' schemes of arrangement).</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">15.2</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref534379180"><span style="font-family:Calibri; font-weight:normal">You may terminate these Terms if:</span></a>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">we breach these Terms and do not remedy that breach within 14 days of you notifying us of that breach; or</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">we breach these Terms and that breach cannot be remedied.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">15.3</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">You may also terminate these Terms at any time by notifying us through your Account or to our email for notices (as set out in clause 16.8), and termination will take effect immediately.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">15.4</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Upon termination of these Terms:</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">you will lose access to our Services, including access to your Account meaning that you will no longer be able to generate eScripts using the Platform;</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">each eScript generated by the Platform prior to the date of termination will continue to be able to be accessed by your patient until the expiry of the eScript, unless earlier processed or cancelled; </span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(c)</span><span style="width:19.09pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">all unused Benefits will become void and non-redeemable; and</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(d)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">we will retain Your Data (including copies) as required by law or regulatory requirements.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; color:#bfbfbf"><span style="font-weight:normal; color:#000000">15.5</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">Termination of these Terms will not affect any other rights or liabilities that we or you may have.</span><span style="font-family:Calibri; font-weight:normal; color:#bfbfbf">&#xa0; </span>
			</h2>
			<ol start="16" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					<a id="_Ref532538569">General</a> 
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri"><span style="font-weight:normal">16.1</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref497124791"></a><a id="_Ref502306326"></a><a id="_Ref516597234"></a><a id="_Ref532538564"></a><a id="_Toc367730119"></a><a id="_Toc368046614"></a><a id="_Toc368046640"><span style="font-family:Calibri">Assignment:</span><span style="font-family:Calibri; font-weight:normal"> You may not transfer or assign these Terms (including any benefits or obligations you have under these Terms) to any third party without our prior written consent. We may assign or transfer these Terms to a third party, or transfer any debt owed by you to us to a debt collector or other third party.</span></a>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">16.2</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Disputes</span><span style="font-family:Calibri; font-weight:normal">: Neither we or you may commence court proceedings relating to any dispute, controversy or claim arising from, or in connection with, these Terms (including any question regarding its existence, validity or termination) (</span><span style="font-family:Calibri">Dispute</span><span style="font-family:Calibri; font-weight:normal">) unless we and you first meet (in good faith) to resolve the Dispute. Nothing in this clause will operate to prevent us or you from seeking urgent injunctive or equitable relief from a court of appropriate jurisdiction. </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; text-indent:0pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">If the Dispute is not resolved at that initial meeting</span><span style="font-family:Calibri; font-weight:normal">&#xa0; </span><span style="font-family:Calibri; font-weight:normal">a party must before commencing court proceedings, refer the matter to mediation, administered by the Australian Disputes Centre in accordance with Australian Disputes Centre Guidelines for Commercial Mediation.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri"><span style="font-weight:normal">16.3</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Events Outside Our Control</span><span style="font-family:Calibri; font-weight:normal">: We will not be liable for any delay or failure to perform our obligations (including our Services), if such delay or failure is caused or contributed to by an event or circumstance beyond our reasonable control.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; color:#bfbfbf"><span style="font-weight:normal; color:#000000">16.4</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Governing law: </span><span style="font-family:Calibri; font-weight:normal">These Terms are governed by the laws of Victoria, and any matter relating to these Terms is to be determined exclusively by the courts in Victoria and any courts entitled to hear appeals from those courts.</span><span style="font-family:Calibri; font-weight:normal; color:#bfbfbf"> </span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">16.5</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Illegal Requests:</span><span style="font-family:Calibri; font-weight:normal"> We reserve the right to refuse any request for or in relation to our Services that we deem inappropriate, unethical, unreasonable, illegal or otherwise non-compliant with these Terms.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri"><span style="font-weight:normal">16.6</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Marketing</span><span style="font-family:Calibri; font-weight:normal">: You agree that we may send you electronic communications about our products and services. You may opt-out at any time by using the unsubscribe function in our electronic communications.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">16.7</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Nature of Legal Relationship: </span><span style="font-family:Calibri; font-weight:normal">These Terms do not create, and should not be interpreted so as to create, a partnership, joint venture, employment or agency relationship between us and you.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto">
				<span style="font-family:Calibri; font-size:9pt"><span style="font-weight:normal">16.8</span></span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><a id="_Ref134053348"><span style="font-family:Calibri; font-size:9pt">Notices:</span><span style="font-family:Calibri; font-size:9pt; font-weight:normal"> Any notice you send to us must be sent to </span></a><span class="Hyperlink" style="font-family:Calibri; font-size:9pt; font-weight:normal"><span class="__cf_email__" data-cfemail="d6a5a3a6a6b9a4a296a4aea6b7b2f8b5b9bbf8b7a3">support@rxpad.com.au</span></span><span style="font-family:Calibri; font-size:9pt; font-weight:normal">. Any notice we send to you will be sent to the email address registered against your Account.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; widows:0; orphans:0; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">16.9</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Publicity:</span><span style="font-family:Calibri; font-weight:normal"> You agree that, subject to your prior written consent, we may advertise or publicise the fact you are a customer of ours, for example on our website or in our promotional material, and you grant us a right to display and use your logo and branding solely for that purpose.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">16.10</span><span style="width:7.83pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Survival</span><span style="font-family:Calibri; font-weight:normal">: Clauses 9 to 14 will survive the termination or expiry of these Terms.</span>
			</h2>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">16.11</span><span style="width:7.83pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri">Third Party Sites:</span><span style="font-family:Calibri; font-weight:normal"> Our Platform may contain links to websites operated by third parties. Unless we tell you otherwise, we do not control, endorse or approve, and are not responsible for, the content on those websites. We recommend that you make your own investigations in relation to the suitability of those websites. If you purchase goods or services from a third party website linked from our Platform, those goods or services are being provided by that third party, not us. We may receive a benefit (which may include a referral fee or a commission) should you visit certain third party websites through a link on our Platform, or for featuring certain goods or services on our Platform. We will make it clear by notice to you which (if any) goods or services, or website links, we receive a benefit from by featuring them on our Platform.</span><span style="font-family:Calibri; font-weight:normal">&#xa0; </span>
			</h2>
			<ol start="17" style="margin:0pt; padding-left:0pt">
				<li style="margin-top:6pt; margin-left:16.53pt; margin-bottom:6pt; padding-left:11.82pt; font-family:Calibri; font-size:9pt; font-weight:bold; color:#ed7d31">
					Definitions 
				</li>
			</ol>
			<h2 style="margin-top:6pt; margin-bottom:6pt; page-break-after:auto; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">17.1</span><span style="width:12.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">In these Terms:</span>
			</h2>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Account </span></strong><span style="font-family:Calibri">means an account accessible to</span><strong><span style="font-family:Calibri; "> </span></strong><span style="font-family:Calibri">the individual or entity who signed up to our Services.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Consequential Loss </span></strong><span style="font-family:Calibri">includes any consequential loss, special or indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.</span><strong><span style="font-family:Calibri; "> </span></strong><span style="font-family:Calibri">However, your obligation to pay us any amounts for access to or use of our Services (including our Platform) will not constitute “Consequential Loss”.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Health Practitioner National Law </span></strong><span style="font-family:Calibri">means the legislation that was enacted in each state and territory of Australia, being the </span><em><span style="font-family:Calibri; ">Health Practitioner Regulation National Law (Victoria) Act 2009</span></em><span style="font-family:Calibri"> in Victoria. </span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Health Records Act </span></strong><span style="font-family:Calibri">means the relevant federal, state and territory legislation or regulation which establishes a framework to protect the privacy of individuals' health information, being the </span><em><span style="font-family:Calibri; ">Health Records Act 2001 (Vic)</span></em><span style="font-family:Calibri"> in Victoria.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Liability </span></strong><span style="font-family:Calibri">means any expense, cost, liability, loss, damage, claim, notice, entitlement, investigation, demand, proceeding or judgment (whether under statute, contract, equity, tort (including negligence), indemnity or otherwise), howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent and whether involving a third party or us or you or otherwise.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Medical Board of Australia Code of Conduct </span></strong><span style="font-family:Calibri">means the code of conduct established by the Australian Medical Association which establishes the professional standards of doctors.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Personal Information</span></strong><span style="font-family:Calibri"> means any information or opinion about an identified individual, or an individual who is reasonably identifiable, whether the information or opinion is true or not, and whether the information or opinion is recorded in a tangible form or not.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt">
				<strong><span style="font-family:Calibri; font-size:9pt; ">Platform</span></strong><span style="font-family:Calibri; font-size:9pt"> means our cloud-based platform, available at </span><a href="https://app.rxpad.com.au/login" style="text-decoration:none"><span class="Hyperlink" style="font-family:Calibri; font-size:9pt">https://app.rxpad.com.au/login</span></a><span style="font-family:Calibri; font-size:9pt"> </span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Privacy Laws </span></strong><span style="font-family:Calibri">means the </span><em><span style="font-family:Calibri; ">Privacy Act 1988</span></em><span style="font-family:Calibri"> (Cth), any applicable Health Records Act and any other applicable laws relating to privacy, including:</span>
			</p>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(a)</span><span style="width:18.58pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><em><span style="font-family:Calibri; font-weight:normal; ">Health Care Identifiers Act 2010</span></em><span style="font-family:Calibri; font-weight:normal"> (Cth); </span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(b)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><em><span style="font-family:Calibri; font-weight:normal; ">Health Care Identifiers Regulations 2010</span></em><span style="font-family:Calibri; font-weight:normal"> (Cth); </span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(c)</span><span style="width:19.09pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><em><span style="font-family:Calibri; font-weight:normal; ">My Health Records Act 2012</span></em><span style="font-family:Calibri; font-weight:normal"> (Cth); and</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(d)</span><span style="width:18.16pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><em><span style="font-family:Calibri; font-weight:normal; ">My Health Records Regulation 2012</span></em><span style="font-family:Calibri; font-weight:normal"> (Cth).</span>
			</h2>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Professional Registration Details </span></strong><span style="font-family:Calibri">means AHPRA registration number, healthcare provider identifier individual number and prescriber number, and any other relevant licence details.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Security Incident </span></strong><span style="font-family:Calibri">means the actual or likely occurrence of any of the following in respect of the Personal information that you (or your personnel) receive or have access to in connection with this Agreement, or otherwise share on the Platform:</span>
			</p>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(e)</span><span style="width:18.41pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">a breach by you (or your personnel) of clause 11.2 or 11.3;</span>
			</h2>
			<h2 style="margin-top:6pt; margin-left:56.7pt; margin-bottom:6pt; font-size:9pt">
				<span style="font-family:Calibri; font-weight:normal">(f)</span><span style="width:20.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="font-family:Calibri; font-weight:normal">an 'eligible data breach' (as that term is defined in the Privacy Act) caused or contributed to by you (or your personnel).</span>
			</h2>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Services</span></strong><span style="font-family:Calibri"> means the services we provide to you, as detailed in clause 2.1.</span>
			</p>
			<p style="margin-top:6pt; margin-left:28.35pt; margin-bottom:6pt; font-size:9pt">
				<strong><span style="font-family:Calibri; ">Your</span></strong><span style="font-family:Calibri"> </span><strong><span style="font-family:Calibri; ">Data</span></strong><span style="font-family:Calibri"> means the information, materials, logos, documents, qualifications and other intellectual property or data supplied by you when receiving our Services or stored by or generated by your use of our Services, including any Personal Information collected, used, disclosed, stored or otherwise handled in connection with our Services. Your Data does not include any data or information that is generated as a result of your usage of our Services that is a back-end or internal output or an output otherwise generally not available to users of our Services.</span><span style="font-family:Calibri">&#xa0;&#xa0; </span><span style="font-family:Calibri">&#xa0;</span>
			</p>
		</div>
</body>
</html>`

export const rxpadAgreement = {
  revisionDate: '8-July-2023',
  contentHTML: rxpadAgreementContent,
}
