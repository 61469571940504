import ApiClient from '../../utils/api/ApiClient'

type AddressDetails = {
  shortAddress: string
  suburb: string
  postcode: string
  state: string
}

export const AddressClient = {
  getAddressSuggestions: async (searchText: string): Promise<string[]> => {
    try {
      const response = await ApiClient.get(`/bff/address/suggestions/${searchText}`)
      return await response.data
    } catch (error) {
      console.error('Error fetching address suggestions:', error)
      return []
    }
  },
  getAddressDetails: async (searchText: string): Promise<AddressDetails> => {
    try {
      const response = await ApiClient.get(`/bff/address/details/${searchText}`)
      return await response.data
    } catch (error) {
      console.error('Error fetching address details:', error)
      return {} as AddressDetails
    }
  },
}
