import { InfoIcon } from '@chakra-ui/icons'
import { VStack, HStack } from '@chakra-ui/react'
import { Text, Link, Button, Input, ButtonGroup } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/typedReduxHooks'
import useApi from '../../../../../common/utils/api/useApi'
import ClickableTooltip from '../../../../../components/shared-components/ClickableTooltip'
import { PROVIDE_EXISTING_SCRIPT_NUMBER_TOOLTIP } from '../../../../../constants'
import { PrescribedItemErrors } from '../../../../../types/prescription'
import {
  createPrescriberScriptNumber,
  CreatePrescriberScriptNumberResult,
} from '../../../ScriptForm/PrescribedItem'
import { setScriptNo } from '../../../ScriptForm/PrescribedItem/PrescribedItemSlice'
import { validateScriptNumber } from '../../utils/validateScriptNumber'

interface ScriptNumberProps {
  formErrors: PrescribedItemErrors
  setFormErrors: Dispatch<SetStateAction<PrescribedItemErrors>>
}

const ScriptNumber: React.FC<ScriptNumberProps> = ({ setFormErrors, formErrors }) => {
  const dispatch = useAppDispatch()
  const { prescriberScriptNumber } = useAppSelector((state) => state.newScript)

  const [scriptNumber, setScriptNumber] = useState('')
  const [isScriptNumberEditMode, setIsScriptNumberEditMode] = useState(false)

  const handleSaveScriptNumber = () => {
    const formattedValue = scriptNumber.trim()
    const errorMessage = validateScriptNumber(formattedValue)
    if (errorMessage) {
      setFormErrors({
        ...formErrors,
        scriptNumber: {
          showError: true,
          message: errorMessage,
        },
      })
      return
    }

    dispatch(setScriptNo(formattedValue))
    setIsScriptNumberEditMode(false)
    setFormErrors({
      ...formErrors,
      scriptNumber: undefined,
    })
  }

  const handleCancel = () => {
    setIsScriptNumberEditMode(false)
    setFormErrors({
      ...formErrors,
      scriptNumber: undefined,
    })
  }

  const { trigger: generatePrescriberScriptNumber, loading } = useApi(
    createPrescriberScriptNumber,
    {
      onSuccess: (data: CreatePrescriberScriptNumberResult) => {
        dispatch(setScriptNo(data.prescriberScriptNumber))
      },
    }
  )

  const handleGeneratePbsDvaCode = async () => {
    await generatePrescriberScriptNumber({})
  }

  const handleEdit = () => {
    setIsScriptNumberEditMode(true)
  }

  return (
    <VStack alignSelf="flex-start" justifySelf="start" alignItems="flex-start">
      <HStack>
        <Button
          onClick={handleGeneratePbsDvaCode}
          isLoading={loading}
          isDisabled={isScriptNumberEditMode}
          flexGrow={1}
        >
          Generate Script No.
        </Button>
        <ClickableTooltip label={PROVIDE_EXISTING_SCRIPT_NUMBER_TOOLTIP} fontSize="sm">
          <InfoIcon color="blue.500" />
        </ClickableTooltip>
        {!isScriptNumberEditMode && <Text fontWeight="bold">{prescriberScriptNumber}</Text>}
      </HStack>
      {isScriptNumberEditMode && (
        <HStack alignSelf="start">
          <Input value={scriptNumber} onChange={(e) => setScriptNumber(e.target.value)} />
          <ButtonGroup variant="outline" spacing="2">
            <Button colorScheme="blue" onClick={handleSaveScriptNumber}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </ButtonGroup>
        </HStack>
      )}
      {formErrors.scriptNumber?.showError && (
        <Text color={'red.500'} fontWeight={500}>
          {formErrors.scriptNumber?.message}
        </Text>
      )}
      <Link as="button" onClick={handleEdit} textDecoration="underline">
        Provide an existing script number
      </Link>
    </VStack>
  )
}

export default ScriptNumber
