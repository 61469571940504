import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { AuthenticationClient } from '../../features/authentication/slice/authenticationSlice'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '',
  withCredentials: true,
})

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('access_token')
    ? `Bearer ${localStorage.getItem('access_token')}`
    : ''

  const prescriberStr = localStorage.getItem('prescriber')
  const prescriberId =
    prescriberStr !== 'undefined' && prescriberStr?.length ? JSON.parse(prescriberStr).id : ''

  ;(config.headers as AxiosRequestHeaders)['Authorization'] = token
  ;(config.headers as AxiosRequestHeaders)['Prescriber-Id'] = prescriberId

  return config
})

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      const { data } = await AuthenticationClient.refreshAccessToken({
        clientId: localStorage.getItem('client_id') || '',
        refreshToken: localStorage.getItem('refresh_token') || '',
      })
      if (data && data.assessToken) {
        localStorage.setItem('access_token', data.assessToken)
        const { config } = error
        config.headers['Authorization'] = 'Bearer ' + data.assessToken
        return axios(config)
      } else {
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

export default instance
