import React, { FC } from 'react'
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form'
import { CustomPrescriptionItem } from './CustomPrescriptionItem'
import { Input, NumberInput, NumberInputField, VStack } from '@chakra-ui/react'
import InputField from '../../../components/InputField'

interface CustomPrescriptionItemWithOptionalId extends Omit<CustomPrescriptionItem, 'id'> {
  id?: string
}

interface CustomPrescriptionItemFormProps {
  register:
    | UseFormRegister<CustomPrescriptionItem>
    | UseFormRegister<CustomPrescriptionItemWithOptionalId>
  errors:
    | FieldErrorsImpl<CustomPrescriptionItem>
    | FieldErrorsImpl<CustomPrescriptionItemWithOptionalId>
}

const CustomPrescriptionItemForm: FC<CustomPrescriptionItemFormProps> = ({ register, errors }) => {
  return (
    <VStack align="stretch" spacing={4}>
      <InputField label="Compound Name" field="compoundName" errors={errors} isRequired>
        <Input
          {...register('compoundName', {
            required: 'Required',
            maxLength: {
              value: 100,
              message: 'Patient name must be less than 100 Characters',
            },
          })}
        ></Input>
      </InputField>
      <InputField
        label="Dosage Form"
        field="dosageForm"
        errors={errors}
        isRequired
        tooltipContent="Form in which drugs can be used (e.g. Tablet, Capsules, Eye drops etc.)"
      >
        <Input
          {...register('dosageForm', {
            required: 'Required',
          })}
        ></Input>
      </InputField>
      <InputField label="Item Strength" field="itemStrength" errors={errors} isRequired>
        <Input
          {...register('itemStrength', {
            required: 'Required',
          })}
        ></Input>
      </InputField>
      <InputField
        label="Route of Administration"
        field="routeOfAdministration"
        errors={errors}
        isRequired
      >
        <Input
          {...register('routeOfAdministration', {
            required: 'Required',
          })}
        ></Input>
      </InputField>
      <InputField label="Quantity" field="quantity" errors={errors} isRequired>
        <NumberInput>
          <NumberInputField
            defaultValue={undefined}
            {...register('quantity', {
              setValueAs: (originalValue) => originalValue || null,
              required: 'Required',
            })}
          />
        </NumberInput>
      </InputField>
      <InputField label="Repeats" field="repeats" errors={errors}>
        <NumberInput>
          <NumberInputField
            {...register('repeats', { setValueAs: (originalValue) => originalValue || null })}
          />
        </NumberInput>
      </InputField>
      <InputField label="PBS Code" field="pbsCode" errors={errors}>
        <Input {...register('pbsCode')}></Input>
      </InputField>
    </VStack>
  )
}

export default CustomPrescriptionItemForm
