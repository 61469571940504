import ApiClient from '../../utils/api/ApiClient'

export interface SearchProductRequest {
  queryTerm: string
  pageOffset?: number
  pageNumber?: number
}

export interface AllergenItem {
  allergenId: string
  allergenName: string
  allergenType: string
}

interface AllergensResponse {
  allergens: AllergenItem[]
}

const searchAllergens = async (request: SearchProductRequest): Promise<AllergensResponse> => {
  const { queryTerm, pageOffset = 30, pageNumber = 1 } = request
  try {
    const response = await ApiClient.get(
      `/experiments/allergens?term=${queryTerm}&pageOffset=${pageOffset}&pageNumber=${pageNumber}`
    )

    return response?.data
  } catch (error) {
    return {
      allergens: [],
    }
  }
}

export const ExperimentsApiClient = {
  searchAllergens,
}
