import { Input } from '@chakra-ui/react'
import React from 'react'
import InputField from '../../components/InputField'

const OrganizationCreationForm: React.FC<any> = ({ register, errors }) => {
  return (
    <>
      <InputField label="Organisation Name" field="name" errors={errors}>
        <Input
          {...register('name', {
            required: 'Please enter organisation name',
          })}
        ></Input>
      </InputField>

      <InputField label="Email" field="email" errors={errors}>
        <Input
          {...register('email', {
            required: 'Please enter email',
          })}
        ></Input>
      </InputField>

      <InputField label="Phone Number" field="phoneNumber" errors={errors}>
        <Input
          {...register('phoneNumber', {
            required: 'Please enter phone number',
          })}
        ></Input>
      </InputField>

      <InputField label="Street Address" field="streetAddress" errors={errors}>
        <Input
          {...register('streetAddress', {
            required: 'Please enter street address',
          })}
        ></Input>
      </InputField>

      <InputField label="Suburb" field="suburb" errors={errors}>
        <Input
          {...register('suburb', {
            required: 'Please enter suburb',
          })}
        ></Input>
      </InputField>

      <InputField label="Postcode" field="postcode" errors={errors}>
        <Input
          {...register('postcode', {
            required: 'Please enter postcode',
          })}
        ></Input>
      </InputField>

      <InputField label="State" field="state" errors={errors}>
        <Input
          {...register('state', {
            required: 'Please enter state',
          })}
        ></Input>
      </InputField>

      <InputField label="HPI-O" field="hpio" errors={errors}>
        <Input
          {...register('hpio', {
            required: 'Please enter HPI-O',
          })}
        ></Input>
      </InputField>

      <InputField label="Administrator Code" field="administratorCode" errors={errors}>
        <Input
          {...register('administratorCode', {
            required: 'Please enter administratorCode',
          })}
        ></Input>
      </InputField>
    </>
  )
}

export default OrganizationCreationForm
