import React, { useRef, useCallback } from 'react'
import * as XLSX from 'xlsx'
import Papa from 'papaparse'
import { Box, Center, Text, useToast, VStack } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

type CSVFileInputProps = {
  onFileLoad: (data: any[]) => void
  children?: React.ReactNode
}

const CSVFileInput: React.FC<CSVFileInputProps> = ({ onFileLoad, children }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const toast = useToast()

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    const fileName = file?.name ?? ''

    const supportFileType = ['.csv', '.xls', 'xlsx']

    const isSupportedFileType = supportFileType.reduce((result, type) => {
      return result || fileName.endsWith(type)
    }, false)

    if (!file || !isSupportedFileType) {
      toast({
        title: 'Please upload a .xlsx or .csv file.',
        status: 'warning',
        duration: 5000,
        position: 'top',
        isClosable: true,
      })
      return
    }
    if (fileName.endsWith('.csv')) {
      Papa.parse(file, {
        complete: (result) => {
          const filiteredData = result.data.filter((data: any) => {
            const valuesOfData: string[] = Object.values(data)
            return !(valuesOfData.length === 1 && /^[ \n]*$/.test(valuesOfData[0]))
          })
          onFileLoad(filiteredData)
        },
        header: true,
      })
      return
    } else {
      const reader = new FileReader()
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const contents = e?.target?.result
        const workbook = XLSX.read(contents, { type: 'binary', dateNF: 'yyyy-mm-dd' })

        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]

        const excelData = XLSX.utils.sheet_to_json(sheet, { raw: false })
        onFileLoad(excelData)
      }
      reader.readAsBinaryString(file)
    }
  }

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      const file = e.dataTransfer.files?.[0]
      if (file && fileInputRef.current) {
        const fileInput = fileInputRef.current
        fileInput.files = e.dataTransfer.files
        const event = new Event('input', { bubbles: true })
        fileInput.dispatchEvent(event)
        handleFileUpload(event as any) // Trigger the file input's onChange event
      }
    },
    [handleFileUpload]
  )

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Box
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleDivClick}
      style={{ cursor: 'pointer' }}
      width="100%"
      height={['200px', '400px']}
      border="2px dashed"
      borderColor="gray.300"
      borderRadius="lg"
      position="relative"
    >
      <input
        type="file"
        accept=".csv, .xls, .xlsx"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
      <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <AddIcon boxSize={8} color="gray.500" />
      </Center>
      <VStack
        position="absolute"
        bottom={['20px', '20px']}
        left="50%"
        transform="translateX(-50%)"
        fontSize="sm"
        color="gray.500"
        width="100%"
        textAlign="center"
      >
        <Text>Please click to upload, or drag and drop a file here to import patients</Text>
        <Text>Only Excel or CSV files are supported</Text>
      </VStack>

      {children}
    </Box>
  )
}

export default CSVFileInput
