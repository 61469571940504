import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useMediaQuery,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { FC, MouseEventHandler } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { WhiteLogo } from './Logo'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { PrescriberSummaryPanel } from '../PrescriberSummaryPanel'
import { DESKTOP_BREAKPOINT } from '../../constants'
import { useLogout } from '../../hooks/useLogout'
import { routes } from '../../pages/routes'
import { useFeatureToggle } from '../../common/utils/feature-toggle/useFeatureToggle'

interface SideNavDrawerProps {
  isOpen: boolean
  onClose: () => void
}

interface NavProps {
  isSmallScreenNavOpen: boolean
  onCloseSmallScreenNav: () => void
}

interface SideNavLinkProps {
  to: string
  title: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const SideNavLink: FC<SideNavLinkProps> = ({ to, title, onClick }) => {
  const location = useLocation()

  return (
    <Button
      justifyContent="flex-start"
      colorScheme="blue"
      bgColor={location.pathname === to ? 'blue.200' : 'blue.800'}
      as={Link}
      to={to}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

const SideNav: FC<{ isNavDrawer?: boolean }> = ({ isNavDrawer }) => {
  const logout = useLogout()
  const { isFeatureEnabled: isReferralCodeFeatureOn } = useFeatureToggle('REFERRAL_CODE')

  return (
    <Flex flexDirection="column" bgColor="blue.800" p="3" h="100%">
      <Box boxSize={5} />
      <VStack spacing={10} justifyContent="space-between">
        <Center>
          <WhiteLogo maxWidth={150} />
        </Center>
        <VStack width={isNavDrawer ? '296px' : '260px'} spacing={2} alignItems="stretch">
          <SideNavLink to="/new-script" title="New Prescription" />
          {/*<SideNavLink to="/clinics" title="Clinics" />*/}
          <SideNavLink to="/patients" title="Manage Patients" />
          <SideNavLink to="/prescriptions" title="Manage Prescriptions" />
        </VStack>
      </VStack>
      <Spacer />
      <VStack width="260px" spacing={10} alignItems="stretch" justifyContent="space-between">
        <VStack width="260px" spacing={2} alignItems="stretch">
          {isReferralCodeFeatureOn && <SideNavLink to={routes.refer.path} title="RxRewards" />}
          <SideNavLink to="/settings" title="Settings" />
          <Button
            justifyContent="flex-start"
            colorScheme="blue"
            bgColor={'blue.800'}
            as="a"
            href="https://help.rxpad.com.au"
            target="_blank"
            rightIcon={<ExternalLinkIcon />}
          >
            Help
          </Button>
          {/*TODO: Build real logout function*/}
          <SideNavLink
            to="/"
            title="Logout"
            onClick={(e) => {
              e.preventDefault()
              logout()
            }}
          />
        </VStack>
        <PrescriberSummaryPanel />
      </VStack>
    </Flex>
  )
}

export const SideNavDrawer: FC<Required<SideNavDrawerProps>> = ({ isOpen, onClose }) => (
  <Drawer isOpen={isOpen} placement="left" onClose={onClose} size={'xs'}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton color="white" />
      <SideNav isNavDrawer />
    </DrawerContent>
  </Drawer>
)

const Nav: FC<NavProps> = ({ isSmallScreenNavOpen, onCloseSmallScreenNav }) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT})`)
  return isDesktop ? (
    <SideNav />
  ) : (
    <SideNavDrawer isOpen={isSmallScreenNavOpen} onClose={onCloseSmallScreenNav} />
  )
}

export default Nav
