import { CopyIcon } from '@chakra-ui/icons'
import { Heading, VStack, Text, HStack, IconButton, Tooltip, useToast } from '@chakra-ui/react'
import { ReactNode } from 'react'
import Card from '../../../../common/components/Card'

type InfoCardProps = {
  title: string
  value?: string | number
  showCurrency?: boolean
  showCopyButton?: boolean
  children?: ReactNode
}

export const InfoCard = ({
  title,
  value = '',
  showCurrency,
  showCopyButton,
  children,
}: InfoCardProps) => {
  const toast = useToast()
  const copyText = () => {
    navigator.clipboard.writeText(`${value}`)
    toast({
      title: `Copied successful`,
      status: 'success',
      isClosable: true,
      duration: 1000,
    })
  }
  return (
    <Card height="180px">
      <VStack padding={4} spacing={10}>
        <Heading as={'h4'} size="md">
          {title}
        </Heading>
        <VStack spacing={0}>
          <HStack alignItems="baseline" spacing={0}>
            {showCurrency && (
              <Text as="b" fontSize="lg">
                A$
              </Text>
            )}
            <Text fontSize="4xl">{value}</Text>
          </HStack>
          <HStack>
            {showCopyButton && (
              <Tooltip label="click to copy">
                <IconButton
                  icon={<CopyIcon />}
                  bgColor={'white'}
                  onClick={copyText}
                  aria-label="Copy text"
                  ml={2}
                />
              </Tooltip>
            )}
            {children}
          </HStack>
        </VStack>
      </VStack>
    </Card>
  )
}
