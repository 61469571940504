import { FormLabel, Stack, Text } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { FieldErrorsImpl } from 'react-hook-form'
import styled from '@emotion/styled'
import { QuestionIcon } from '@chakra-ui/icons'
import ClickableTooltip from './shared-components/ClickableTooltip'

const RedText = styled.span`
  color: red;
`
type InputFieldProps<T> = {
  field: keyof T
  label: string
  children: ReactNode
  errors?: FieldErrorsImpl<T>
  direction?: 'column' | 'row'
  isRequired?: boolean
  useTooltipIcon?: boolean
  tooltipIconColor?: string
  tooltipContent?: ReactNode
  width?: string
  className?: string
}
interface LabelProps {
  useTooltipIcon?: boolean
  tooltipIconColor?: string
  tooltipContent?: ReactNode
  label: string
  isRequired?: boolean
}

export const Label: FC<LabelProps> = ({
  tooltipContent,
  useTooltipIcon = false,
  tooltipIconColor,
  label = '#145fe1',
  isRequired = false,
}) => (
  <FormLabel mb={0}>
    {tooltipContent && !useTooltipIcon ? (
      <ClickableTooltip label={tooltipContent} ariaLabel={`${label}-tooltip`}>
        <Text>{label ?? null}</Text>
      </ClickableTooltip>
    ) : (
      label ?? null
    )}
    {label && isRequired && <RedText>*</RedText>}
    {useTooltipIcon && tooltipContent && (
      <ClickableTooltip
        label={tooltipContent}
        ariaLabel={`${label}-tooltip`}
        placement="right-start"
        closeOnClick={false}
      >
        <QuestionIcon ml={1} style={{ color: tooltipIconColor }} />
      </ClickableTooltip>
    )}
  </FormLabel>
)

export default function InputField<T extends Record<string, any>>({
  label,
  field,
  children,
  errors,
  direction = 'column',
  isRequired,
  useTooltipIcon,
  tooltipIconColor,
  tooltipContent,
  width = '100%',
  className,
}: InputFieldProps<T>) {
  const errorMessage: string = (errors?.[field]?.message as string) || ''
  return (
    <Stack width={width} direction={direction} justify="stretch" className={className}>
      <Label
        tooltipContent={tooltipContent}
        useTooltipIcon={useTooltipIcon}
        tooltipIconColor={tooltipIconColor}
        label={label}
        isRequired={isRequired}
      />
      {children}
      {errorMessage && (
        <Text fontSize="sm" fontWeight="700" color="red.600">
          {errorMessage}
        </Text>
      )}
    </Stack>
  )
}
