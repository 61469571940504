import { Box } from '@chakra-ui/react'

export const ScrollableContainer: import('@chakra-ui/system').ChakraComponent<'div', unknown> = (
  props
) => {
  const { children, ...restProps } = props
  return (
    <Box maxHeight="100vh" style={{ overflowY: 'scroll' }} {...restProps}>
      {children}
    </Box>
  )
}
