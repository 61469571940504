import { Button, Heading, SimpleGrid, VStack, Alert, AlertIcon, Stack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import useApi from '../../common/utils/api/useApi'
import { createOrganizationByAdmin } from '../OrganizationProfilePage/OrganizationSlice'
import OrganizationCreationForm from './OrganizationCreationForm'

const OrganizationCreationDeveloperPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const {
    loading,
    error,
    response,
    trigger: triggerCreateOrganization,
    reset: resetResult,
  } = useApi(createOrganizationByAdmin)

  return (
    <VStack
      alignItems={['center', 'start']}
      p={[3, 8, 10]}
      width={['100%', '100%', '70%']}
      gap={[4, 8, 14]}
    >
      <Heading as="h3" mb={6}>
        Create Organization Developer
      </Heading>

      <SimpleGrid columns={[1, 2, 3]} as="form" gap={[4, 8, 14]}>
        <OrganizationCreationForm register={register} errors={errors} />
      </SimpleGrid>

      <Stack gap={8} direction={['column', 'row']}>
        <Button
          colorScheme="blue"
          width={218}
          onClick={handleSubmit(triggerCreateOrganization)}
          isLoading={loading}
        >
          Save
        </Button>
        <Button
          colorScheme="blue"
          width={218}
          onClick={() => {
            reset()
            resetResult()
          }}
        >
          Reset Form
        </Button>
      </Stack>

      {error && (
        <Alert status="error" padding={6} width="max-content">
          <AlertIcon />
          Failed to create organization {error.response?.data?.message || ''}
        </Alert>
      )}

      {response && (
        <Alert status="success" padding={6} width="max-content">
          <AlertIcon />
          The organization is created successfully. The organization id is: {response.id}
        </Alert>
      )}
    </VStack>
  )
}

export default OrganizationCreationDeveloperPage
