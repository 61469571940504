import { FunctionComponent, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'
import { printIhiNumber } from '../../utils/ihi-number-printer/ihi-number-printer'
import { IHIRecord } from '../../types'

interface IHIHistoryModalProps {
  ihiRecords: IHIRecord[]
  children: FunctionComponent<{
    openModal: () => void
  }>
}

const IHIHistoryModal: FunctionComponent<IHIHistoryModalProps> = ({ ihiRecords, ...props }) => {
  const [modalIsOpen, setModelIsOpen] = useState<boolean>(false)
  return (
    <>
      <props.children openModal={() => setModelIsOpen(true)}></props.children>
      <Modal isOpen={modalIsOpen} onClose={() => setModelIsOpen(false)}>
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>IHI History</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>IHI</Th>
                    <Th>IHI Number Status</Th>
                    <Th>IHI Record Status</Th>
                    <Th>Added Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {ihiRecords.map((record) => (
                    <Tr key={record.ihi_number}>
                      <Td>{printIhiNumber(record.ihi_number)}</Td>
                      <Td>{record.ihi_status}</Td>
                      <Td>{record.record_status}</Td>
                      <Td>{format(parseISO(record.created_at), 'dd/MM/yyyy hh:mm a')}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default IHIHistoryModal
