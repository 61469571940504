import { ReactNode, useState } from 'react'
import Modal from '.'

interface OpenSimpleModalProps {
  title: string
  body: ReactNode
  onClose?: () => void
  onOk?: () => void
}

interface UseModalReturnProps {
  openModal: (props: OpenSimpleModalProps) => void
  closeModal: () => void
  SimpleModal: React.ComponentType
}

const VOID_FUNC = () => {}

export const useSimpleModal: () => UseModalReturnProps = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [onOk, setOnOk] = useState<() => void>(VOID_FUNC)
  const [onClose, setOnClose] = useState<() => void>(VOID_FUNC)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState<ReactNode>('')

  const resetModal = () => {
    setOnOk(VOID_FUNC)
    setOnClose(() => () => {})
    setTitle('')
    setBody('')
  }

  const openModal: (props: OpenSimpleModalProps) => void = ({ title, body, onOk, onClose }) => {
    setOnOk(() => () => {
      onOk?.()
      closeModal()
    })
    setOnClose(() => () => onClose?.())
    setTitle(title)
    setBody(body)
    setIsOpen(true)
  }

  const closeModal = () => {
    onClose?.()
    setIsOpen(false)
    resetModal()
  }

  const render = () => (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={closeModal}
      actions={[{ name: 'OK', action: onOk }]}
    >
      {body}
    </Modal>
  )

  return {
    openModal,
    closeModal,
    SimpleModal: render,
  }
}
