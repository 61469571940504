import { Stack, chakra } from '@chakra-ui/react'
import React from 'react'

const ResponsiveStack: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <Stack className={className} direction={['column', 'row']}>
      {children}
    </Stack>
  )
}

export default chakra(ResponsiveStack)
