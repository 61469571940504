import { FC, useEffect } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { Row } from './SendScript.styled'
import { CheckIcon, CloseIcon, TimeIcon } from '@chakra-ui/icons'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import { RootState } from '../../../app/store'
import { BillingClient } from '../../Billing/BillingSlice'

interface SendScriptStepperModalProps {
  steps: SendScriptStep[]
  scid?: string
  guid?: string
  error?: any
  isModelOpen: boolean
  disableResend: boolean
  createScriptLoading: boolean
  cancelPrescriptionLoading: boolean
  onCloseStepperModal: () => void
  onResendButtonClicked: () => void
  onAbort: (scid: string) => void
  onPrintButtonClicked: () => void
}

interface SendScriptStep {
  label: string
  isLoading: boolean
  isValid: StepValidationStatus
  error: any
}

export type StepValidationStatus = 'VALID' | 'INVALID' | 'NOT_START'

const SendScriptStepperModal: FC<SendScriptStepperModalProps> = (props) => {
  const dispatch = useAppDispatch()
  const organizationId = useAppSelector(
    (state: RootState) => state.prescriber?.prescriber?.organization.id
  )
  const prescriberId = useAppSelector((state) => state.prescriber.prescriber?.id)
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)

  const {
    steps,
    scid,
    error,
    isModelOpen,
    disableResend,
    onCloseStepperModal,
    createScriptLoading,
    onAbort,
    cancelPrescriptionLoading,
    onPrintButtonClicked,
    onResendButtonClicked,
  } = props

  const numbersCheckStatusIcon = (step: Omit<SendScriptStep, 'label'>) => {
    if (step.isLoading) {
      return <Spinner />
    }
    if (step.isValid === 'INVALID' || !!step.error) {
      return <Icon as={CloseIcon} color="red.500" w={6} h={6} />
    }
    if (step.isValid === 'NOT_START') {
      return <Icon as={TimeIcon} color="blue.500" w={6} h={6} />
    }
    if (step.isValid === 'VALID' && !step.error) {
      return <Icon as={CheckIcon} color="green.500" w={6} h={6} />
    }
  }

  useEffect(() => {
    const createPrescriptionComplete = !createScriptLoading && scid && !error
    const hasSubscription =
      subscription && subscription?.tokens < 10_000 && organizationId && prescriberId
    if (createPrescriptionComplete && hasSubscription) {
      dispatch(BillingClient.getSubscription({ prescriberId, organizationId }))
    }
  }, [createScriptLoading, scid, error])

  return (
    <Modal
      isOpen={isModelOpen}
      onClose={onCloseStepperModal}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Creating Prescription...</ModalHeader>
        <ModalBody>
          {error ? (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription fontSize="sm">{error}</AlertDescription>
            </Alert>
          ) : null}
          {steps.map((step) => {
            return (
              <Row key={step.label}>
                {numbersCheckStatusIcon(step)}
                <span>{step.label}</span>
              </Row>
            )
          })}
          {steps.every((step) => step.isValid === 'VALID') && (
            <Row>
              <Icon as={CheckIcon} color="green.500" w={6} h={6} />
              <span>{'Done'}</span>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          {createScriptLoading && scid && (
            <Button
              mr={3}
              onClick={() => onAbort(scid)}
              disabled={cancelPrescriptionLoading}
              colorScheme="red"
            >
              Abort
            </Button>
          )}
          {!createScriptLoading && scid && !error ? (
            <Button mr={3} onClick={onPrintButtonClicked}>
              Print
            </Button>
          ) : null}
          {!createScriptLoading && scid && !disableResend && !error ? (
            <Button mr={3} onClick={onResendButtonClicked}>
              Re-send
            </Button>
          ) : null}
          <Button
            mr={3}
            onClick={onCloseStepperModal}
            isDisabled={!!steps.find((step) => step.isLoading)}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SendScriptStepperModal
