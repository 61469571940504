import { AuthenticationClient } from '../features/authentication/slice/authenticationSlice'
import { useAppDispatch } from '../app/typedReduxHooks'

export const useLogout = () => {
  const dispatch = useAppDispatch()
  return async () => {
    const clientId = localStorage.getItem('client_id')
    await dispatch(AuthenticationClient.Logout())
    window.location.href = `${process.env.REACT_APP_COGNITO_AUTH_URI}/logout?client_id=${clientId}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_REDIRECT_URI}`
  }
}
