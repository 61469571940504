import { FC, FunctionComponent, useState, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import CustomPrescriptionItemForm from './CustomPrescriptionItemForm'
import { useForm } from 'react-hook-form'
import { CustomPrescriptionItem } from './CustomPrescriptionItem'

interface CreateCustomPrescriptionItemModalProps {
  children: FunctionComponent<{
    openModal: () => void
  }>
  submit: (value: any, id?: string) => Promise<void>
  modalHeader: string
  modalFooter: string
  prescriptionItem?: CustomPrescriptionItem
}

export const CreateAndUpdateCustomPrescriptionItemModal: FC<
  CreateCustomPrescriptionItemModalProps
> = ({ children: ChildrenComponent, submit, modalHeader, modalFooter, prescriptionItem }) => {
  // const dispatch = useAppDispatch()

  const [modalIsOpen, setModelIsOpen] = useState<boolean>(false)
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<CustomPrescriptionItem>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: prescriptionItem || {},
  })

  const onSubmit = () => {
    return handleSubmit(async (values: any) => {
      if (prescriptionItem) {
        await submit(values, prescriptionItem.id)
      } else {
        await submit(values)
      }
      setModelIsOpen(false)
    })
  }

  useEffect(() => {
    reset(prescriptionItem)
  }, [prescriptionItem, reset])

  return (
    <>
      <ChildrenComponent openModal={() => setModelIsOpen(true)} />
      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModelIsOpen(false)}
        onCloseComplete={() => reset()}
        blockScrollOnMount={false}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent maxW="600px" as="form" onSubmit={onSubmit()}>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CustomPrescriptionItemForm register={register} errors={errors} />
          </ModalBody>
          <ModalFooter justifyContent="end">
            <Button colorScheme="blue" type="submit">
              {modalFooter}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
