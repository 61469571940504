import { FC } from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import ClickableTooltip from '../../../../../components/shared-components/ClickableTooltip'

interface ProductTooltipProps {
  text: string
  hoverOverText: string
}

const ProductTooltip: FC<ProductTooltipProps> = ({ text, hoverOverText }) => {
  return (
    <HStack>
      <Text fontWeight="semibold" fontSize="sm">
        * {text}
      </Text>
      <ClickableTooltip label={hoverOverText}>
        <InfoIcon color="blue.500" />
      </ClickableTooltip>
    </HStack>
  )
}

export default ProductTooltip
