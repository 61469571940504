import { Heading, VStack, SimpleGrid, OrderedList, ListItem, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { RootState } from '../../app/store'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import Page from '../../components/Page'
import { InfoCard } from './components/InfoCard'
import { ReferClient } from './referSlice'
import Card from '../../common/components/Card'
import { ReferralTileModal } from './components/ReferralTileModal'

export const ReferralPage = () => {
  const dispatch = useAppDispatch()
  const referSummary = useAppSelector((state: RootState) => state.refer.summary)
  const referralCode = useAppSelector((state) => state.prescriber.prescriber?.referralCode)
  useEffect(() => {
    dispatch(ReferClient.getReferSummary())
  }, [])
  return (
    <Page>
      <VStack p="12px" alignItems="stretch" spacing={8} height="100%">
        <Heading as="h1" fontSize="3xl" fontWeight="bold">
          RxRewards
        </Heading>
        <SimpleGrid width={['100%']} columns={[1, 1, 3]} spacing={6} alignItems={'center'}>
          <InfoCard title="My Referral Code" value={referralCode ?? ''} showCopyButton>
            <ReferralTileModal />
          </InfoCard>
          <InfoCard
            title="Reward Balance"
            value={(referSummary.remainingReferralBonus / 100).toFixed(2)}
            showCurrency
          />
          <InfoCard title="Referrals Made" value={referSummary.totalReferrals} />
        </SimpleGrid>
        <Card>
          <VStack alignItems="stretch" spacing={2} padding={6}>
            <Text>Welcome to your RxRewards Dashboard. </Text>
            <Text>
              Earn credit towards your RxPad subscription and help others experience our platform's
              benefits!
            </Text>
            <Text>Here's how it works:</Text>
            <OrderedList paddingLeft={12} spacing={2}>
              <ListItem>
                <Heading size={'sm'} paddingBottom={1}>
                  Share your Referral Code
                </Heading>
                Spread the word about RxPad to others using your unique code.
              </ListItem>
              <ListItem>
                <Heading size={'sm'} paddingBottom={1}>
                  You earn $20 for each successful referral
                </Heading>
                Whenever a new user successfully onboards with your code, you'll receive a $20
                referral reward.
              </ListItem>
              <ListItem>
                <Heading size={'sm'} paddingBottom={1}>
                  Your referrals get an extra 15 days FREE
                </Heading>
                When a new user successfully onboards with your code, they get an extra 15 days of
                free usage on top of the standard 30-day free trial.
              </ListItem>
              <ListItem>
                <Heading size={'sm'} paddingBottom={1}>
                  Enjoy your rewards
                </Heading>
                As your referrals join RxPad your Reward Balance grows. This credit will
                automatically offset your next monthly subscription billing until it is exhausted.
              </ListItem>
            </OrderedList>
            <Text>Sharing the benefits of RxPad has never been more rewarding!</Text>
          </VStack>
        </Card>
        <Text fontSize={'sm'} paddingLeft={5} paddingRight={3} paddingBottom={4}>
          <b>Disclaimer:</b> RxPad reserves the right to modify, suspend, or terminate the rewards
          program at any time and for any reason without prior notice. We may also adjust the terms
          and conditions of the program, including reward amounts and eligibility criteria, as
          deemed necessary.
        </Text>
      </VStack>
    </Page>
  )
}
