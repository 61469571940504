import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PrescribedItem } from '../../../../types'
import { selectPatient } from '../../../../features/patient/PatientContextSlice'
import { PbsConcise } from '../ProductSearchContextSlice'
import { transformPbsData } from './pbsDataTransformer'

export interface DeliveryState {
  sendViaEmail: boolean
  sendViaSMS: boolean
  printPaperToken: boolean
  recipientEmail: string
  recipientMobile: string
}

export interface ScriptState extends DeliveryState {
  prescribedItemList: PrescribedItem[]
  selectedPrescribedItemIndex?: number
  addPrescribedItemError?: string
  prescriberScriptNumber: string
  id: string
  isPaperPrescription: boolean
}

const blankScript: ScriptState = {
  prescribedItemList: [],
  selectedPrescribedItemIndex: undefined,
  prescriberScriptNumber: '',

  // delivery info
  sendViaEmail: false,
  sendViaSMS: false,
  printPaperToken: true,
  recipientEmail: '',
  recipientMobile: '',
  id: '',
  isPaperPrescription: false,
}

export const ScriptSlice = createSlice({
  name: 'newScript',
  initialState: blankScript,
  reducers: {
    fetchPrescription: (state, { payload }: PayloadAction<PrescribedItem[]>) => {
      state.prescribedItemList = payload
    },
    addPrescribedItem: (state, { payload: newPrescribedItem }: PayloadAction<PrescribedItem>) => {
      state.prescribedItemList.push(newPrescribedItem)
      state.selectedPrescribedItemIndex = state.prescribedItemList.length - 1
    },
    selectPrescribedItem: (state, { payload: index }: PayloadAction<number>) => {
      state.selectedPrescribedItemIndex = index
    },
    updatePrescribedItem: (
      state,
      {
        payload: { index, updatedItem },
      }: PayloadAction<{
        index: number
        updatedItem: PrescribedItem
      }>
    ) => {
      state.prescribedItemList[index] = updatedItem
    },
    deletePrescribedItem: (state, { payload: index }: PayloadAction<number>) => {
      state.prescribedItemList.splice(index, 1)
    },
    setDeliveryState: (state, { payload }: PayloadAction<Partial<DeliveryState>>) => {
      Object.assign(state, payload)
    },
    setScriptState: (state, { payload }: PayloadAction<Partial<ScriptState>>) => {
      Object.assign(state, payload)
    },
    resetScript: (state) => {
      Object.assign(state, blankScript)
    },
    setScriptId: (state, { payload: id }: PayloadAction<string>) => {
      state.id = id
    },
    setIsPaperPrescription: (state, { payload: isPaperPrescription }: PayloadAction<boolean>) => {
      state.isPaperPrescription = isPaperPrescription
    },
    uncheckIsPaperPrescription: (state) => {
      const firstPrescribedItem = state.prescribedItemList[0]
      state.prescribedItemList = firstPrescribedItem ? [firstPrescribedItem] : []
      state.isPaperPrescription = false
    },
    selectPBSProduct: (
      state,
      { payload: { index, pbsData } }: PayloadAction<{ index: number; pbsData: PbsConcise }>
    ) => {
      const item = state.prescribedItemList[index]
      if (!item || !pbsData) {
        return
      }
      state.prescribedItemList[index] = {
        ...item,
        ...transformPbsData(pbsData),
      }
    },
    setScriptNo: (state, { payload }: PayloadAction<string>) => {
      state.prescriberScriptNumber = payload
    },
    setAddPrescribedItemError: (state, { payload }: PayloadAction<string | undefined>) => {
      if (!payload) {
        state.addPrescribedItemError = undefined
        return
      }
      state.addPrescribedItemError = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(selectPatient, (state, { payload: patientDetails }) => {
      Object.assign(state, { isPaperPrescription: patientDetails?.paper_only })
    })
  },
})

export const {
  addPrescribedItem,
  selectPrescribedItem,
  updatePrescribedItem,
  deletePrescribedItem,
  selectPBSProduct,
  setDeliveryState,
  setScriptState,
  resetScript,
  setScriptId,
  setIsPaperPrescription,
  uncheckIsPaperPrescription,
  setAddPrescribedItemError,
  setScriptNo,
} = ScriptSlice.actions

export default ScriptSlice.reducer
