const VALID = undefined
const NO_SCRIPT_NUMBER_ERROR = 'Please provide an existing script number.'
const INVALID_SCRIPT_NUMBER_ERROR =
  'This script number is invalid. Please enter a valid script number.'

export const validateScriptNumber = (scriptNumber: string): string | undefined => {
  if (!scriptNumber) {
    return NO_SCRIPT_NUMBER_ERROR
  }

  const formattedValue = scriptNumber.trim()
  const isValid = /^[0-9]{7}[0-8]{1}$/.test(formattedValue)

  if (!isValid) {
    return INVALID_SCRIPT_NUMBER_ERROR
  }

  const number = scriptNumber.slice(0, -1)
  const checkNumber = parseInt(scriptNumber.slice(-1))

  const calculatedCheckNumber =
    parseInt(number)
      .toString()
      .split('')
      .map((digit) => parseInt(digit))
      .reduce((digit, sum) => digit + sum, 0) % 9
  return checkNumber === calculatedCheckNumber ? VALID : INVALID_SCRIPT_NUMBER_ERROR
}
