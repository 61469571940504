import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiClient from '../../../utils/api/ApiClient'
import { Clinic } from '../../../types'
import { RootState } from '../../../app/store'

// Get Clinic Data
export type GetCurrentClinicResponse = Clinic

// Update Clinic
export type UpdateClinicRequest = Clinic
export type CreateClinicRequest = Clinic
export type UpdateClinicResponse = Clinic

export interface HpioValidateRequest {
  hpio_number: string
  hpii_number: string
  checkUnique?: string
}
export interface HpioValidateResponse {
  hpio_number: string
  hpio_status: string
  hpio_message: string
}

export const ClinicClient = {
  checkHpio: async ({ hpii_number, hpio_number, checkUnique }: HpioValidateRequest) => {
    const query = new URLSearchParams({
      'hpio-number': hpio_number,
      'hpii-number': hpii_number,
      'check-unique': checkUnique ?? 'false',
    })

    const response = await ApiClient.get(`/hi-service/clinic/validate?${query.toString()}`)
    return response.data as HpioValidateResponse
  },
  GetClinic: createAsyncThunk('clinic/getCurrent', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    if (state.clinic.loading.GetClinic !== thunkAPI.requestId) return
    try {
      if (!state.prescriber.prescriber) throw new Error('No Prescriber Logged In')
      const response = await ApiClient.get(`bff/clinics/current`)

      const clinic = response.data as GetCurrentClinicResponse

      thunkAPI.dispatch(selectClinic(clinic))

      return clinic
    } catch (error) {
      return undefined
    }
  }),
  getClinicByHPIO: async (hpio: string): Promise<Clinic> => {
    const { data } = await ApiClient.get(`/bff/clinics/search?hpio=${hpio}`)
    return data
  },
  createClinic: async (clinic: CreateClinicRequest): Promise<Clinic> => {
    const { data } = await ApiClient.post(`bff/clinics`, clinic)
    return data as Clinic
  },
  UpdateClinic: createAsyncThunk('clinic/update', async (clinic: UpdateClinicRequest, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    if (state.clinic.loading.UpdateClinic !== thunkAPI.requestId) return
    try {
      if (!state.prescriber.prescriber) throw new Error('No Prescriber Logged In')
      const response = await ApiClient.patch(`bff/clinics/current`, clinic)

      thunkAPI.dispatch(ClinicClient.GetClinic())

      return response.data as UpdateClinicResponse
    } catch (error) {
      return undefined
    }
  }),
}
type ClinicClientMethods = keyof typeof ClinicClient

export interface ClinicContextState {
  currentClinic?: Clinic
  loading: {
    [K in ClinicClientMethods]?: string // Request ID
  }
}

const initialState: ClinicContextState = {
  currentClinic: undefined,

  loading: {},
}

export const ClinicContextSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    selectClinic: (state, { payload: clinic }: PayloadAction<Clinic | undefined>) => {
      state.currentClinic = clinic
    },
  },
  extraReducers(builder) {
    // Get Clinics
    builder.addCase(ClinicClient.GetClinic.pending, (state, { meta: { requestId } }) => {
      if (!state.loading.GetClinic) {
        state.loading.GetClinic = requestId
      }
    })
    builder.addCase(ClinicClient.GetClinic.fulfilled, (state, { payload }) => {
      state.currentClinic = payload
      state.loading.GetClinic = undefined
    })
    builder.addCase(ClinicClient.GetClinic.rejected, (state) => {
      state.loading.GetClinic = undefined
    })
    // Update Clinics
    builder.addCase(ClinicClient.UpdateClinic.pending, (state, { meta: { requestId } }) => {
      state.loading.UpdateClinic = requestId
    })
    builder.addCase(ClinicClient.UpdateClinic.fulfilled, (state) => {
      state.loading.UpdateClinic = undefined
    })
    builder.addCase(ClinicClient.UpdateClinic.rejected, (state) => {
      state.loading.UpdateClinic = undefined
    })
  },
})

export const { selectClinic } = ClinicContextSlice.actions

export default ClinicContextSlice.reducer
