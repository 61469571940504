import { Center, Heading, VStack, Text, Button } from '@chakra-ui/react'
import { Logo } from '../../components/shared-components/Logo'
import { useLogout } from '../../hooks/useLogout'

const GenericErrorPage = () => {
  const logout = useLogout()

  const onLogout = async () => {
    await logout()
  }
  return (
    <VStack overflow="auto" height="65%" spacing="12" justifyContent={'center'}>
      <Center>
        <Logo maxWidth={100} />
      </Center>
      <VStack textAlign="center" spacing={8}>
        <Heading as={'h3'} size={'md'}>
          Oops, something went wrong...
        </Heading>
        <Text>Sorry, looks like something went run in our end, please try again later~</Text>
        <Button onClick={onLogout}>Logout</Button>
      </VStack>
    </VStack>
  )
}
export default GenericErrorPage
