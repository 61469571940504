import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { FunctionComponent, useState } from 'react'
import NewPatientModal from './NewPatientModal'
import UpdatePatientModal from './UpdatePatientModal'

import { Patient } from '../../types'
import PatientTable from './PatientTable'
import { ImportPatientsModal } from '../../features/patient/components/ImportPatientModal'
import { RootState } from '../../app/store'
import { useAppSelector } from '../../app/typedReduxHooks'

const PatientPage: FunctionComponent<unknown> = () => {
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null)
  const isDeactivatedPrescriber: boolean = useAppSelector(
    ({ prescriber }: RootState) => !prescriber?.prescriber?.active
  )

  return (
    <Box minWidth={0} width={'100%'} height={['100%', '100vh']}>
      <VStack p="12px" alignItems="stretch" spacing={6} height="100%">
        <HStack spacing="4">
          <Text as="h1" fontSize="3xl" fontWeight="bold">
            Patients
          </Text>
          <NewPatientModal>
            {({ openModal }) => (
              <Button
                colorScheme="blue"
                onClick={() => openModal()}
                disabled={isDeactivatedPrescriber}
              >
                + New Patient
              </Button>
            )}
          </NewPatientModal>
          <ImportPatientsModal>
            {({ openModal }) => (
              <Button
                colorScheme="blue"
                onClick={() => openModal()}
                disabled={isDeactivatedPrescriber}
              >
                Import Patients
              </Button>
            )}
          </ImportPatientsModal>
        </HStack>
        <PatientTable
          setSelectedPatient={setSelectedPatient}
          isDeactivatedPrescriber={isDeactivatedPrescriber}
        />
      </VStack>

      {selectedPatient && (
        <UpdatePatientModal
          patient={selectedPatient}
          isOpen={true}
          onClose={function () {
            setSelectedPatient(null)
          }}
        />
      )}
    </Box>
  )
}

export default PatientPage
