import { Box, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import Loading from '../../components/shared-components/Loading'
import { Prescription } from '../../types'
import { CeasePrescriptionModal } from './components/CeasePrescriptionModal'
import { ReIssuePrescriptionModal } from './components/ReIssuePrescriptionModal'
import { useParams } from 'react-router-dom'
import CancelPrescriptionModal from './components/CancelPrescriptionModal'
import { useGetAllPrescriptionsQuery } from '../../services/prescriptions'
import RCPagination from '../../components/RCPagination'
import SearchInput from '../../components/form/SearchInput'
import PrescriptionTable from './components/PrescriptionTable'
import { useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'

const PrescriptionsPage = () => {
  const { patientId } = useParams()

  // Fetch Prescriptions
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { isLoading, data: prescriptionsResponse } = useGetAllPrescriptionsQuery({
    patientId,
    page: String(page),
    limit: '10',
    search: searchTerm,
  })

  const { data: prescriptions, meta: prescriptionsPaginationData } = prescriptionsResponse || {}

  // Fetch Patient
  const [reIssueModalIsOpen, setReIssueModalIsOpen] = useState<boolean>(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)

  const [selectedPrescription, setSelectedPrescription] = useState<Prescription | undefined>(
    undefined
  )

  const [selectedScid, setSelectedScid] = useState<string>('')

  const { isOpen: isCeaseOpen, onOpen: onCeaseOpen, onClose: onCeaseClose } = useDisclosure()
  const isDeactivatedPrescriber: boolean = useAppSelector(
    ({ prescriber }: RootState) => !prescriber?.prescriber?.active
  )

  const onCloseCeaseModal = () => {
    onCeaseClose()
  }

  const openCeaseModal = (index: number) => {
    const scid = prescriptions?.[index]?.scid
    if (scid) {
      setSelectedScid(scid)
      onCeaseOpen()
    }
  }

  const openCancelModal = (index: number) => {
    const prescription = prescriptions?.[index]
    prescription && setSelectedPrescription(prescription)
    setIsCancelModalOpen(true)
  }

  const onCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const openReIssueModal = (index: number) => {
    const prescription = prescriptions?.[index]
    prescription && setSelectedPrescription(prescription)
    setReIssueModalIsOpen(true)
  }

  const closeReIssueModal = () => {
    setReIssueModalIsOpen(false)
    setSelectedPrescription(undefined)
  }

  return (
    <Box width="100%" height={['100%', '100vh']} p="2">
      {isLoading && <Loading />}
      {prescriptions && (
        <>
          <VStack px={[2, 4]} alignItems="stretch" h="100%" gap={[1, 4]}>
            <Text as="h1" fontSize="3xl" fontWeight="bold">
              Prescriptions
            </Text>
            <SearchInput
              w={['80%', '20%']}
              alignSelf="flex-start"
              onSearch={(value: string) => {
                const formattedValue = value.replace(/\s/g, '')
                setSearchTerm(formattedValue)
                setPage(1)
              }}
              placeholder="by patient name or scid"
            />
            <PrescriptionTable
              prescriptions={prescriptions}
              openCeaseModal={openCeaseModal}
              openReIssueModal={openReIssueModal}
              openCancelModal={openCancelModal}
              isDeactivatedPrescriber={isDeactivatedPrescriber}
            />
            <RCPagination
              showLessItems
              current={page}
              total={prescriptionsPaginationData?.totalItems}
              pageSize={10}
              onChange={(currentPage) => setPage(currentPage)}
            />
          </VStack>
          <CeasePrescriptionModal
            isOpen={isCeaseOpen}
            onClose={onCloseCeaseModal}
            scid={selectedScid}
          />
          {selectedPrescription && (
            <CancelPrescriptionModal
              isOpen={isCancelModalOpen}
              onClose={onCloseCancelModal}
              prescription={selectedPrescription}
            />
          )}
          {selectedPrescription ? (
            <ReIssuePrescriptionModal
              isOpen={reIssueModalIsOpen}
              onClose={closeReIssueModal}
              prescription={selectedPrescription}
            />
          ) : null}
        </>
      )}
    </Box>
  )
}

export default PrescriptionsPage
