import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import clinicContextReducer from '../pages/NewScriptPage/ScriptForm/ClinicContextSlice'
import patientContextReducer from '../features/patient/PatientContextSlice'
import prescriberReducer from '../pages/NewScriptPage/ScriptForm/PrescriberSlice'
import prescriberCreationReducer from '../pages/PrescriberProfileCreationPage/PrescriberCreationSlice'
import productSearchContextReducer from '../pages/NewScriptPage/ScriptForm/ProductSearchContextSlice'
import scriptReducer from '../pages/NewScriptPage/ScriptForm/PrescribedItem/PrescribedItemSlice'
import organizationReducer from '../pages/OrganizationProfilePage/OrganizationSlice'
import authenticationReducer from '../features/authentication/slice/authenticationSlice'
import billingReducer from '../pages/Billing/BillingSlice'
import referReducer from '../features/refer/referSlice'
import { patientsApi } from '../services/patients'
import { prescriptionsApi } from '../services/prescriptions'
import { customPrescriptionItemsApi } from '../services/customPrescriptionItems'

export const store = configureStore({
  reducer: {
    newScript: scriptReducer,
    prescriber: prescriberReducer,
    prescriberCreation: prescriberCreationReducer,
    patientDetail: patientContextReducer,
    clinic: clinicContextReducer,
    product: productSearchContextReducer,
    organization: organizationReducer,
    authentication: authenticationReducer,
    billing: billingReducer,
    refer: referReducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    [prescriptionsApi.reducerPath]: prescriptionsApi.reducer,
    [customPrescriptionItemsApi.reducerPath]: customPrescriptionItemsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(patientsApi.middleware)
      .concat(prescriptionsApi.middleware)
      .concat(customPrescriptionItemsApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
