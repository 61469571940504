import { FC } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import ResponsiveStack from './shared-components/ResponsiveStack'

interface AlertModelProps {
  isOpen: boolean
  onClose: () => void
  title: string
  alertTitle: string
  alertDescription: string
  status: AlertStatus
  actions?: { name: string; action: () => void }[]
}

const AlertModel: FC<AlertModelProps> = ({
  isOpen,
  onClose,
  title,
  alertTitle,
  alertDescription,
  status,
  actions = [],
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert status={status}>
            <AlertIcon />
            <Box>
              <AlertTitle>{alertTitle}</AlertTitle>
              <AlertDescription>{alertDescription}</AlertDescription>
            </Box>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <ResponsiveStack gap={3} w={['100%', 'max-content']}>
            <Button onClick={onClose}>Close</Button>
            {actions.map(({ action, name }) => (
              <Button key={name} onClick={action} colorScheme="blue">
                {name}
              </Button>
            ))}
          </ResponsiveStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AlertModel
