import { VStack, Text, Link } from '@chakra-ui/react'

export const GenericHPIOError = () => {
  return (
    <VStack alignItems={'stretch'}>
      <Text>It appears the HPI-O you have entered either:</Text>
      <Text>a) Does not exist on the HI Service </Text>
      <Text>b) Is not linked to the RxPad CSP in PRODA</Text>
      <Text>c) All of the above</Text>
      <Text>
        Please verify that your HPI-O with your practice manager, and click{' '}
        <Link
          color="teal.500"
          textDecoration={'underline'}
          href="https://help.rxpad.com.au/en/articles/7923812-how-do-i-connect-my-clinic-s-hpi-o-to-rxpad-s-csp"
          isExternal
        >
          here
        </Link>{' '}
        for instructions on how to link your HPI-O to our CSP.
      </Text>
    </VStack>
  )
}
