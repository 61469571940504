export const isMobileNumberInvalid = (mobileNumber?: string) => {
  if (!mobileNumber) {
    return true
  }

  return !/^(0|\+61)[0-8]\d{8}$/g.test(String(mobileNumber))
}

export const isPhoneNumberValid = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return false
  }

  return (
    /^(0|\+61)[0-8]\d{8}$/g.test(String(phoneNumber)) || /^(13|18)\d{8}$/g.test(String(phoneNumber))
  )
}
