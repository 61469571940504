import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { FunctionComponent, useState } from 'react'
import PatientForm from './PatientForm'
import { Patient } from '../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import {
  CreatePatientRequest,
  emptyPatient,
  useCreatePatientMutation,
} from '../../services/patients'
import { FetchBaseQueryErrorResult } from '../../services/baseQuery'
import { ServerError } from '../../types/result'

interface NewPatientModalProps {
  onAdd?: (patient: Patient) => void
  children: FunctionComponent<{
    openModal: () => void
  }>
}

const NewPatientModal: FunctionComponent<NewPatientModalProps> = ({
  onAdd,
  children: ChildrenComponent,
}) => {
  const [modalIsOpen, setModelIsOpen] = useState<boolean>(false)
  const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(false)
  const prescriberId = useSelector(({ prescriber }: RootState) => prescriber?.prescriber?.id)
  const [createPatient, createPatientResult] = useCreatePatientMutation()
  const createPatientError = (createPatientResult.error as FetchBaseQueryErrorResult['error'])
    ?.data as ServerError

  const {
    trigger,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: emptyPatient,
  })

  const handleCreatePatient = async (data: CreatePatientRequest) => {
    const result = await createPatient({ patient: data, prescriberId })
    if ('data' in result) {
      onAdd?.(result.data)
      reset(emptyPatient)
      setModelIsOpen(false)
    }
  }

  const handleCloseCreation = () => {
    reset(emptyPatient)
    setModelIsOpen(false)
  }

  return (
    <>
      <ChildrenComponent openModal={() => setModelIsOpen(true)}></ChildrenComponent>

      <Modal isOpen={modalIsOpen} onClose={handleCloseCreation} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={handleSubmit(handleCreatePatient)}
          width="100%"
          maxWidth="1000px"
        >
          <ModalHeader>New Patient</ModalHeader>
          {createPatientError?.error === 'duplicated-ihi-number' && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription
                fontSize={14}
              >{`Sorry, create patient failed. ${createPatientError?.message}`}</AlertDescription>
            </Alert>
          )}
          <ModalCloseButton />
          <ModalBody>
            <PatientForm
              trigger={trigger}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              setShouldDisableSubmitButton={setShouldDisableSubmitButton}
            />
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button mr={3} onClick={handleCloseCreation}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" disabled={shouldDisableSubmitButton}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NewPatientModal
