import { SearchIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputLeftElement, chakra } from '@chakra-ui/react'
import React, { useState } from 'react'
interface SearchInputProps {
  onSearch: (value: string) => void
  placeholder?: string
  className?: string
}

const SearchInput: React.FC<SearchInputProps> = ({ onSearch, placeholder, className }) => {
  const [search, setSearch] = useState('')

  const handleSearch = () => {
    const value = search.trim()
    onSearch(value)
  }

  return (
    <InputGroup className={className} variant="outline">
      <InputLeftElement cursor="pointer" onClick={handleSearch}>
        <SearchIcon />
      </InputLeftElement>
      <Input
        maxWidth="100%"
        placeholder={`Search ${placeholder || ''}`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch()
          }
        }}
        focusBorderColor="blue.500"
        backgroundColor="white"
      />
    </InputGroup>
  )
}

export default chakra(SearchInput)
