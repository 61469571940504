import { isValidDvaFileNumber } from '../../../utils/validation-helpers/DvaValidate'
import { isValidEmailFormat } from '../../../utils/validation-helpers/emailFormatValidate'
import { isValidMedicareNumber } from '../../../utils/validation-helpers/MedicareValidate'
import { isMobileNumberInvalid } from '../../../utils/validation-helpers/mobileNumberFormatValidate'

const MEDICARE_OR_DVA_MUST_BE_SUPPLIED = 'Either Medicare or DVA information must be supplied'

export const phoneNumberValidator = (phoneNumber?: string, email?: string) => {
  if (phoneNumber) {
    if (isMobileNumberInvalid(phoneNumber))
      return 'Please provide a valid phone number (no space or bracket)'
    return true
  }
  if (email && isValidEmailFormat(email)) {
    return true
  }
  return 'Either Email or Mobile Number required'
}

export const emailValidator = (email?: string, phoneNumber?: string) => {
  if (email) {
    if (email.length > 50) {
      return 'The maximum length of an email is 50'
    }
    return isValidEmailFormat(email) ? true : 'Please enter email with the correct format'
  }
  if (phoneNumber) {
    return true
  }
  return 'Either Email or Mobile Number required'
}

export const medicareNumberValidator = (medicareNo?: string, dvaFileNo?: string) => {
  if (medicareNo) {
    return isValidMedicareNumber(String(medicareNo)) || 'Please Enter a valid Medicare Number'
  }
  if (isValidDvaFileNumber(dvaFileNo)) return true
  return MEDICARE_OR_DVA_MUST_BE_SUPPLIED
}

export const medicareIrnValidator = (irnNo?: string, dvaFileNo?: string) => {
  if (irnNo) return !isNaN(parseInt(irnNo, 10)) || 'Please enter a valid IRN'
  if (isValidDvaFileNumber(dvaFileNo)) return true
  return MEDICARE_OR_DVA_MUST_BE_SUPPLIED
}

export const dvaFileNumberValidator = (
  dvaFileNo?: string,
  medicareNo?: string,
  medicareIrn?: string
) => {
  if (dvaFileNo) {
    return isValidDvaFileNumber(String(dvaFileNo)) || 'Please Enter a valid Dva File Number'
  }
  if (
    isValidMedicareNumber(medicareNo) &&
    medicareIrn &&
    medicareIrn.length === 1 &&
    !isNaN(parseInt(medicareIrn, 10))
  ) {
    return true
  }
  return MEDICARE_OR_DVA_MUST_BE_SUPPLIED
}

export const postcodeValidator = (postcode: string) => {
  return postcode.length === 4 || 'Postcodes are 4 digits log'
}

export const addressValidator = (address?: string) => {
  if (address) {
    return address.length <= 50 || `The maximum length of an address is 50`
  }
  return true
}

export const convertDateString = (dateString?: string) => {
  if (!dateString) return dateString
  let dateComponents = []

  if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
    dateComponents = dateString.split('-')
  } else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    dateComponents = dateString.split('/')
    dateComponents = [dateComponents[2], dateComponents[1], dateComponents[0]]
  } else if (/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(dateString)) {
    dateComponents = dateString.split('/')
  } else {
    return dateString
  }

  const [year, month, day] = dateComponents
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}
