import React, { FC } from 'react'
import { Text, VStack } from '@chakra-ui/react'

const DetailDisplay: FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <VStack spacing={4} align="stretch">
      <Text color="gray.500">{label}</Text>
      <Text>{value}</Text>
    </VStack>
  )
}

export default DetailDisplay
