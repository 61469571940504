import * as XLSX from 'xlsx'

export const convertPatientDataToXlsx = (data: any, filename?: string) => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data)
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  const excelData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${filename ?? 'data'}.xlsx`
  a.click()

  // Clean up by revoking the object URL
  URL.revokeObjectURL(url)
}
