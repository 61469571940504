class IdleTimer {
  interval?: number
  onTimeout?: () => void
  timeoutInMs: number
  debounceTimer?: number

  constructor({
    onTimeout: customizeOnTimeout,
    timeoutInMs,
  }: {
    onTimeout?: () => void
    timeoutInMs: number
  }) {
    this.onTimeout = customizeOnTimeout
    this.timeoutInMs = timeoutInMs
    this.updateExpiredTime = this.updateExpiredTime.bind(this)

    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || '0', 10)
    if (expiredTime > 0 && Date.now() > expiredTime) {
      customizeOnTimeout && customizeOnTimeout()
      localStorage.removeItem('_expiredTime')
      return
    }
    this.registerTracker()
    this.startInterval()
  }

  startInterval() {
    this.updateExpiredTime()

    this.interval = window.setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('_expiredTime') || '0', 10)
      if (expiredTime > 0 && Date.now() > expiredTime) {
        this.onTimeout && this.onTimeout()
        localStorage.setItem('_isExpired', 'true')
        this.cleanUpTracker()
      }
    }, 1000)
  }

  updateExpiredTime() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }
    this.debounceTimer = window.setTimeout(() => {
      localStorage.setItem('_expiredTime', (Date.now() + this.timeoutInMs).toString())
    }, 300)
  }

  registerTracker() {
    window.addEventListener('mousemove', this.updateExpiredTime)
    window.addEventListener('scroll', this.updateExpiredTime)
    window.addEventListener('keydown', this.updateExpiredTime)
  }

  cleanUpTracker() {
    window.clearInterval(this.interval)
    window.removeEventListener('mousemove', this.updateExpiredTime)
    window.removeEventListener('scroll', this.updateExpiredTime)
    window.removeEventListener('keydown', this.updateExpiredTime)
  }
}

export default IdleTimer
