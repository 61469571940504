import { PlacementWithLogical, Tooltip } from '@chakra-ui/react'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

type ClickableTooltipWithInfoIconProps = {
  label: ReactNode
  children: ReactElement
  fontSize?: string
  placement?: PlacementWithLogical
  ariaLabel?: string
  closeOnClick?: boolean
}
const ClickableTooltip = ({
  label,
  children: ChildComponent,
  fontSize,
  placement,
  closeOnClick,
  ariaLabel,
}: ClickableTooltipWithInfoIconProps) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false)
  const [isHoverOn, setIsHoverOn] = useState(false)

  useEffect(() => {
    if (isHoverOn) {
      setIsLabelOpen(true)
    } else {
      setIsLabelOpen(false)
    }
  }, [isHoverOn])

  return (
    <Tooltip
      label={
        <div onMouseEnter={() => setIsHoverOn(true)} onMouseLeave={() => setIsHoverOn(false)}>
          {label}
        </div>
      }
      fontSize={fontSize}
      aria-label={ariaLabel}
      isOpen={isLabelOpen}
      placement={placement ?? 'auto'}
      closeOnClick={closeOnClick}
      pointerEvents={'all'}
    >
      {React.cloneElement(ChildComponent, {
        onMouseEnter: () => setIsHoverOn(true),
        onMouseLeave: () => setIsHoverOn(false),
        onClick: () => setIsLabelOpen(true),
      })}
    </Tooltip>
  )
}

export default ClickableTooltip
