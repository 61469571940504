import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'

interface UserAgreementModalProps {
  isOpen: boolean
  canBeBypass: boolean
  title?: string
  onAccept?: () => void
  onDecline?: () => void
  onClose?: () => void
}

const UserAgreementModal: FC<PropsWithChildren<UserAgreementModalProps>> = ({
  children,
  isOpen,
  canBeBypass,
  title,
  onAccept,
  onDecline,
  onClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose && onClose()}
      size="4xl"
      blockScrollOnMount={false}
      closeOnOverlayClick={canBeBypass}
    >
      <ModalOverlay />
      <ModalContent>
        {canBeBypass ? <ModalCloseButton /> : null}
        {title ? <ModalHeader>{title}</ModalHeader> : null}
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={canBeBypass ? onClose : onDecline}>
            Decline
          </Button>
          <Button colorScheme="blue" onClick={onAccept}>
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserAgreementModal
