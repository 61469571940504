import { Box, Text, VStack, Image } from '@chakra-ui/react'
import { ForwardedRef, forwardRef, ForwardRefExoticComponent, RefAttributes, useState } from 'react'
import referralTileSvg from '../../../../assets/social-media-referral-tile.svg'

interface ReferralTileProps {
  referrerName: string
  referralCode: string
}

const ReferralTileImage: ForwardRefExoticComponent<
  ReferralTileProps & RefAttributes<HTMLDivElement>
> = forwardRef(
  ({ referrerName, referralCode }: ReferralTileProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    return (
      <Box id="referral-tile" position="relative" ref={ref}>
        <Image src={referralTileSvg} onLoad={() => setIsImageLoaded(true)} />
        {isImageLoaded && (
          <VStack
            id="referral-info-block"
            width="45%"
            position="absolute"
            top={'62%'}
            left={'47%'}
            alignItems="stretch"
            color="#737373"
            spacing={[1, 0]}
          >
            <Text id="referrer-name" as={'b'} fontSize={['xs', 'sm']} lineHeight={['120%', '135%']}>
              {referrerName}'s referrral code:
            </Text>
            <Text id="referral-code" as={'b'} fontSize={['4xl', '5xl']} alignSelf="center">
              {referralCode}
            </Text>
          </VStack>
        )}
      </Box>
    )
  }
)

export default ReferralTileImage
