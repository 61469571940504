import { Alert, Text, AlertIcon, VStack, Link } from '@chakra-ui/react'

export const PatientsValidateInfoAlert = ({
  validCount,
  invalidCount,
  uniqueInvalidCount,
  downloadInvalidPatient,
}: {
  validCount: number
  invalidCount: number
  uniqueInvalidCount: number
  downloadInvalidPatient?: () => void
}) => {
  if (validCount === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        <VStack fontSize={'xs'} alignItems={'stretch'}>
          <Text as="b">
            Oops! None of your patients could be pre-loaded because the data is either inaccurate,
            duplicative or entered in an invalid format.
          </Text>
          <Text as="b">
            Consult the Bulk Patient Import Guide for guidance on the accepted data conventions.
          </Text>
        </VStack>
      </Alert>
    )
  } else {
    return (
      <Alert status="info">
        <AlertIcon />
        <VStack fontSize={'xs'} alignItems={'stretch'} spacing={0}>
          <Text>
            We pre-loaded <strong>{validCount}</strong> unique patient records from your source
            file.
          </Text>
          <Text>
            {invalidCount === 1 ? '1 patient record has' : `${invalidCount} records have`} been
            excluded as the data is either inaccurate, duplicative, or entered in an invalid format.
          </Text>
          {downloadInvalidPatient && uniqueInvalidCount > 0 && (
            <Text>
              Click{' '}
              <Link as="b" onClick={downloadInvalidPatient} color="teal">
                here
              </Link>{' '}
              to download an Excel file of the <strong>{uniqueInvalidCount}</strong> unique patient
              records that have been excluded.
            </Text>
          )}
          <Text>Consult the Bulk Patient Import Guide for accepted data conventions.</Text>
        </VStack>
      </Alert>
    )
  }
}
