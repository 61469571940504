import { ValidationResult } from '../../types/validation-result'

export const validateExists = (value: any, field: string, errorMsg: string): ValidationResult => {
  if (!value) {
    return {
      isValid: false,
      displayValue: `Missing ${field}. ${errorMsg}.`,
    }
  }

  return {
    isValid: true,
    displayValue: value,
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const displayIfPresent = (value: any, field: string, errorMsg: string): ValidationResult => {
  if (!value) {
    return {
      isValid: true,
      displayValue: '', // display empty string instead of "null"
    }
  }

  return {
    isValid: true,
    displayValue: value,
  }
}

export const validateDate = (date: string, field: string, errorMsg: string): ValidationResult => {
  if (!date) {
    return {
      isValid: false,
      displayValue: `Missing ${field}. ${errorMsg}.`,
    }
  }

  if (!/\d{4}-\d{2}-\d{2}/.test(date)) {
    return {
      isValid: false,
      displayValue: `Invalid date format for ${date} in ${field}. Date should be formatted as YYYY-MM-DD. ${errorMsg}.`,
    }
  }

  const ts = Date.parse(date)

  if (isNaN(ts)) {
    return {
      isValid: false,
      displayValue: `${date} is an invalid date in field ${field}. ${errorMsg}.`,
    }
  }

  return {
    isValid: true,
    displayValue: date,
  }
}

export const validateHumanName = (name: string): ValidationResult => {
  if (!/^[a-z0-9 \-']+$/i.test(name)) {
    return {
      isValid: false,
      displayValue:
        'Only alphanumeric, straight apostrophe, dash and spaces characters are allowed',
    }
  }

  return {
    isValid: true,
    displayValue: '',
  }
}
