import { ValidationResult } from '../../types/validation-result'

export const isValidPrescriberType = (prescriberType?: string): boolean => {
  // Using falsy instead of === undefined to check as a value of 0 or empty string is invalid.
  if (!prescriberType) {
    return false
  }

  return /^[MEUFDVTC]$/i.test(prescriberType.trim())
}

export const isValidEntityID = (id?: string): boolean => {
  // Using falsy instead of === undefined to check as a value of 0 or empty string is invalid.
  if (!id) {
    return false
  }

  return /^[0-9A-Z]{5}$/.test(id.trim())
}

export const validateHpii = (hpii?: string, status?: string): ValidationResult => {
  // Using falsy instead of === undefined to check as a value of 0 or empty string is invalid.
  if (!hpii) {
    return {
      isValid: false,
      displayValue: 'No HPI-I linked to prescriber. Please contact Telecare RxPad Support.',
    }
  }

  if (status !== 'A') {
    return {
      isValid: false,
      displayValue: 'HPI-I in system is not active. Please contact Telecare Rxpad Support.',
    }
  }

  return {
    isValid: true,
    displayValue: hpii,
  }
}

export const validateEntityId = (entityId?: string): ValidationResult => {
  // Using falsy instead of === undefined to check as a value of 0 or empty string is invalid.
  if (!entityId) {
    return {
      isValid: false,
      displayValue: 'Missing Entity ID for this prescriber. Please contact Telecare RxPad Support.',
    }
  }

  if (!isValidEntityID(entityId)) {
    return {
      isValid: false,
      displayValue: 'Invalid Entity ID for this prescriber. Please contact Telecare RxPad Support.',
    }
  }

  return {
    isValid: true,
    displayValue: entityId,
  }
}
