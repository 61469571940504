import { useCallback, useEffect, useState } from 'react'

const useReadMore = (text: string, numberOfWordsToDisplay?: number) => {
  const [isFold, setIdFold] = useState(true)
  const [displayedText, setDisplayedText] = useState('')

  useEffect(() => {
    if (isFold) {
      const wordsNumber = numberOfWordsToDisplay || 20
      setDisplayedText(text.split(/\s+/).slice(0, wordsNumber).join(' '))
    }
  }, [text, numberOfWordsToDisplay, isFold])

  const handleClickReadMore = useCallback(() => {
    setIdFold(false)
    setDisplayedText(text)
  }, [text])

  return { handleClickReadMore, isFold, displayedText }
}

export default useReadMore
