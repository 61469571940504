import { FC, PropsWithChildren } from 'react'
import { Flex, FlexboxProps } from '@chakra-ui/react'
import { ScrollableContainer } from './shared-components/ScrollableContainer'

type PageProps = PropsWithChildren & Pick<FlexboxProps, 'alignItems'>

const Page: FC<PageProps> = ({ children, alignItems }) => {
  return (
    <ScrollableContainer flexGrow="1" flexBasis="0" width="100%">
      <Flex
        height="100%"
        flexDirection="column"
        overflow="scroll"
        width="100%"
        alignItems={alignItems}
      >
        {children}
      </Flex>
    </ScrollableContainer>
  )
}

export default Page
