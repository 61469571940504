export const PRESCRIBER_OPRIONS = [
  {
    label: 'Medical Practitioner',
    value: 'M',
  },
  {
    label: 'Nurse Practitioner',
    value: 'U',
  },
  {
    label: 'Midwife',
    value: 'F',
  },
  {
    label: 'Pharmacist',
    value: 'C',
  },
  {
    label: 'Dentist',
    value: 'D',
  },
  {
    label: 'Optometrist',
    value: 'E',
  },
  {
    label: 'Podiatrist',
    value: 'T',
  },
  {
    label: 'Vetinarian',
    value: 'V',
  },
]
