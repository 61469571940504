import { ValidationResult } from '../../types/validation-result'

const activeStatuses = new Set(['A', 'Active', 'ACTIVE'])

export const validateIhi = (ihi?: string, status?: string): ValidationResult => {
  // Using falsy instead of === undefined to check as a value of 0 or empty string is invalid.
  if (!ihi) {
    return {
      isValid: false,
      displayValue:
        'No IHI verified for this patient. Please ensure patient has signed up for My Health Record and check their details are correct.',
    }
  }
  if (!status) {
    return {
      isValid: false,
      displayValue:
        "No IHI Status for this patient. Please go to Patient Management and validate the patient's IHI.",
    }
  }
  if (!activeStatuses.has(status)) {
    return {
      isValid: false,
      displayValue: `Inactive IHI ${ihi}, status: ${status} for this patient. Please ensure patient has signed up for My Health Record and check their details are correct.`,
    }
  }

  return {
    isValid: true,
    displayValue: ihi,
  }
}
