import { ValidationResult } from '../../types/validation-result'
import { Text } from '@chakra-ui/react'

export const renderValidatedResult = (result: ValidationResult) => {
  if (result.isValid) {
    return <Text>{result.displayValue}</Text>
  } else {
    return <Text color="red">{result.displayValue}</Text>
  }
}
