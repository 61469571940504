import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiClient from '../../../utils/api/ApiClient'

import { RootState } from '../../../app/store'
import {
  transformPrescriberForService,
  transformPrescriberForUI,
} from '../../PrescriberProfilePage/utils'
import { Prescriber } from '../../../types'
import { PrescriberCreation } from '../../../types/prescriber'
import { STATUS } from '../../../constants'

export interface PrescriberContextState {
  prescriber: Prescriber | null
  loading: {
    [K in PrescriberClientMethods]?: string
  }
  status: {
    [K in PrescriberClientMethods]?: STATUS
  }
}

const prescriberJSON = localStorage.getItem('prescriber')
const prescriber: Prescriber | null =
  (prescriberJSON !== 'undefined' && prescriberJSON?.length && JSON.parse(prescriberJSON)) || null

const initialState: PrescriberContextState = {
  prescriber,
  loading: {},
  status: {},
}

export type PrescriberBriefInfo = {
  id: string
  givenNames: string
  familyName: string
}

export type UpdatePrescriberRequest = Prescriber
export type UpdatePrescriberResponse = Prescriber

export type CreatePrescriberRequest = PrescriberCreation
export type CreatePrescriberResponse = PrescriberCreation
export interface HpiiValidateRequest {
  hpii: string
  hpio: string
  family_name: string
}
export interface HpiiValidateResponse {
  hpii: string
  hpii_status: string
}

type CheckHPIIAlreadyLinkedRequest = { hpii: string; clinicId: string }

export const PrescriberClient = {
  checkHpii: async (request: HpiiValidateRequest) => {
    const query = new URLSearchParams({
      hpiiNumber: request.hpii,
      hpioNumber: request.hpio,
      familyName: request.family_name,
    })

    const response = await ApiClient.get(`/hi-service/prescriber/validate?${query.toString()}`)
    return response.data as HpiiValidateResponse
  },
  checkReferralCode: async (referralCode: string): Promise<PrescriberBriefInfo> => {
    const { data } = await ApiClient.get(`/bff/prescriber/referrer/${referralCode}`)
    return data
  },
  checkHPIIAlreadyLinked: async ({
    hpii,
    clinicId,
  }: CheckHPIIAlreadyLinkedRequest): Promise<{ isPrescriberAlreadyLinked: boolean }> => {
    const query = new URLSearchParams({
      hpii,
      'clinic-id': clinicId,
    })
    const { data } = await ApiClient.get(`/bff/prescriber/is-already-linked?${query.toString()}`)
    return data
  },
  GetPrescriber: createAsyncThunk('prescriber/get', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    if (state.prescriber.loading.GetPrescriber !== thunkAPI.requestId) return
    try {
      if (!state.prescriber.prescriber) throw new Error('No Prescriber Logged In')
      const response = await ApiClient.get(`/prescribers/${state.prescriber.prescriber?.id}`)

      return {
        prescriber: transformPrescriberForUI(response.data),
      }
    } catch (error) {
      return undefined
    }
  }),
  CreatePrescriber: createAsyncThunk(
    'prescriber/create',
    async (request: CreatePrescriberRequest, thunkAPI) => {
      const state = thunkAPI.getState() as RootState
      if (state.prescriber.loading.CreatePrescriber !== thunkAPI.requestId) return
      try {
        const prescriber = transformPrescriberForService(request)
        const response = await ApiClient.post(`/bff/prescriber`, prescriber)

        return {
          prescriber: transformPrescriberForUI(response.data),
        }
      } catch (error) {
        console.error(error)
      }
    }
  ),
  UpdatePrescriber: createAsyncThunk(
    'prescriber/update',
    async (request: UpdatePrescriberRequest, thunkAPI) => {
      const state = thunkAPI.getState() as RootState
      if (state.prescriber.loading.UpdatePrescriber !== thunkAPI.requestId) return

      try {
        if (!state.prescriber.prescriber) throw new Error('No Prescriber Logged In')

        const updatedPrescriber = transformPrescriberForService(request)
        const response = await ApiClient.patch(
          `/prescribers/${state.prescriber.prescriber?.id}`,
          updatedPrescriber
        )

        setTimeout(() => {
          thunkAPI.dispatch(PrescriberClient.GetPrescriber())
        }, 300)

        return response.data as UpdatePrescriberResponse
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  ),
  AcceptAgreements: createAsyncThunk(
    'prescriber/accept_agreement',
    async (request: any, { getState, requestId }) => {
      const state = getState() as RootState
      if (state.prescriber.loading.AcceptAgreements !== requestId) return

      try {
        const response = await ApiClient.put(`/bff/prescriber/agreements`, request)
        return { prescriber: response.data } as GetPrescriberResponse
      } catch (error) {
        console.error(error)
      }
    }
  ),
}
type PrescriberClientMethods = keyof typeof PrescriberClient
export interface GetPrescriberResponse {
  prescriber: Prescriber
}

export const PrescriberContextSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    setPrescriber: (state, { payload: newPrescriber }: PayloadAction<Prescriber>) => {
      state.prescriber = newPrescriber
      localStorage.setItem('prescriber', JSON.stringify(newPrescriber))
    },
  },
  extraReducers(builder) {
    builder
      // Get Prescriber
      .addCase(PrescriberClient.GetPrescriber.pending, (state, { meta: { requestId } }) => {
        if (!state.loading.GetPrescriber) {
          state.loading.GetPrescriber = requestId
        }
      })
      .addCase(PrescriberClient.GetPrescriber.fulfilled, (state, { payload }) => {
        state.prescriber = payload?.prescriber ?? null
        state.loading.GetPrescriber = undefined

        localStorage.setItem('prescriber', JSON.stringify(payload?.prescriber))
      })
      .addCase(PrescriberClient.GetPrescriber.rejected, (state) => {
        state.loading.GetPrescriber = undefined
      })
      // Update Prescribers
      .addCase(PrescriberClient.UpdatePrescriber.pending, (state, { meta: { requestId } }) => {
        state.loading.UpdatePrescriber = requestId
      })
      .addCase(PrescriberClient.UpdatePrescriber.fulfilled, (state) => {
        state.loading.UpdatePrescriber = undefined
      })
      .addCase(PrescriberClient.UpdatePrescriber.rejected, (state) => {
        state.loading.UpdatePrescriber = undefined
      })
      //Create Prescriber
      .addCase(PrescriberClient.CreatePrescriber.pending, (state, { meta: { requestId } }) => {
        state.loading.CreatePrescriber = requestId
        state.status = {
          ...state?.status,
          CreatePrescriber: STATUS.PENDING,
        }
      })
      .addCase(PrescriberClient.CreatePrescriber.fulfilled, (state) => {
        state.loading.CreatePrescriber = undefined
        state.status = {
          ...state?.status,
          CreatePrescriber: STATUS.SUCCESS,
        }
      })
      .addCase(PrescriberClient.CreatePrescriber.rejected, (state) => {
        state.loading.CreatePrescriber = undefined
        state.status = {
          ...state?.status,
          CreatePrescriber: STATUS.FAILURE,
        }
      })
      .addCase(PrescriberClient.AcceptAgreements.pending, (state, { meta: { requestId } }) => {
        state.loading.AcceptAgreements = requestId
      })
      .addCase(PrescriberClient.AcceptAgreements.fulfilled, (state, { payload }) => {
        if (!state.prescriber) {
          console.error('Prescriber can not be null') // TODO: Logout user and report error?
          return
        }
        state.loading.AcceptAgreements = undefined
        state.prescriber.mimsEula = payload?.prescriber?.mimsEula || null
        state.prescriber.rxpadEula = payload?.prescriber?.rxpadEula || null
        localStorage.setItem('prescriber', JSON.stringify(state.prescriber))
      })
      .addCase(PrescriberClient.AcceptAgreements.rejected, (state) => {
        state.loading.AcceptAgreements = undefined
      })
  },
})

export const { setPrescriber } = PrescriberContextSlice.actions

export default PrescriberContextSlice.reducer
