import { Button, Flex, Heading, HStack, Link, VStack, Text, Center } from '@chakra-ui/react'
import { FunctionComponent, useEffect } from 'react'
import Card from '../../common/components/Card'
import { Logo } from '../../components/shared-components/Logo'
import { enableIntercomService } from '../../services/intercom/intercomService'
import GoogleAnalyticsTagManager from '../../utils/google-analytics/GoogleAnalyticsTagManager'

const LoginPage: FunctionComponent<unknown> = () => {
  const isExpired = Boolean(localStorage.getItem('_isExpired'))

  useEffect(() => {
    enableIntercomService()
  }, [])
  const authUri = process.env.REACT_APP_COGNITO_AUTH_URI
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID
  const redirectUri = `${process.env.REACT_APP_COGNITO_REDIRECT_URI}/${clientId}`

  return (
    <Flex height="100%" flexDirection="column" p={10}>
      <HStack mt={20}>
        <VStack spacing="16" width="100%">
          <Card variant="light" padding="8" width="90%" maxWidth="500px">
            <VStack spacing="20px" alignItems="stretch">
              <Center>
                <Logo maxWidth={150} />
              </Center>
              <Heading as="h2" alignSelf="center" size="lg">
                Login
              </Heading>
              {isExpired && (
                <Text alignSelf="center">You have been logged out due to inactivity</Text>
              )}
              <Link
                href={`${authUri}/login?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`}
                onClick={GoogleAnalyticsTagManager.sendLoginEvent}
              >
                <Button colorScheme="blue" alignSelf="center" style={{ width: '100%' }}>
                  Sign In
                </Button>
              </Link>
              <Link
                href={`${authUri}/signup?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`}
                onClick={GoogleAnalyticsTagManager.sendSignUpEvent}
              >
                <Button colorScheme="blue" alignSelf="center" style={{ width: '100%' }}>
                  Sign Up
                </Button>
              </Link>
            </VStack>
          </Card>
        </VStack>
      </HStack>
    </Flex>
  )
}

export default LoginPage
