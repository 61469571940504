import { Alert, AlertDescription, AlertIcon, Button, VStack, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import {
  PrescriberClient,
  UpdatePrescriberRequest,
} from '../../NewScriptPage/ScriptForm/PrescriberSlice'
import { Prescriber } from '../../../types'
import ProfileForm from './ProfileForm'
import { STATUS } from '../../../constants'

const ProfilePage: FunctionComponent<unknown> = () => {
  const { prescriber } = useAppSelector((state) => state.prescriber)
  const [updateStatus, setUpdateStatus] = useState(STATUS.DEFAULT)
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: prescriber as Prescriber,
  })

  const dispatch = useAppDispatch()

  const updatePrescriber = async (data: UpdatePrescriberRequest) => {
    setUpdateStatus(STATUS.PENDING)
    const result = (await dispatch(PrescriberClient.UpdatePrescriber(data))) as any
    if (result.meta.requestStatus === 'fulfilled') {
      setUpdateStatus(STATUS.SUCCESS)
    }

    if (result.meta.requestStatus === 'rejected') {
      setUpdateStatus(STATUS.FAILURE)
    }
  }

  useEffect(() => {
    reset(prescriber as Prescriber)
    setUpdateStatus(STATUS.DEFAULT)
  }, [reset, prescriber])

  return (
    <VStack
      as="form"
      overflow="auto"
      p={[3, 6]}
      alignItems="flex-start"
      w={['100%', '50%']}
      minHeight="100%"
      spacing="8"
      onSubmit={handleSubmit(updatePrescriber)}
    >
      <ProfileForm
        {...{
          register,
          getValues,
          setValue,
          errors,
          setDisabledSubmit,
          prescriber,
          setError,
        }}
      />

      <Button
        colorScheme="blue"
        type="submit"
        disabled={disabledSubmit || isSubmitting || !isEmpty(errors)}
        isLoading={isSubmitting}
      >
        Update
      </Button>
      {!isEmpty(errors) && (
        <Text fontSize="xs" color="red.600" style={{ marginTop: '10px' }}>
          The update button will be enabled when all validation pass
        </Text>
      )}

      {[STATUS.SUCCESS, STATUS.FAILURE].includes(updateStatus) && (
        <Alert status={updateStatus === STATUS.FAILURE ? 'error' : 'success'} width="30%">
          <AlertIcon />
          <AlertDescription fontSize="sm">
            {updateStatus === STATUS.FAILURE
              ? 'Sorry, failed to update your profile.'
              : 'Successfully updated your profile'}
          </AlertDescription>
        </Alert>
      )}
    </VStack>
  )
}

export default ProfilePage
