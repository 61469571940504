import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'
import { useEffect, useState } from 'react'
import { BillErrorCode } from './BillingSlice'
import { SubscriptionExpiredNotificationContent } from './components/SubscriptionExpiredNotificationContent'
import { NoSubscriptionNotificationContent } from './components/NoSubscriptionNotificationContent'
import { NoRemainingTokensNotificationContent } from './components/NoRemainingTokensNotificationContent'
import { ExceedSeatLimitNotificationContent } from './components/ExceedSeatLimitNotificationContent'

export const SubscriptionNotificationModal = () => {
  const { error } = useAppSelector((state: RootState) => state.billing?.subscription)
  const [modalBody, setModalBody] = useState<JSX.Element | null>(null)
  const [modalTitle, setModalTitle] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const modalBodyMapping = {
      [BillErrorCode.NO_SUBSCRIPTION]: {
        title: 'Inactive Subscription',
        body: <NoSubscriptionNotificationContent />,
      },
      [BillErrorCode.SUBSCRIPTION_EXPIRED]: {
        title: 'Expired Subscription',
        body: <SubscriptionExpiredNotificationContent />,
      },
      [BillErrorCode.NO_REMAINING_TOKENS]: {
        title: 'Monthly Prescription Limit Reached',
        body: <NoRemainingTokensNotificationContent />,
      },
      [BillErrorCode.EXCEED_SEAT_LIMIT]: {
        title: 'Exceeding Subscription Seat Limit',
        body: <ExceedSeatLimitNotificationContent />,
      },
    }
    if (error?.code) {
      setModalBody(modalBodyMapping[error.code]?.body ?? null)
      setModalTitle(modalBodyMapping[error.code]?.title ?? '')
    }

    setIsOpen(!!error?.code)
  }, [error])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      size="xl"
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
