import React, { FunctionComponent } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  AlertDialogCloseButton,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react'
import { GeneralError } from '../../../types'
import { useCeasePrescriptionMutation } from '../../../services/prescriptions'
import { FetchBaseQueryErrorResult } from '../../../services/baseQuery'

interface CeasePrescriptionModelProps {
  onClose: () => void
  isOpen: boolean
  scid?: string
}

export const CeasePrescriptionModal: FunctionComponent<CeasePrescriptionModelProps> = ({
  onClose,
  isOpen,
  scid,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const [ceasePrescription, result] = useCeasePrescriptionMutation()
  const error = (result.error as FetchBaseQueryErrorResult['error'])?.data as GeneralError
  const successfulMessage =
    result?.data?.status === 'Success' ? 'Script has successfully been ceased' : null

  const onCeasePrescription = async () => {
    scid && (await ceasePrescription(scid))
  }

  const onCloseModal = () => {
    result.reset()
    onClose()
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseModal}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Cease Prescription?</AlertDialogHeader>
          {(error || successfulMessage) && (
            <Alert status={error ? 'error' : 'success'}>
              <AlertIcon />
              <AlertDescription fontSize="sm">
                {error ? error.message || 'Sorry, cease prescription failed.' : successfulMessage}
              </AlertDescription>
            </Alert>
          )}
          <AlertDialogCloseButton />
          {!successfulMessage && (
            <AlertDialogBody>{`Are you sure you want to cease this prescription?`}</AlertDialogBody>
          )}
          <AlertDialogFooter>
            {successfulMessage ? (
              <Button onClick={onCloseModal}>Close</Button>
            ) : (
              <>
                <Button onClick={onCloseModal}>No</Button>
                <Button
                  isLoading={result?.status === 'pending'}
                  loadingText="Ceasing"
                  colorScheme="red"
                  ml={3}
                  onClick={onCeasePrescription}
                >
                  Yes
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
