import { Button, Flex, Heading, SimpleGrid, StackDivider, VStack } from '@chakra-ui/react'
import { Subscription } from '../../types/subscription'
import DetailDisplay from '../OrganizationProfilePage/components/DetailDisplay'
import { format } from 'date-fns'
import { Fragment, useEffect, useState } from 'react'
import { CancelSubscriptionModal } from './components/CancelSubscriptionModal'
import { RootState } from '../../app/store'
import { ReactivateSubscriptionModal } from './components/ReactivateSubscriptionModal'
import { BillingClient } from './BillingSlice'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'

export const SubscriptionInfo = ({ subscription }: { subscription: Subscription }) => {
  const dispatch = useAppDispatch()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false)
  const organizationId = useAppSelector(
    (state: RootState) => state.prescriber?.prescriber?.organization.id
  )
  const prescriberId = useAppSelector((state) => state.prescriber?.prescriber?.id)

  const [billingPeriodEndDate, setBillingPeriodEndDate] = useState('')
  const [subscriptionStartDate, setSubscriptionStartDate] = useState('')
  useEffect(() => {
    setBillingPeriodEndDate(format(new Date(subscription.validTo), 'MMMM d, yyyy'))
    setSubscriptionStartDate(format(new Date(subscription.startAt), 'MMMM d, yyyy'))
  }, [subscription])
  const openCancelModal = () => {
    setIsCancelModalOpen(true)
    setIsReactivateModalOpen(false)
  }
  const openReactivateModal = () => {
    setIsReactivateModalOpen(true)
    setIsCancelModalOpen(false)
  }

  const closeModal = () => {
    if (organizationId && prescriberId) {
      dispatch(BillingClient.getSubscription({ prescriberId, organizationId }))
    }
    setIsCancelModalOpen(false)
    setIsReactivateModalOpen(false)
  }

  return (
    <Fragment>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={2}
        align="stretch"
        width={['100%', '100%', '70%']}
      >
        <Flex justify="space-between">
          <Heading as="h3" size="md" mb={4}>
            {subscription.productName}
          </Heading>
          <Button
            colorScheme="red"
            width={'100px'}
            onClick={subscription.cancelAt ? openReactivateModal : openCancelModal}
          >
            {subscription.cancelAt ? 'Re-activate' : 'Cancel'}
          </Button>
        </Flex>
        <SimpleGrid columns={2} spacing={[2, 4, 6]}>
          <DetailDisplay
            key={'Price'}
            label={'Price'}
            value={
              subscription.productPrice ? subscription.productPrice.toUpperCase() + '/month' : ''
            }
          />
          <DetailDisplay
            key={'Tokens'}
            label={'Tokens'}
            value={`${subscription.tokens > 100_000 ? 'Unlimited' : subscription.tokens}`}
          />
          <DetailDisplay key={'Start Date'} label={'Start Date'} value={subscriptionStartDate} />
          <DetailDisplay
            key={'Next Payment Date'}
            label={'Next Payment Date'}
            value={billingPeriodEndDate}
          />
        </SimpleGrid>
      </VStack>
      {subscription.cancelAt ? (
        <ReactivateSubscriptionModal
          isOpen={isReactivateModalOpen}
          onClose={closeModal}
          billingPeriodEndDate={billingPeriodEndDate}
          organizationId={organizationId ?? ''}
          subscriptionId={subscription.id}
        />
      ) : (
        <CancelSubscriptionModal
          isOpen={isCancelModalOpen}
          onClose={closeModal}
          billingPeriodEndDate={billingPeriodEndDate}
          organizationId={organizationId ?? ''}
          subscriptionId={subscription.id}
        />
      )}
    </Fragment>
  )
}
