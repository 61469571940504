export const isValidDvaFileNumber = (cardNumber: string | undefined) => {
  if (!cardNumber) {
    return false
  }
  return /^[NVQSWT]([A-Z ][0-9]{1,6}|[A-Z]{2}[0-9]{1,5}|[A-Z]{3}[0-9]{1,4})[A-Z]?$/.test(cardNumber)
}

export const isValidDvaCardColour = (dvaCardColour: string | undefined) => {
  if (!dvaCardColour) {
    return false
  }
  return /^[GWO]$/.test(dvaCardColour.trim())
}
