import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { AustralianState, Clinic } from '../../types'
import { ClinicClient } from '../NewScriptPage/ScriptForm/ClinicContextSlice'
import {
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import InputField from '../../components/InputField'
import luhn_validate from '../../utils/validation-helpers/LuhnValidate'
import useApi from '../../common/utils/api/useApi'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { mapStatusToText } from '../../utils/status/map-status-to-text'
import AddressSuggestionInput from '../../components/form/AddressSuggestionInput'
import { useState } from 'react'
import { isPhoneNumberValid } from '../../utils/validation-helpers/mobileNumberFormatValidate'
import { isValidEmailFormat } from '../../utils/validation-helpers/emailFormatValidate'
import { trimValue } from '../../utils/text-formatter'
import { MultipleLineTooltipContent } from './components/MultipleLineTooltipContent'
import { HPIOTooltipContent } from './components/HPIOTooltipContent'
import { GenericHPIOError } from './components/GenericHPIOError'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { addressValidator } from '../../features/patient/utils/patientFieldsValidator'

export interface ClinicWithOptionalId extends Omit<Clinic, 'id'> {
  id?: string
}

interface ClinicFormProps {
  register: UseFormRegister<Clinic> | UseFormRegister<ClinicWithOptionalId>
  errors: FieldErrorsImpl<Clinic> | FieldErrorsImpl<ClinicWithOptionalId>
  setValue: UseFormSetValue<Clinic> | UseFormSetValue<ClinicWithOptionalId>
  getValues: UseFormGetValues<Clinic> | UseFormGetValues<ClinicWithOptionalId>
  setIsDisabled?: any
}

function ClinicForm({ register, errors, setValue, getValues, setIsDisabled }: ClinicFormProps) {
  const GENERIC_HPIO_ERROR = 'GENERIC_HPIO_ERROR'
  const navigate = useNavigate()
  const { isOpen, onOpen: setModalOpen, onClose } = useDisclosure()
  const [HPIOStatusDisplay, setHPIOStatusDisplay] = useState('Unverified')
  const [showAddressLine2, setShowAddressLine2] = useState(!!getValues().address_2)
  const { prescriberCreationPayload } = useSelector((state: RootState) => state.prescriberCreation)

  const goBackToLinkPage = () => {
    navigate(routes.linkOrganization.path)
  }

  const { trigger: checkHpio, loading } = useApi(ClinicClient.checkHpio, {
    onSuccess: (response) => {
      setValue('hpio_status', response.hpio_status ?? '')
      setValue('hpio_message', response.hpio_message)
      setHPIOStatusDisplay(mapStatusToText[response.hpio_status] ?? 'Unverified')
      setIsDisabled && setIsDisabled(response.hpio_status !== 'A')
    },
    onFailure: ({ response }) => {
      setValue('hpio_status', response?.data?.hpio_status ?? '')
      const status: number = response.status
      let hpioMessage = response?.data?.message
      if (status === 500) {
        setHPIOStatusDisplay('Not Found')
        hpioMessage = GENERIC_HPIO_ERROR
      }
      setValue('hpio_message', hpioMessage ?? '')
      if (status === 409) {
        setModalOpen()
        setHPIOStatusDisplay('Duplicated')
      }

      setIsDisabled && setIsDisabled(true)
    },
  })

  const validateHpio = async () => {
    const values = getValues()
    const hpiiNumber = prescriberCreationPayload?.hpii

    checkHpio({
      hpio_number: values.hpio_number,
      hpii_number: hpiiNumber ?? '',
      checkUnique: 'true',
    })
  }
  const hpio_message = getValues().hpio_message ?? ''
  return (
    <>
      <VStack alignItems="stretch" width={'100%'}>
        <InputField
          label="Organisation Name"
          field="name"
          isRequired={true}
          errors={errors}
          tooltipContent={
            <MultipleLineTooltipContent
              lines={[
                'Please be aware that this name may be visible to patients and pharmacies (except for Send to Pharmacy prescriptions).',
                'The organisation name will be displayed on prescriptions sent through any affiliated prescribing accounts.',
              ]}
            />
          }
          useTooltipIcon
          tooltipIconColor="gray.800"
        >
          <Input
            {...register('name', {
              setValueAs: trimValue,
              required: 'Required',
              maxLength: {
                value: 36,
                message: 'Clinic name must be less than 36 Characters',
              },
            })}
          ></Input>
        </InputField>
        <SimpleGrid columns={[1, 1, 2]} spacing={[2, 4, 6]} width={'100%'}>
          <InputField
            label="Phone number"
            field="phone_number"
            errors={errors}
            isRequired={true}
            tooltipContent={
              <MultipleLineTooltipContent
                lines={[
                  'Please be aware that this phone number may be visible to patients and pharmacies (except for Send to Pharmacy prescriptions).',
                  'This phone number will be printed on prescriptions created by prescribing accounts linked to this organisation. ',
                ]}
              />
            }
            useTooltipIcon
            tooltipIconColor="gray.800"
          >
            <Input
              {...register('phone_number', {
                setValueAs: trimValue,
                validate: (value: string) => {
                  if (value) {
                    if (!isPhoneNumberValid(value))
                      return "The phone number can not include '-' or any spaces."
                    return true
                  }
                  return 'Please enter a valid phone number'
                },
              })}
            ></Input>
          </InputField>
          <InputField
            label="Email"
            field="email"
            errors={errors}
            isRequired={true}
            tooltipContent={
              <MultipleLineTooltipContent
                lines={[
                  'Please ensure this email address is the clinic email rather than a personal email (unless this is preferred), as pharmacies may contact this email address for prescription-related enquiries.',
                ]}
              />
            }
            useTooltipIcon
            tooltipIconColor="gray.800"
          >
            <Input
              {...register('email', {
                setValueAs: trimValue,
                required: 'Please Email for confirmation emails at this clinic',
                validate: (email: string) => {
                  if (email) {
                    if (email.length > 50) {
                      return 'The maximum length of an email is 50'
                    }
                    return isValidEmailFormat(email)
                      ? true
                      : 'Please enter email with the correct format'
                  }
                },
              })}
            ></Input>
          </InputField>
        </SimpleGrid>
        <AddressSuggestionInput setValue={setValue} />
        <SimpleGrid columns={[1, 1, 2]} spacing={[2, 4, 6]} width={'100%'}>
          <InputField
            label="Address"
            field="address_1"
            errors={errors}
            isRequired={true}
            tooltipContent={
              <MultipleLineTooltipContent
                lines={[
                  'Please be aware that the address inserted here may be visible to patients and pharmacies (except for Send to Pharmacy prescriptions).',
                  'This address will be printed on prescriptions created by prescribing accounts linked to this organisation. ',
                ]}
              />
            }
            useTooltipIcon
            tooltipIconColor="gray.800"
          >
            <Input
              {...register('address_1', {
                setValueAs: trimValue,
                required: 'Please enter an Address',
                validate: addressValidator,
              })}
            ></Input>
            {!showAddressLine2 && (
              <Button onClick={() => setShowAddressLine2(true)}>+ Add address line 2</Button>
            )}
          </InputField>
          {showAddressLine2 && (
            <InputField label="Address Line 2" field="address_2" errors={errors}>
              <Input
                {...register('address_2', {
                  setValueAs: trimValue,
                  validate: addressValidator,
                })}
              ></Input>
            </InputField>
          )}
          <InputField label="Suburb" field="suburb" errors={errors} isRequired={true}>
            <Input
              {...register('suburb', {
                setValueAs: trimValue,
                required: 'Please enter your suburb',
                maxLength: 36,
              })}
            ></Input>
          </InputField>
        </SimpleGrid>
        <SimpleGrid columns={[1, 1, 2]} spacing={[2, 4, 6]} width={'100%'}>
          <InputField label="Postcode" field="postcode" errors={errors} isRequired={true}>
            <Input
              {...register('postcode', {
                setValueAs: trimValue,
                required: 'Please enter your Post Code',
                minLength: {
                  value: 4,
                  message: 'Postcodes are 4 digits log',
                },
                maxLength: {
                  value: 4,
                  message: 'Postcodes are 4 digits log',
                },
              })}
            ></Input>
          </InputField>
          <InputField label="State" field="state" errors={errors} isRequired={true}>
            <Select
              {...register('state', {
                setValueAs: trimValue,
                required: 'Please select a valid state',
                validate: (input: string) =>
                  Object.values(AustralianState).includes(input as AustralianState) ||
                  'Please select a valid State',
              })}
            >
              <>
                {Object.values(AustralianState).map((state) => {
                  return (
                    <option value={state} key={state}>
                      {state}
                    </option>
                  )
                })}
              </>
            </Select>
          </InputField>
        </SimpleGrid>
        <SimpleGrid columns={[1, 1, 2]} spacing={[2, 4, 8]} width={'100%'}>
          {/* TODO HPIO Valideation */}
          <VStack>
            <InputField
              label="HPIO Number"
              field="hpio_number"
              errors={errors}
              isRequired={true}
              tooltipContent={<HPIOTooltipContent />}
              useTooltipIcon
              tooltipIconColor="gray.800"
            >
              <Input
                {...register('hpio_number', {
                  setValueAs: trimValue,
                  required: 'Please Enter a valid HPIO Number',
                  validate: (hpio: number | string) => {
                    hpio = String(hpio)
                    if (hpio.length === 0) return true
                    return (
                      (hpio.startsWith('800362') && luhn_validate(hpio, 16)) ||
                      'Please Enter a valid HPIO Number'
                    )
                  },
                })}
              />
            </InputField>
            {hpio_message !== '' ? (
              <Alert status="warning" mt={4}>
                <AlertIcon />
                <AlertTitle>
                  {getValues().hpio_message !== GENERIC_HPIO_ERROR ? (
                    getValues().hpio_message
                  ) : (
                    <GenericHPIOError />
                  )}
                </AlertTitle>
              </Alert>
            ) : null}
          </VStack>
          {/* IHI Status */}
          <VStack>
            <InputField label="HPIO Status" field="hpio_status" errors={errors} isRequired={true}>
              <Alert status={getValues().hpio_status === 'A' ? 'success' : 'warning'}>
                <AlertIcon />
                <Text>{HPIOStatusDisplay}</Text>
              </Alert>
            </InputField>

            <Button onClick={validateHpio} colorScheme="blue" disabled={loading} width={'100%'}>
              Click to Validate
            </Button>
          </VStack>
        </SimpleGrid>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>HPI-O Duplicated</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>This HPIO already exists in RxPad.</Text>
            <Text>
              If you wish to proceed with this HPIO Number, please return to the previous page to
              search and link to this HPIO.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Spacer />
            <Button onClick={goBackToLinkPage}>Go Back</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ClinicForm
