import { PrescriberCreation } from '../../types/prescriber'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PrescriberCreationState {
  prescriberCreationPayload: Partial<PrescriberCreation> | null
}

const initialState: PrescriberCreationState = {
  prescriberCreationPayload: null,
}

const prescriberCreationSlice = createSlice({
  name: 'prescriberCreation',
  initialState,
  reducers: {
    setPrescriberCreationPayload: (
      state,
      { payload }: PayloadAction<Partial<PrescriberCreation | null>>
    ) => {
      state.prescriberCreationPayload = payload ?? null
    },
  },
})

export const { setPrescriberCreationPayload } = prescriberCreationSlice.actions

export default prescriberCreationSlice.reducer
