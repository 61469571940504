import { Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'

export const SubscriptionExpiredNotificationContent = () => {
  return (
    <VStack alignItems={'stretch'}>
      <Text>Your subscription has expired.</Text>
      <Text paddingBottom={8}>
        To start sending prescriptions, select a subscription plan and enter your payment details{' '}
        <Link to={routes.pricePlan.path} style={{ color: 'dodgerblue' }}>
          here.
        </Link>
      </Text>
    </VStack>
  )
}
