import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './utils/google-analytics/GoogleAnalyticsTagManager'
import { Provider } from 'react-redux'
import { store } from './app/store'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './app/styles/theme'
import App from './app/App'
import './index.css'

const container = document.getElementById('root')
if (!container) {
  throw new Error('No root element has been found.')
}
const root = createRoot(container)

// Remove Strike Mode first, for the login process can only be call once.
// We can bring it back and build robust system after the demoing
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
