import { Box, Center, Text, Spinner, VStack } from '@chakra-ui/react'

export const CheckIHILoadingBox = () => {
  return (
    <Box
      width="100%"
      height={['200px', '400px']}
      border="2px dashed"
      borderColor="gray.300"
      borderRadius="lg"
      position="relative"
    >
      <Center
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width={'100%'}
      >
        <VStack>
          <Spinner />
          <Text textAlign="center" color="gray.500">
            Checking IHI number for each patient...
          </Text>
        </VStack>
      </Center>
    </Box>
  )
}
