import { PrescribedItem } from '../../../../types'

export const getPbsStatus = (item: PrescribedItem) => {
  if (item.isPBS) {
    return 'PBS'
  } else if (item.isRPBS) {
    return 'RPBS'
  } else if (item.privatePrescription) {
    return 'Private'
  } else if (item.isSection100) {
    return 'Section 100'
  }
  return ''
}
