import { ValidationResult } from '../../types/validation-result'

export const isValidMedicareNumber = (medicare: string | undefined): boolean => {
  if (!medicare || medicare.length !== 10) return false

  const matches = medicare.match(/^(\d{8})(\d)/)
  if (!matches) return false

  const base = matches[1]
  const checkDigit = matches[2]
  const weights = [1, 3, 7, 9, 1, 3, 7, 9]

  let sum = 0
  for (let i = 0; i < weights.length; i++) {
    sum += parseInt(base[i], 10) * weights[i]
  }
  return sum % 10 === parseInt(checkDigit, 10)
}

export const validateMedicareNumber = (medicareNumber?: string): ValidationResult => {
  // Using falsy instead of === undefined to check as a value of 0 or empty string is invalid.
  if (!medicareNumber) {
    return {
      isValid: false,
      displayValue:
        'Missing Medicare number for this patient. Please go to Manage Patients and updated the medicare number and then refresh.',
    }
  }

  if (!isValidMedicareNumber(medicareNumber)) {
    return {
      isValid: false,
      displayValue:
        'Invalid Medicare number for this patient. Please go to Manage Patients and updated the medicare number and then refresh.',
    }
  }

  return {
    isValid: true,
    displayValue: medicareNumber,
  }
}
