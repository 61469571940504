import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        fontFamily: 'Open Sans, Avenir, Helvetica, Arial, sans-serif;',
      },
    }),
  },
})
