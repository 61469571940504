export enum AustralianState {
  WesternAustralia = 'WA',
  NorthernTerritory = 'NT',
  SouthAustralia = 'SA',
  Victoria = 'VIC',
  Tasmania = 'TAS',
  AustralianCapitalTerritory = 'ACT',
  Queensland = 'QLD',
  NewSouthWales = 'NSW',
}
