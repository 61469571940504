import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  ModalFooter,
  useToast,
  IconButton,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import saveAs from 'file-saver'
import html2canvas from 'html2canvas'
import { Fragment, useEffect, useRef, useState } from 'react'
import { PiShareFatBold } from 'react-icons/pi'
import { useAppSelector } from '../../../../app/typedReduxHooks'
import { formatName } from '../../../../utils/text-formatter'
import ReferralTileImage from '../referrerSharedImage'

export const ReferralTileModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const [referrerName, setReferrerName] = useState('')
  const componentRef = useRef<HTMLDivElement>(null)
  const toast = useToast()
  const triggerSaveImage = async () => {
    await saveComponentAsImage(componentRef)
    onClose()
    toast({
      title: `Saved successful`,
      status: 'success',
      isClosable: true,
      duration: 2000,
    })
  }

  const saveComponentAsImage = async (componentRef: React.RefObject<HTMLDivElement>) => {
    if (!componentRef.current) return
    try {
      const canvas = await html2canvas(componentRef.current, {
        scale: 2,
        onclone: (el) => {
          const referralTile = el.getElementById('referral-tile') as HTMLElement
          const referrerName = el.getElementById('referrer-name') as HTMLElement
          const referralCode = el.getElementById('referral-code') as HTMLElement
          referralTile.style.width = '1080px'
          referralTile.style.height = '1080px'
          referrerName.style.transform = 'translateY(-20%)'
          referrerName.style.fontSize = '36px'
          referralCode.style.transform = 'translateY(-35%)'
          referralCode.style.fontSize = '136px'
        },
      })
      canvas.toBlob((blob: any) => {
        saveAs(blob, `${referrerName}'s Referral Tile.png`)
      }, 'image/png')
    } catch (error) {
      console.error('Error saving component as image:', error)
    }
  }

  useEffect(() => {
    if (prescriber) {
      const name = formatName(`${prescriber.givenNames} ${prescriber.familyName}`)
      setReferrerName(name)
      return
    }
    setReferrerName('')
  }, [prescriber])

  return (
    <Fragment>
      <Tooltip label="share referral code">
        <IconButton
          icon={<Icon as={PiShareFatBold} />}
          bgColor={'white'}
          onClick={onOpen}
          aria-label="Share referral code"
          ml={2}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save & Share Your Referral Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReferralTileImage
              referrerName={referrerName ?? ''}
              referralCode={prescriber?.referralCode ?? ''}
              ref={componentRef}
            />
          </ModalBody>
          <ModalFooter justifyContent={'center'} width="100%">
            <Button colorScheme="messenger" width={'40%'} onClick={triggerSaveImage}>
              Save Picture
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}
