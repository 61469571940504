import { Button, HStack, Td, Text, Tr, VStack } from '@chakra-ui/react'
import React, { FunctionComponent } from 'react'
import parse from 'html-react-parser'
import { DeleteIcon } from '@chakra-ui/icons'
import PrescribedItemStateTag from '../../../../PrescriptionsPage/components/PrescribedItemStateTag'
import { PrescribedItemState } from '../../../../../types/prescription'

interface PrescribedRowProps {
  productName: string
  displayName: string
  itemState: PrescribedItemState
  form: string
  quantity: number
  repeats: number | string
  directions: string
  pbsStatus: string
  selected: boolean
  onClick?: () => any
  onDelete?: () => any
}

const PrescribedItemRow: FunctionComponent<PrescribedRowProps> = ({
  displayName,
  productName,
  itemState,
  form,
  quantity,
  repeats,
  directions,
  pbsStatus,
  selected,
  onClick,
  onDelete,
}) => {
  const deleteItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    onDelete && onDelete()
  }

  return (
    <Tr
      cursor="pointer"
      onClick={() => onClick?.()}
      bgColor={selected ? 'gray.200' : 'white'}
      transition="background-color 0.3s"
      _hover={{
        bgColor: 'gray.100',
      }}
    >
      <Td>
        <VStack alignItems="stretch">
          <HStack>
            <Text fontWeight="bold">{displayName ? parse(displayName) : parse(productName)}</Text>
            <PrescribedItemStateTag state={itemState} />
          </HStack>
          <Text>{form}</Text>
          {directions && <Text>{directions}</Text>}
        </VStack>
      </Td>
      <Td isNumeric>{quantity}</Td>
      <Td isNumeric>{repeats}</Td>
      <Td>{pbsStatus}</Td>
      <Td>
        <Button colorScheme="red" size="sm" rounded="full" padding={0} onClick={deleteItem}>
          <DeleteIcon fill="white" />
        </Button>
      </Td>
    </Tr>
  )
}

export default PrescribedItemRow
