import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { Flex, Input, List, ListIcon, ListItem, Spinner } from '@chakra-ui/react'
import InputField from '../InputField'
import { SearchIcon } from '@chakra-ui/icons'
import { UseFormSetValue } from 'react-hook-form'
import useApi from '../../common/utils/api/useApi'
import { AddressClient } from '../../pages/PatientsPage/AddressSlice'

type AddressSuggestionInputProps = {
  setValue: UseFormSetValue<any>
  tooltipContent?: ReactNode
  fieldMapping?: {
    address?: string
    postcode?: string
    state?: string
    suburb?: string
  }
  className?: string
}

const AddressSuggestionInput = ({
  setValue,
  tooltipContent,
  fieldMapping,
  className,
}: AddressSuggestionInputProps) => {
  const [query, setQuery] = useState<string | null>('')
  const [suggestions, setSuggestions] = useState([] as string[])
  const [debouncedQuery, setDebouncedQuery] = useState('')
  const [hoveredItem, setHoveredItem] = useState<null | number>(null)
  const [selectedAddress, setSelectedAddress] = useState('')

  const { trigger: getAddressSuggestions, loading } = useApi(AddressClient.getAddressSuggestions)
  const { trigger: getAddressDetails } = useApi(AddressClient.getAddressDetails)
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      console.error('event is missing')
      return
    }
    const { value } = event.target
    setQuery(value)
  }
  const handleItemHover = (index: number) => {
    setHoveredItem(index)
  }

  const handleItemLeave = () => {
    setHoveredItem(null)
  }
  const handleSelectAddress = (index: number) => {
    setSelectedAddress(suggestions[index] ?? '')
    setQuery(null)
  }

  useEffect(() => {
    if (!query) {
      setDebouncedQuery('')
      return
    }
    const debounceTimeout = setTimeout(() => {
      setDebouncedQuery(query.replace(/\//, ' '))
    }, 1000)

    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [query])

  useEffect(() => {
    const fetchAddressSuggestions = async () => {
      if (debouncedQuery.trim() === '') {
        setSuggestions([])
        return
      }
      const suggestions: string[] = (await getAddressSuggestions(debouncedQuery)) ?? []
      setSuggestions(suggestions)
    }

    fetchAddressSuggestions()
  }, [debouncedQuery])

  useEffect(() => {
    if (!selectedAddress) return
    const fetchAddressDetailsBySuggestion = async () => {
      const addressDetails = await getAddressDetails(selectedAddress)
      setValue(fieldMapping?.address ?? 'address_1', addressDetails?.shortAddress ?? '')
      setValue(fieldMapping?.postcode ?? 'postcode', addressDetails?.postcode ?? '')
      setValue(fieldMapping?.state ?? 'state', addressDetails?.state ?? '')
      setValue(fieldMapping?.suburb ?? 'suburb', addressDetails?.suburb ?? '')
    }
    fetchAddressDetailsBySuggestion()
  }, [selectedAddress])

  return (
    <div className={className}>
      <InputField
        label="Address Search"
        tooltipContent={tooltipContent ?? 'e.g. 173 Whitehorse Rd Blackburn VIC, 3130'}
        useTooltipIcon
        tooltipIconColor="gray.800"
        field=""
      >
        <Input
          value={query === null ? selectedAddress : query}
          onChange={handleInputChange}
          placeholder="Enter an address"
          size="md"
          width="100%"
        />
      </InputField>
      <List bg="gray.50" borderRadius="md">
        {loading ? (
          <Flex align="center" justify="center" height="100px">
            <Spinner />
          </Flex>
        ) : (
          suggestions.map((suggestion, index) => (
            <ListItem
              key={suggestion}
              bg={hoveredItem === index ? 'gray.200' : 'transparent'}
              onMouseEnter={() => handleItemHover(index)}
              onMouseLeave={handleItemLeave}
              onClick={() => handleSelectAddress(index)}
              paddingY={2}
              paddingLeft={2}
            >
              <ListIcon as={SearchIcon} color="gray.400" />
              {suggestion}
            </ListItem>
          ))
        )}
      </List>
    </div>
  )
}

export default AddressSuggestionInput
