import { Center, Heading, VStack } from '@chakra-ui/react'
import { Logo } from './shared-components/Logo'

type TitleWithLogoProps = {
  title?: string
  logoSize?: number
}
const TitleWithLogo = ({ title, logoSize }: TitleWithLogoProps) => {
  return (
    <VStack spacing={'5'}>
      <Center>
        <Logo maxWidth={logoSize ?? 150} />
      </Center>
      {title ? (
        <Heading as="h2" alignSelf="center" size="lg" mt={1}>
          {title}
        </Heading>
      ) : null}
    </VStack>
  )
}

export default TitleWithLogo
