import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import ApiClient from '../utils/api/ApiClient'

interface Query {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  signal?: AxiosRequestConfig['signal']
  headers?: AxiosRequestConfig['headers']
}
export type FetchBaseQueryErrorResult = {
  error: {
    status: number
    data: any
  }
}

export type FetchBaseQueryResult = Promise<
  | {
      data: any
    }
  | FetchBaseQueryErrorResult
>

export const axiosBaseQuery =
  ({ baseUrl = '' }): BaseQueryFn<Query> =>
  async ({ url, method, data, params, signal, headers }) => {
    try {
      const result: AxiosResponse = await ApiClient({
        url: baseUrl + url,
        method,
        data,
        params,
        signal,
        headers,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }
