import styled from '@emotion/styled'

export const Row = styled.div`
  height: 2rem;
  & span {
    margin-left: 10px;
  }
  display: flex;
  align-items: center;
`
