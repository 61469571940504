import { FC } from 'react'
import { Tag } from '@chakra-ui/react'
import { PrescribedItemState } from '../../../types/prescription'

export interface PrescribedItemStateTagProps {
  state: PrescribedItemState
}

const colorSchemeOfState = (state: PrescribedItemState) => {
  switch (state) {
    case 'Active':
      return 'teal'
    case 'Cancelled':
      return 'orange'
    case 'Ceased':
      return 'blue'
    default:
      return 'blue'
  }
}

const PrescribedItemStateTag: FC<PrescribedItemStateTagProps> = ({ state }) => {
  const colorScheme = colorSchemeOfState(state)
  return (
    <Tag size="sm" variant="solid" colorScheme={colorScheme}>
      {state}
    </Tag>
  )
}

export default PrescribedItemStateTag
