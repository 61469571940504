export const getLabelOfAuthority = (state: string | undefined): string => {
  switch (state) {
    case 'VIC':
      return 'Warrant number'
    case 'NSW':
    case 'NT':
      return 'Authorisation number'
    case 'WA':
    case 'TAS':
      return 'Authority number'
    case 'SA':
      return 'Permit number'
    case 'QLD':
    case 'ACT':
      return 'Approval number'
    default:
      return 'Authority'
  }
}
