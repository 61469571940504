import { FC } from 'react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Flex, Container } from '@chakra-ui/react'
import { ReactComponent as LogoIcon } from '../../assets/rxpad_logo.svg'

interface HeaderBarProps {
  onToggleNav: () => void
}

const HeaderBar: FC<HeaderBarProps> = ({ onToggleNav }) => {
  return (
    <Flex p="16px 12px" justifyContent="space-between" verticalAlign="center">
      <Container w="fit-content" h={35} m={0} p={0}>
        <LogoIcon width="50px" height="20px" />
      </Container>
      <HamburgerIcon
        boxSize={8}
        onClick={onToggleNav}
        cursor="pointer"
        display={['block', 'block', 'block', 'none']}
      />
    </Flex>
  )
}
export default HeaderBar
