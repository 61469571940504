import { Box, Text, Heading, VStack } from '@chakra-ui/react'

export const PatientQuantityExceedErrorBox = () => {
  return (
    <Box p="10px" bg="red.100" color="red.600" borderRadius="lg">
      <VStack alignItems={'stretch'}>
        <Heading size={'xs'}>
          Oops! Your file has exceeded the maximum allowed number of patients.
        </Heading>
        <Text fontSize={'sm'}>
          Please ensure that each upload contains{' '}
          <strong>no more than 300 patients at a time.</strong>
        </Text>
      </VStack>
    </Box>
  )
}
