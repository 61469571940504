import axios, { AxiosError } from 'axios'
import ApiClient from '../../../../utils/api/ApiClient'

const BASE_PRESCRIPTION_PATH = '/bff/prescription'
const CREATE_SCID = '/scid'
const CREATE_PRESCRIBER_SCRIPT_NUMBER = '/prescribers/prescriberScriptNumber'

export interface CreatePrescriberScriptNumberResult {
  prescriberScriptNumber: string
}

export const createScid = async (): Promise<string> => {
  try {
    const {
      data: { scid },
    } = await ApiClient.post(`${BASE_PRESCRIPTION_PATH}${CREATE_SCID}`)
    return scid as string
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw (error as AxiosError)?.response?.data
    }
    throw error
  }
}

export const createPrescriberScriptNumber =
  async (): Promise<CreatePrescriberScriptNumberResult> => {
    try {
      const response = await ApiClient.post(CREATE_PRESCRIBER_SCRIPT_NUMBER)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw (error as AxiosError)?.response?.data
      }
      throw error
    }
  }
