import {
  SimpleGrid,
  Input,
  VStack,
  Heading,
  Button,
  InputRightAddon,
  InputGroup,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../app/typedReduxHooks'
import { RootState } from '../../../app/store'
import { STATUS } from '../../../constants'
import InputField from '../../../components/InputField'
import { updateOrganizationSettingsData } from '../OrganizationSlice'

const OrganizationSettings = () => {
  const dispatch = useAppDispatch()
  const organization = useSelector((state: RootState) => state.organization?.data)
  const { updateOrganizationSetting } = useSelector(
    (state: RootState) => state.organization?.status
  )
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  if (!organization) {
    return null
  }

  const handleUpdate = (value: any) => {
    if (organization.id) {
      dispatch(updateOrganizationSettingsData({ id: organization.id, ...value }))
    }
  }

  return (
    <VStack alignItems="flex-start">
      <Heading as="h4" size="md" mb={4}>
        Organisation Setting
      </Heading>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <SimpleGrid columns={[1, 2]} spacing={[2, 6, 10]} mb={6}>
          <InputField
            label="eRx Timeout (0 and empty means no timeout)"
            field="eRxTimeout"
            errors={errors}
          >
            <InputGroup>
              <Input
                type="number"
                {...register('eRxTimeout', {
                  value: organization?.settings?.eRxTimeout,
                  setValueAs: (value) => (value ? Number(value) : 0),
                  validate: (value) => {
                    if (isNaN(value)) {
                      return 'The timeout value should be a number'
                    }
                  },
                })}
              ></Input>
              <InputRightAddon children="seconds" />
            </InputGroup>
          </InputField>
          <InputField label="Inactivity Detection" field="idleTimeout" errors={errors}>
            <InputGroup>
              <Input
                type="number"
                placeholder="Inactivity Detection"
                {...register('idleTimeout', {
                  value: organization?.settings?.idleTimeout,
                  setValueAs: (value) => value || null,
                  validate: (value) => {
                    if (!value) {
                      return true
                    }

                    const timeout = Number(value)
                    if (isNaN(timeout)) {
                      return 'The inactivity detection timeout value should be a number'
                    }

                    if (timeout > 600) {
                      return 'Inactivity detection timeout should be less than 600 seconds'
                    }
                  },
                })}
              ></Input>
              <InputRightAddon children="seconds" />
            </InputGroup>
          </InputField>
        </SimpleGrid>
        <Button
          colorScheme="blue"
          size="sm"
          type="submit"
          isLoading={updateOrganizationSetting === STATUS.PENDING}
          loadingText="Submitting"
        >
          Submit
        </Button>
      </form>
    </VStack>
  )
}

export default OrganizationSettings
