export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const formatName = (str: string) => {
  return str
    .split(' ')
    .map((name) => {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    })
    .join(' ')
}

export const insertSpaceEvery4Characters = (str?: string) => str?.match(/.{1,4}/g)?.join(' ') ?? ''

export const trimValue = (value?: string) => value?.toString().trim() ?? value
