import { ValidationResult } from '../../types/validation-result'

export const validateHpio = (hpio?: string, status?: string): ValidationResult => {
  // Using falsy instead of === undefined to check as a value of 0 or empty string is invalid.
  if (!hpio) {
    return {
      isValid: false,
      displayValue: 'No HPI-O linked to prescriber. Please contact Telecare RxPad Support.',
    }
  }

  if (status !== 'A') {
    return {
      isValid: false,
      displayValue: 'HPI-O in system is not active. Please contact Telecare RxPad Support.',
    }
  }

  return {
    isValid: true,
    displayValue: hpio,
  }
}
