import { Center, CircularProgress, VStack } from '@chakra-ui/react'
import React from 'react'

const LoadingPage = () => {
  return (
    <VStack overflow="auto" height="70%" spacing="12" justifyContent={'center'}>
      <Center w="100px">
        <CircularProgress isIndeterminate color="blue.300" />
      </Center>
    </VStack>
  )
}
export default LoadingPage
