import { Text, VStack } from '@chakra-ui/react'

export const MultipleLineTooltipContent = ({ lines }: { lines: string[] }) => {
  return (
    <VStack paddingY={2} spacing={1}>
      {lines.map((line) => (
        <Text key={line}>{line}</Text>
      ))}
    </VStack>
  )
}
