import { Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import Card from '../../common/components/Card'
import { validateEntityId, validateHpii } from '../../utils/validation-helpers/PrescriberValidate'
import { validateHpio } from '../../utils/validation-helpers/ClinicValidate'
import { renderValidatedResult } from './jsxUtilities'

const PrescriberDetailsPanel: FunctionComponent<unknown> = () => {
  const prescriber = useSelector(({ prescriber }: RootState) => prescriber?.prescriber)
  const currentClinic = useSelector(({ clinic }: RootState) => clinic?.currentClinic)

  return (
    <VStack alignItems="stretch">
      <Heading as="h4" size="md">
        Prescriber
      </Heading>
      <Card p="3">
        {currentClinic ? (
          <VStack flexGrow="1" flexBasis="0" alignItems="stretch">
            <HStack>
              <Text fontWeight="bold">Clinic:</Text>
              <Text>{currentClinic?.name}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">HPI-O:</Text>
              {renderValidatedResult(
                validateHpio(currentClinic?.hpio_number, currentClinic?.hpio_status)
              )}
            </HStack>
            <HStack>
              <Text fontWeight="bold">Given Name:</Text>
              <Text>{prescriber?.givenNames}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Family Name:</Text>
              <Text>{prescriber?.familyName}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Entity Id:</Text>
              {renderValidatedResult(validateEntityId(prescriber?.entityId))}
            </HStack>
            <HStack>
              <Text fontWeight="bold">HPI-I:</Text>
              {renderValidatedResult(validateHpii(prescriber?.hpii, prescriber?.hpiiStatus))}
            </HStack>
          </VStack>
        ) : (
          <VStack flexGrow="1" flexBasis="0" alignItems="stretch">
            <HStack>
              <Text fontWeight="bold">Clinic:</Text>
              <Text>
                No Clinic Bound to this prescriber. Please contact Telecare Rxpad Support.
              </Text>
            </HStack>
          </VStack>
        )}
      </Card>
    </VStack>
  )
}

export default PrescriberDetailsPanel
