import { createAsyncThunk } from '@reduxjs/toolkit'
import { ClinicClient } from '../pages/NewScriptPage/ScriptForm/ClinicContextSlice'
import { AuthenticationClient } from '../features/authentication/slice/authenticationSlice'
import { fetchOrganizationData } from '../pages/OrganizationProfilePage/OrganizationSlice'
import { RootState } from './store'

export const appDidFinishLaunching = createAsyncThunk(
  'app/did_finish_launching',
  async (_, { dispatch }) => {
    await dispatch(AuthenticationClient.Login())
    await dispatch(dataPrefetching())
  }
)

const dataPrefetching = createAsyncThunk('app/prefetching', async (_, { dispatch, getState }) => {
  const organizationId = (getState() as RootState).prescriber?.prescriber?.organization?.id
  await dispatch(ClinicClient.GetClinic())
  if (organizationId) {
    await dispatch(fetchOrganizationData(organizationId))
  }
})

// TODO: Add slice to handle global error or notifications if needed.
