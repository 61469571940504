import { Text, VStack, Link } from '@chakra-ui/react'
import { DownloadIcon, QuestionIcon } from '@chakra-ui/icons'

export const ImportPatientHelpGuide = () => {
  return (
    <VStack alignItems={'stretch'} paddingTop={2} paddingLeft={1} spacing={1}>
      <Text fontSize={'xs'} as="b">
        <QuestionIcon boxSize={3} marginBottom={1} /> First time using bulk import? Click{' '}
        <Link
          href="https://help.rxpad.com.au/en/articles/8320213-rxpad-bulk-patient-import-guide"
          isExternal
          target="_blank"
          color={'blue.500'}
        >
          here
        </Link>{' '}
        to view the Bulk Patient Import Guide for step-by-step instructions.
      </Text>
      <Text as={'b'} fontSize={'xs'}>
        <Link
          href="https://downloads.intercomcdn.com/i/o/822005932/a1e1f383c1b2a2b69a8cb8d6/Patient+Import+Template+%28Editable%29.xlsx"
          isExternal
          target="_blank"
          color={'blue.500'}
        >
          <DownloadIcon color={'blue.500'} boxSize={3} marginBottom={1} /> Patient Import Template
          (Editable).xlsx
        </Link>
      </Text>
      <Text as={'b'} fontSize={'xs'}>
        <Link
          href="https://downloads.intercomcdn.com/i/o/823946282/a65b6b0114a0040cc58b8524/Sample+Patient+Import+%28View+Only%29.xlsx"
          isExternal
          target="_blank"
          color={'blue.500'}
        >
          <DownloadIcon color={'blue.500'} boxSize={3} marginBottom={1} /> Sample Patient Import
          (View Only).xlsx
        </Link>
      </Text>
    </VStack>
  )
}
