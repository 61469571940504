import {
  Modal as ChakraModal,
  ModalOverlay,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'
import ResponsiveStack from '../../../components/shared-components/ResponsiveStack'

interface ModalProps extends PropsWithChildren {
  title: string
  isOpen: boolean
  onClose: () => void
  actions?: { name: string; isLoading?: boolean; isDisabled?: boolean; action: () => void }[]
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, title, actions = [], children }) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size="xl" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <ResponsiveStack gap={3} w={['100%', 'max-content']}>
            <Button onClick={onClose}>Close</Button>
            {actions.map(({ action, name, isLoading, isDisabled }) => (
              <Button
                key={name}
                onClick={action}
                isLoading={isLoading}
                disabled={isDisabled}
                colorScheme="blue"
              >
                {name}
              </Button>
            ))}
          </ResponsiveStack>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
