import { FC } from 'react'
import { Box, Heading, Text, VStack } from '@chakra-ui/react'
import { ReactComponent as CancelPicture } from '../assets/undraw_cancel.svg'

const UnauthorizedPage: FC<unknown> = () => {
  return (
    <VStack justifyContent="center" alignItems="center" width="100%" gap={10} p={10}>
      <Box boxSize="xs">
        <CancelPicture />
      </Box>
      <Heading>Unauthorised</Heading>
      <Box boxSize={['sm', 'md']}>
        <Text textAlign="center">You are not authorised to create prescriptions.</Text>
        <Text textAlign="center">
          If this is not correct, please contact your organisation's administrator to obtain access.
        </Text>
      </Box>
    </VStack>
  )
}

export default UnauthorizedPage
