import { FC } from 'react'
import RxPadEulaModal from './RxPadEulaModal'
import MimsEulaModal from './MimsEulaModal'

const UserAgreementPage: FC<unknown> = () => {
  return (
    <>
      <MimsEulaModal />
      <RxPadEulaModal />
    </>
  )
}

export default UserAgreementPage
