import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import parse from 'html-react-parser'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FunctionComponent } from 'react'
import Card from '../../common/components/Card'
import { ProductResult } from './ScriptForm/ProductSearchContextSlice'

interface SearchResultsProps {
  results: ProductResult[]
  onAdd: (result: ProductResult) => any
  onSelect: (result: ProductResult) => any
  selectedId?: string
  hasMore: boolean
  loadMoreResults: () => any
}

export const DrugSearchResultsPanel: FunctionComponent<SearchResultsProps> = ({
  results,
  onAdd,
  onSelect,
  selectedId,
  loadMoreResults,
  hasMore,
}) => {
  return (
    <Card height="300px" overflow="auto">
      <VStack align="stretch" spacing="0" id="scrollableDiv" height="100%" overflow="auto">
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={results?.length ?? 0}
          next={loadMoreResults}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
          {results?.map((result) => (
            <SearchResult
              result={result}
              onAdd={onAdd}
              onSelect={onSelect}
              key={result.productId}
              selected={selectedId === result.productId}
            />
          ))}
        </InfiniteScroll>
      </VStack>
    </Card>
  )
}

interface SearchResultProps {
  result: ProductResult
  onAdd: (result: ProductResult) => any
  onSelect: (result: ProductResult) => any
  selected: boolean
}

const SearchResult: FunctionComponent<SearchResultProps> = ({
  result,
  onAdd,
  onSelect,
  selected,
}) => {
  return (
    <Box
      cursor="pointer"
      p="3"
      borderBottom="1px solid gray"
      borderBottomColor="gray.200"
      transition="background-color 0.3s"
      bgColor={selected ? 'gray.200' : 'white'}
      _hover={{
        bgColor: 'gray.100',
      }}
      onClick={() => onSelect(result)}
      className="drug_search_result"
    >
      <HStack justifyContent="space-between">
        <Text>{parse(result.productName)}</Text>
        <HStack>
          <Text color="gray.300">{result.productType}</Text>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={(e) => {
              e.stopPropagation()
              onAdd(result)
            }}
          >
            Select
          </Button>
        </HStack>
      </HStack>
    </Box>
  )
}
