import { Link, Text, VStack } from '@chakra-ui/react'

export const HPIOTooltipContent = () => {
  return (
    <VStack paddingY={2} spacing={1}>
      <Text>
        If you can’t find your HPI-O number, click{' '}
        <Link
          color="teal.500"
          textDecoration={'underline'}
          href="https://help.rxpad.com.au/en/articles/7958011-how-do-i-find-my-hpi-o"
          isExternal
        >
          here
        </Link>{' '}
        for instructions.
      </Text>
      <Text>
        If you don't have an HPI-O number, you will need to register your organisation as a{' '}
        <strong>seed organisation</strong> on PRODA. Click{' '}
        <Link
          color="teal.500"
          textDecoration={'underline'}
          href="https://help.rxpad.com.au/en/articles/7958105-how-do-i-register-my-organisation-for-an-hpi-o-number"
          isExternal
        >
          here
        </Link>{' '}
        for instructions.
      </Text>
    </VStack>
  )
}
