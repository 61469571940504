import {
  Box,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { getPrescriptionViewDataForNewScript } from './PaperPrescription'
import { PrescribedItem } from '../../../types'
import { FC } from 'react'
import GoogleAnalyticsTagManager from '../../../utils/google-analytics/GoogleAnalyticsTagManager'
import { useGAContextData } from '../../../utils/google-analytics/useGAContextData'

interface DeliveryMethodProps {
  emergencySupply: boolean
  sendToPharmacy: boolean
  sendViaSMS: boolean
  printPaperToken: boolean
  sendViaEmail: boolean
  isPaperPrescription: boolean
}

const DeliveryMethod: FC<DeliveryMethodProps> = (props) => {
  const Content: FC<DeliveryMethodProps> = ({
    sendToPharmacy,
    emergencySupply,
    sendViaEmail,
    sendViaSMS,
    printPaperToken,
    isPaperPrescription,
  }) => {
    if (isPaperPrescription) {
      return <Text>Paper Prescription</Text>
    }
    if (sendToPharmacy || emergencySupply || printPaperToken) {
      return <Text>Paper Token</Text>
    }
    if (sendViaEmail) {
      return <Text>Email</Text>
    }
    if (sendViaSMS) {
      return <Text>SMS</Text>
    }
    return <Text>N/A</Text>
  }

  return (
    <Box>
      <Text fontWeight="bold" textTransform="uppercase">
        Script Delivery Method:
      </Text>
      <Content {...props} />
    </Box>
  )
}

interface ReviewPrescriptionModalProps {
  isOpen: boolean
  isPaperPrescription: boolean
  isIncludeS8: boolean
  onClose: () => void
  sendPrescription: () => void
}

const PreviewPrescriptionModal: FC<ReviewPrescriptionModalProps> = ({
  isOpen,
  onClose,
  sendPrescription,
  isPaperPrescription,
  isIncludeS8,
}) => {
  const data = getPrescriptionViewDataForNewScript()
  const GAContextData = useGAContextData()

  const onSend = () => {
    onClose()
    GoogleAnalyticsTagManager.sendCreatePrescriptionEvent(GAContextData)
    sendPrescription()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview Prescription</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Box>
                    <Text fontWeight="bold" textTransform="uppercase">
                      Prescriber
                    </Text>
                    <Text>{`Prescriber: ${data.prescriberTitle} ${data.prescriberFirstname} ${data.prescriberLastname}`}</Text>
                    <Text>{`Address: ${data.clinicAddress}, ${data.clinicSuburb} ${data.clinicState} ${data.clinicPostcode}`}</Text>
                    <Text>{`Phone: ${data.prescriberPhoneNumber ?? ''}`}</Text>
                    <Text>{`Other Phone: ${data.prescriberOtherPhoneNumber ?? ''}`}</Text>
                    <Text>{`Provider No. ${data.prescriberProviderNumber ?? ''}`}</Text>
                    <Text>{`Prescriber No.: ${data.prescriberNumber ?? ''}`}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" textTransform="uppercase">
                      Patient
                    </Text>
                    <Text>{`Patient: ${data.patientFirstname} ${data.patientLastname}`}</Text>
                    <Text>{`DOB: ${data.patientDob}`}</Text>
                    <Text>{`Address: ${data.patientAddress}, ${data.patientSuburb} ${data.patientState} ${data.patientPostcode}`}</Text>
                  </Box>

                  {data.prescribedItems.map((item: PrescribedItem, index: number) => (
                    <Box key={index}>
                      <Text fontWeight="bold" textTransform="uppercase">
                        {`Item ${index + 1}:`}
                      </Text>
                      <Text>{`Name: ${item.displayName || item.genericName}`}</Text>
                      {item.isPBS && <Text>Type: PBS</Text>}
                      {item.isRPBS && <Text>Type: RPBS</Text>}
                      {item.privatePrescription && <Text>Type: Private</Text>}
                      {item.controlledSubstanceReference === 'Section 100' && (
                        <Text>Type: Section 100</Text>
                      )}
                      {item.pbsCode && <Text>{`PBS Code: ${item.pbsCode}`}</Text>}
                      {item.phoneApprovalAuthorityNumber && (
                        <Text>{`Approval Number: ${item.phoneApprovalAuthorityNumber}`}</Text>
                      )}
                      <Text>{`Quantity: ${item.quantity}`}</Text>
                      <Text>{`Repeat: ${item.numberOfRepeatsAuthorised}`}</Text>
                      {item.repeatIntervals && <Text>{`Interval: ${item.repeatIntervals}`}</Text>}
                      {item.patientInstructions && (
                        <Text>{`Directions: ${item.patientInstructions}`}</Text>
                      )}
                      {item.reasonForPrescribing && (
                        <Text>{`Reason for Prescribing: ${item.reasonForPrescribing}`}</Text>
                      )}
                      {item.doctorsNotes && <Text>{`Annotation: ${item.doctorsNotes}`}</Text>}
                      {item.unusualDoseFlag && <Text>{`Unusual Dose`}</Text>}
                      {item.brandSubstitutionNotAllowed && (
                        <Text>{`Brand substitution not allowed`}</Text>
                      )}
                      {item.regulation24 && (
                        <Text>{`Regulation 49 (previously Regulation 24)`}</Text>
                      )}
                      {item.emergencySupply && <Text>{`Emergency supply`}</Text>}
                      {item.noMySL && <Text>{`DO NOT send to Active Script List`}</Text>}
                      {item.sendToPharmacy && <Text>{`${item.sendToPharmacy}`}</Text>}
                    </Box>
                  ))}
                  <DeliveryMethod
                    isPaperPrescription={!!data.isPaperPrescription}
                    sendToPharmacy={!!data.prescribedItems?.[0]?.sendToPharmacy}
                    emergencySupply={!!data.prescribedItems?.[0]?.emergencySupply}
                    sendViaEmail={!!data.sendViaEmail}
                    sendViaSMS={!!data.sendViaSMS}
                    printPaperToken={!!data.printPaperToken}
                  />
                </Stack>
              </CardBody>
            </Card>
            {isPaperPrescription && isIncludeS8 ? (
              <Text color={'red.500'} fontWeight={'bold'} paddingTop={4}>
                Printed Schedule 8 prescriptions must comply with state or territory requirements
              </Text>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Make Changes
            </Button>
            <Button colorScheme="blue" onClick={onSend}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PreviewPrescriptionModal
