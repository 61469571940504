import { Tag } from '@chakra-ui/react'
import { FC } from 'react'

type PrescriptionTypeTagProps = {
  isPaperPrescription: boolean
}

export const PrescriptionTypeTag: FC<PrescriptionTypeTagProps> = ({
  isPaperPrescription,
}: PrescriptionTypeTagProps) => {
  const colorSchema = isPaperPrescription ? 'purple' : 'cyan'
  return (
    <Tag size="sm" variant="solid" colorScheme={colorSchema}>
      {isPaperPrescription ? 'Paper' : 'Electronic'}
    </Tag>
  )
}
