import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import { Box, HStack, Table, Tbody, Td, Th, Thead, Tr, VStack, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { BatchCreatePatientsResponseItem } from '../../../services/patients'
import { Patient } from '../../../types'
import ClickableTooltip from '../../../components/shared-components/ClickableTooltip'
import { generateDisplayedError } from '../services'

type ImportPatientPreviewTableProps = {
  patients: Partial<Patient>[]
  response?: BatchCreatePatientsResponseItem[]
}

export const ImportPatientPreviewTable: FC<ImportPatientPreviewTableProps> = ({
  patients,
  response,
}) => {
  const [successfulCount, setSuccessfulCount] = useState('')
  const [failedCount, setFailedCount] = useState('')

  useEffect(() => {
    if (response && response.length > 0) {
      const successfulResCount = response?.filter((res) => !!res.data).length
      setSuccessfulCount(`${successfulResCount}`)
      setFailedCount(`${response?.length - successfulResCount}`)
    }
  }, [response])

  const tableFields: { key: keyof Patient; label: string }[] = [
    { key: 'given_names', label: 'Given Names' },
    { key: 'family_name', label: 'Family Name' },
    { key: 'ihi_number', label: 'IHI Number' },
    { key: 'ihi_status', label: 'IHI Status' },
    { key: 'gender', label: 'Gender' },
    { key: 'date_of_birth', label: 'DOB' },
    { key: 'medicare_no', label: 'Medicare Card Number' },
    { key: 'medicare_irn', label: 'Medicare IRN' },
    { key: 'email', label: 'Email' },
    { key: 'phone_number', label: 'Phone Number' },
    { key: 'dva_file_no', label: 'DVA Card Number' },
    { key: 'dva_card_colour', label: 'DVA Card Colour' },
    { key: 'address_1', label: 'Address Line 1' },
    { key: 'address_2', label: 'Address Line 2' },
    { key: 'suburb', label: 'Suburb' },
    { key: 'postcode', label: 'Postcode' },
    { key: 'state', label: 'State' },
    { key: 'medicare_valid_to', label: 'Medicare Expiry' },
    { key: 'entitlement_no', label: 'Entitlement Number' },
    { key: 'ctg_flag', label: 'Eligible for Closing the Gap' },
    { key: 'concession_pension_no', label: 'Pension or Health Care Card Number' },
    { key: 'hospital_category', label: 'Hospital Inpatient' },
    { key: 'hospital_provider_number', label: 'Hospital Provider Number' },
    { key: 'racfid', label: 'RACFID (Aged Care Patient)' },
    { key: 'paper_only', label: 'Paper Prescriptions Only' },
  ]

  return (
    <VStack>
      <Box
        border="1px solid"
        width="100%"
        borderColor="gray.300"
        borderRadius="lg"
        maxHeight="450px"
        overflowX="auto"
        overflowY="auto"
      >
        <Table>
          <Thead>
            <Tr>
              {response && response.length > 0 && <Th key={'status'}>STATUS</Th>}
              {Object.values(tableFields).map((field, index) => (
                <Th key={`${field.key} + ${index}`}>{field.label}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {patients.map((patient, rowIndex) => (
              <Tr
                key={`${patient.email} + ${rowIndex}`}
                bgColor={response?.[rowIndex]?.error?.reason ? 'red.100' : ''}
              >
                {response && response.length > 0 && (
                  <Td textAlign={'center'}>
                    {!response?.[rowIndex]?.data ? (
                      <ClickableTooltip label={generateDisplayedError(response?.[rowIndex]?.error)}>
                        <WarningIcon color={'red'} boxSize={5} />
                      </ClickableTooltip>
                    ) : (
                      <CheckCircleIcon color={'green'} boxSize={5} />
                    )}
                  </Td>
                )}
                {Object.values(tableFields).map((field, columnIndex) => (
                  <Td key={`${patient[field.key]} + ${columnIndex}`}>{`${
                    patient[field.key] ?? ''
                  }`}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {response && response.length > 0 && (
        <HStack justifyContent="center" alignSelf="end" paddingEnd={4} spacing={8}>
          <Text>
            <CheckCircleIcon color={'green'} boxSize={5} /> : {successfulCount}
          </Text>
          <ClickableTooltip label="Hover over the warning icon on the failed line to see the cause.">
            <Text>
              <WarningIcon color={'red'} boxSize={5} /> : {failedCount}
            </Text>
          </ClickableTooltip>
        </HStack>
      )}
    </VStack>
  )
}
