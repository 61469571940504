export const luhn_validate = (function (arr: number[]) {
  return function (validationString: string, length?: number) {
    if (!validationString) {
      return false
    }
    let len = length ?? validationString.length,
      bit = 1,
      sum = 0,
      val

    while (len) {
      val = parseInt(validationString.charAt(--len), 10)
      // eslint-disable-next-line no-cond-assign
      sum += (bit ^= 1) ? arr[val] : val
    }

    return sum && sum % 10 === 0
  }
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9])

export default luhn_validate
