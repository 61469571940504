import { format } from 'date-fns'
import { Prescription } from '../../types'

export const transformPrescriptions = (prescription: Prescription) => {
  const { id, patient, scid, createdDate, prescribedItems, isPaperPrescription } = prescription

  return {
    id,
    patientName: `${patient?.given_names ?? ''} ${patient?.family_name ?? ''}`,
    scid,
    createdDate: format(new Date(createdDate), 'yyyy-MM-dd'),
    prescribedItems: prescribedItems.map(({ tradeName, quantity, state }) => ({
      displayName: `${tradeName} | Qty: ${quantity}`,
      state,
    })),
    isPaperPrescription,
    isEmergencySupply: prescribedItems.some((drug) => drug.emergencySupply),
    isSendToPharmacy: prescribedItems.some(
      (drug) => drug.sendToPharmacy && drug.sendToPharmacy !== ''
    ),
  }
}
