import { Center, Heading, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Logo } from '../../components/shared-components/Logo'
import { useState } from 'react'
import ProfileCreationForm from './components/ProfileCreationForm'
import { FORM_HEADER, FORM_STAGE } from './constant'
import SelectOrganizationForm from './components/SelectOrganizationForm'
import { PrescriberCreation } from '../../types/prescriber'

const PrescriberProfileCreationPage = () => {
  const [formStage, setFormStage] = useState(FORM_STAGE.CREATE_PROFILE)

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {} as PrescriberCreation,
  })

  const FormStageMapping = {
    [FORM_STAGE.CREATE_PROFILE]: (
      <ProfileCreationForm
        {...{
          register,
          getValues,
          setValue,
          setError,
          errors,
          trigger,
        }}
      />
    ),
    [FORM_STAGE.SELECT_ORG]: (
      <SelectOrganizationForm
        {...{
          register,
          getValues,
          setValue,
          errors,
          setFormStage,
        }}
      />
    ),
  }

  return (
    <VStack
      as="form"
      overflow="auto"
      p="24px"
      pb="9"
      minHeight="100%"
      spacing="12"
      onSubmit={handleSubmit(() => {})}
    >
      <Center>
        <Logo maxWidth={100} />
      </Center>
      <Heading as="h2" textAlign="center" size="lg">
        {FORM_HEADER[formStage]}
      </Heading>
      {FormStageMapping[formStage]}
    </VStack>
  )
}
export default PrescriberProfileCreationPage
