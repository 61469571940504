import { HStack, Select, Text, VStack } from '@chakra-ui/react'
import { ChangeEvent, FC } from 'react'

interface CheckboxFieldProps {
  className?: string
  label?: string
  selectedValue?: string
  required?: boolean
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  options: { label?: string; value: string }[]
}

const SelectField: FC<CheckboxFieldProps> = ({
  className = '',
  label = '',
  options,
  selectedValue,
  onChange,
  required,
}) => {
  return (
    <VStack alignItems="stretch">
      <Text fontWeight="bold">
        {required ? <span style={{ color: 'red' }}>* </span> : null}
        {label}
      </Text>
      <HStack>
        <Select value={selectedValue} onChange={onChange} className={className}>
          <option value="">{''}</option>
          {options.map(({ label, value }) => {
            return (
              <option value={value} key={value}>
                {label || value}
              </option>
            )
          })}
        </Select>
      </HStack>
    </VStack>
  )
}

export default SelectField
