export const ADD_PRESCRIBED_ITEM_FAILED_ERROR =
  'Only 1 PBS item is allowed per script for e-prescribing (paperless). Up to 3 PBS items are allowed per script for paper prescriptions, except for Authority PBS items (only 1 permissible per script).'
export const UPDATE_PRESCRIBED_ITEM_FAILED_ERROR =
  'The new PBS you select violate the rule: only 3 PBS items are allowed per script, except for Authority PBS items (only 1 per script).'

export const initFormError = {
  patientInstructions: {
    showError: false,
    message: 'Please enter directions',
  },
}
