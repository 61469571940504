import { InfoIcon } from '@chakra-ui/icons'
import {
  Card,
  Checkbox,
  HStack,
  Input,
  Link,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { get } from 'lodash'
import { Dispatch, FC, SetStateAction } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../app/typedReduxHooks'
import {
  BRAND_IS_REQUIRED_TOOLTIP,
  BRAND_SUBSTITUTION_NOT_PERMITTED_TOOLTIP,
  CONTROLLED_SUBSTANCE_TOOLTIP,
  DO_NOT_SEND_TO_ACTIVE_SCRIPT_LIST_TOOLTIP,
  EMERGENCY_SUPPLY_TOOLTIP,
  REGULATION_49_TOOLTIP,
  REPEAT_INTERVAL_TOOLTIP,
  SEND_TO_PHARMACY_TOOLTIP,
  TABLET_BREAKPOINT,
  UNUSUAL_DOSE_TOOLTIP,
} from '../../../../../constants'
import { PrescribedItem } from '../../../../../types'
import { ClinicContextState } from '../../../ScriptForm/ClinicContextSlice'
import {
  isAuthPhoneRequiredForPrescribedItem,
  isSchedule8Medicine,
  repeatIntervalRequired,
} from '../../../utilities'
import { getPbsStatus } from '../../utils/getPbsStatus'
import { getLabelOfAuthority } from '../../utils/getLabelOfAuthority'
import { setDeliveryState } from '../../../ScriptForm/PrescribedItem/PrescribedItemSlice'
import { getProductDisplayName } from '../../../ScriptForm/PrescribedItem/utils/getProductDisplayName'
import SelectField from '../../../../../components/form/SelectField'
import { PrescribedItemErrors } from '../../../../../types/prescription'
import ClickableTooltip from '../../../../../components/shared-components/ClickableTooltip'
import { AMTTypes } from '../../../ScriptForm/ProductSearchContextSlice'
import { EMPTY_PBS_DATA } from '../../../ScriptForm/PrescribedItem/pbsDataTransformer'
import { hasUnusualQuantity } from '../../utils/hasUnusualQuantity'
import ScriptNumber from './ScriptNumber'
import { useSimpleModal } from '../../../../../common/components/Modal/useSimpleModal'

interface PrescribedItemDetailsProps {
  prescribedItem: PrescribedItem
  formErrors: PrescribedItemErrors
  setFormErrors: Dispatch<SetStateAction<PrescribedItemErrors>>
  onChange: (updatedItem: PrescribedItem) => any
  onSelectPBSOrRPBS: () => void
}

/**
 * Get a list of drug volume options based on product type
 *
 * @param amt Australia medicinal terminoloy
 * @param pt Product type, e.g generic or branded
 * @returns A list of drug volume options
 */
type DurgPack = { tppName: string; mppName: string }
function getDrugPackOptionsFromAMT(
  amt: AMTTypes[],
  pt: 'generic' | 'branded'
): { label: string; value: DurgPack }[] {
  const options: { label: string; value: DurgPack }[] = []
  for (let i = 0; i < amt?.length; i++) {
    const tppName = get(amt[i], `tpps[0].tppName`, undefined)
    const mppName = get(amt[i], `mpps[0].mppName`, undefined)
    const packName = pt === 'branded' ? tppName : mppName
    // filter empty and duplicated options
    if (
      tppName !== undefined &&
      mppName !== undefined &&
      !options.find((e) => e.label === packName)
    ) {
      options.push({ label: packName, value: { tppName, mppName } })
    }
  }
  return options
}

const PrescribedItemDetails: FC<PrescribedItemDetailsProps> = ({
  prescribedItem,
  formErrors,
  setFormErrors,
  onChange,
  onSelectPBSOrRPBS,
}) => {
  const dispatch = useAppDispatch()
  const isControlledSubstance = isSchedule8Medicine(prescribedItem)
  const { prescriber } = useAppSelector((state) => state.prescriber)
  const { currentClinic } = useAppSelector<ClinicContextState>((state) => state.clinic)

  const [isTablet] = useMediaQuery(`(min-width: ${TABLET_BREAKPOINT})`)

  const { SimpleModal, openModal } = useSimpleModal()

  let drugPackOptions: { label: string; value: DurgPack }[] = []
  if (prescribedItem.productDetails !== undefined) {
    drugPackOptions = getDrugPackOptionsFromAMT(
      prescribedItem.productDetails.amt,
      prescribedItem.productDetails.productType
    )
  }

  const handlePBSTypeChange = (type: string) => {
    const isPBS = type === 'PBS'
    const isRPBS = type === 'RPBS'
    const isPrivate = type === 'Private'
    const isSection100 = type === 'Section 100'
    if (
      (isPBS || isRPBS) &&
      !prescribedItem.isPBS &&
      !prescribedItem.isRPBS &&
      !prescribedItem.customPrescriptionPBSCode
    ) {
      onSelectPBSOrRPBS()
      return
    }
    const changes = {
      ...prescribedItem,
      privatePrescription: isPrivate,
      isPBS,
      isRPBS,
      unlistedItemRepatAuthority: isRPBS ? prescribedItem.unlistedItemRepatAuthority : false,
      isSection100,
      controlledSubstanceReference: isSection100 ? 'Section 100' : undefined,
      pbsCode: isPBS || isRPBS ? prescribedItem.customPrescriptionPBSCode : '',
    }

    onChange(
      isPrivate
        ? {
            ...changes,
            ...EMPTY_PBS_DATA,
          }
        : changes
    )
  }

  const handleDrugPackChange = (pack?: DurgPack) => {
    onChange(
      pack
        ? {
            ...prescribedItem,
            genericName: pack.mppName,
            tradeName:
              prescribedItem.productDetails?.productType === 'generic'
                ? pack.mppName
                : pack.tppName,
          }
        : {
            ...prescribedItem,
            tradeName: prescribedItem.productDetails?.productName || '',
            genericName:
              prescribedItem.productDetails?.vis.map((vi) => vi.viName).join(' + ') || '',
          }
    )
  }

  const handleQuantityAndRepeatsChange = (quantity: number, repeats: number) => {
    onChange({
      ...prescribedItem,
      quantity,
      numberOfRepeatsAuthorised: repeats,
      repeatIntervals: repeats < 1 ? undefined : prescribedItem.repeatIntervals,
      phoneApprovalAuthorityNumber: hasUnusualQuantity({
        ...prescribedItem,
        quantity,
        numberOfRepeatsAuthorised: repeats,
      })
        ? prescribedItem.phoneApprovalAuthorityNumber &&
          prescribedItem.phoneApprovalAuthorityNumber !== prescribedItem.streamlinedCode
          ? prescribedItem.phoneApprovalAuthorityNumber
          : ''
        : prescribedItem.phoneApprovalAuthorityNumber || prescribedItem.streamlinedCode,
    })
  }

  return (
    <VStack alignItems="stretch" key={prescribedItem.productId}>
      <Card p="3">
        <VStack alignItems="stretch" spacing="6">
          <Stack spacing="6" alignItems="flex-start" direction={['column', 'row']}>
            <VStack
              flexGrow="1"
              flexBasis="0"
              alignItems="stretch"
              width="100%"
              spacing={4}
              sx={{
                'input, .chakra-select__wrapper': {
                  flexBasis: '200px',
                  flexGrow: '1',
                },
              }}
            >
              {drugPackOptions?.length > 0 ? (
                <SelectField
                  className="drug_pack_details"
                  label="Drug Pack Detail:"
                  required
                  options={drugPackOptions.map((amt) => ({
                    label: amt.label,
                    value: amt.label,
                  }))}
                  onChange={(e) => {
                    const selectedPack = drugPackOptions.find(
                      (amt) => e.target.value === amt.label
                    )?.value
                    handleDrugPackChange(selectedPack)
                  }}
                />
              ) : null}
              <VStack align="flex-start">
                <HStack w={['100%', '80%', '50%']}>
                  <Text fontWeight="bold">
                    <span style={{ color: 'red' }}>* </span>Quantity
                  </Text>
                  <Text>
                    (<strong>PBS Max:</strong>&nbsp;{prescribedItem.maximumQuantity})
                  </Text>
                  <Input
                    key={prescribedItem.quantity}
                    type="number"
                    defaultValue={prescribedItem.quantity}
                    onBlur={(e) => {
                      handleQuantityAndRepeatsChange(
                        Number(e.target.value),
                        Number(prescribedItem.numberOfRepeatsAuthorised)
                      )
                    }}
                  />
                </HStack>
                <HStack w={['100%', '80%', '50%']}>
                  <Text fontWeight="bold">
                    <span style={{ color: 'red' }}>* </span>
                    Repeats
                  </Text>
                  <Text>
                    (<strong>PBS Max:</strong> {prescribedItem.maximumRepeats})
                  </Text>
                  <Input
                    type="number"
                    key={`${prescribedItem.maximumRepeats}${prescribedItem.numberOfRepeatsAuthorised}
                    `}
                    defaultValue={prescribedItem.numberOfRepeatsAuthorised}
                    onBlur={(e) => {
                      const repeats = Math.max(Number(e.target.value), 0)
                      handleQuantityAndRepeatsChange(prescribedItem.quantity, repeats)
                    }}
                  ></Input>
                </HStack>
                {prescribedItem.numberOfRepeatsAuthorised > 0 ? (
                  <HStack w={['100%', '80%', '50%']}>
                    <Text fontWeight="bold">
                      {repeatIntervalRequired(
                        prescribedItem.numberOfRepeatsAuthorised,
                        currentClinic?.state,
                        prescribedItem.productDetails
                      ) ? (
                        <span style={{ color: 'red' }}>* </span>
                      ) : null}
                      Repeat Interval (days):
                    </Text>
                    <ClickableTooltip label={REPEAT_INTERVAL_TOOLTIP} fontSize="sm">
                      <InfoIcon color="blue.500" />
                    </ClickableTooltip>
                    <NumberInput
                      flexGrow={1}
                      isInvalid={
                        repeatIntervalRequired(
                          prescribedItem.numberOfRepeatsAuthorised,
                          currentClinic?.state,
                          prescribedItem.productDetails
                        ) &&
                        (prescribedItem.repeatIntervals === 0 ||
                          prescribedItem.repeatIntervals === undefined)
                      }
                    >
                      <NumberInputField
                        value={prescribedItem.repeatIntervals}
                        onChange={(e) => {
                          onChange({
                            ...prescribedItem,
                            repeatIntervals:
                              e.target.value === '' ||
                              e.target.value === undefined ||
                              isNaN(Number(e.target.value))
                                ? undefined
                                : Number(e.target.value),
                          })
                        }}
                      />
                    </NumberInput>
                  </HStack>
                ) : null}
              </VStack>
              <HStack>
                <RadioGroup
                  defaultValue="Private"
                  value={getPbsStatus(prescribedItem)}
                  onChange={handlePBSTypeChange}
                >
                  <HStack spacing="6">
                    {get(prescribedItem, 'productDetails.pbs', [])?.length > 0 ||
                    !!get(prescribedItem, 'customPrescriptionPBSCode', '') ? (
                      <>
                        <Radio value="PBS">PBS</Radio>
                        <Radio value="RPBS">RPBS</Radio>
                      </>
                    ) : null}
                    <Radio value="Private">Private</Radio>
                    <Radio value="Section 100">Section 100</Radio>
                  </HStack>
                </RadioGroup>
              </HStack>
              {prescribedItem.isRPBS ? (
                <HStack>
                  <Checkbox
                    spacing="4"
                    iconSize="lg"
                    fontWeight="bold"
                    flexBasis="80%"
                    isChecked={prescribedItem.unlistedItemRepatAuthority}
                    onChange={(e) => {
                      onChange({
                        ...prescribedItem,
                        unlistedItemRepatAuthority: e.target.checked,
                      })
                    }}
                  >
                    Unlisted Repat Authority
                  </Checkbox>
                </HStack>
              ) : null}
              <HStack w={['100%', '80%', '50%']}>
                <Text fontWeight="bold">PBS Code:</Text>
                <Input
                  placeholder=""
                  value={prescribedItem.pbsCode}
                  onChange={(e) => {
                    onChange({
                      ...prescribedItem,
                      pbsCode: e.target.value,
                    })
                  }}
                ></Input>
              </HStack>
              <ScriptNumber formErrors={formErrors} setFormErrors={setFormErrors} />
              <HStack w={['100%', '80%', '50%']}>
                <Text fontWeight="bold">PBS Authority:</Text>
                <Input
                  value={prescribedItem.phoneApprovalAuthorityNumber}
                  onChange={(e) => {
                    onChange({
                      ...prescribedItem,
                      phoneApprovalAuthorityNumber: e.target.value.replaceAll(/\s/g, ''),
                    })
                  }}
                />
              </HStack>
              <VStack align="flex-start">
                {isAuthPhoneRequiredForPrescribedItem(prescribedItem) && (
                  <Text fontWeight="bold" color="red.500">
                    Requires Online/Phone Authority
                  </Text>
                )}
                <Text align="right">
                  RPBS Authority Hotline:{' '}
                  <Link color="blue.500" href="tel:1800 552 580">
                    1800 552 580
                  </Link>
                </Text>
                <Text align="right">
                  PBS Authority Hotline:{' '}
                  <Link color="blue.500" href="tel:1800 888 333">
                    1800 888 333
                  </Link>
                </Text>
              </VStack>
              <SimpleGrid columns={isTablet ? 2 : 1} spacingX={4} spacingY={4}>
                <VStack align="flex-start" spacing={4}>
                  <HStack>
                    <Checkbox
                      spacing="4"
                      iconSize="lg"
                      fontWeight="bold"
                      isChecked={prescribedItem.unusualDoseFlag}
                      onChange={(e) => {
                        onChange({
                          ...prescribedItem,
                          unusualDoseFlag: e.target.checked,
                        })
                      }}
                    >
                      <Text>Unusual Dose</Text>
                    </Checkbox>
                    <ClickableTooltip label={UNUSUAL_DOSE_TOOLTIP} fontSize="sm">
                      <InfoIcon color="blue.500" />
                    </ClickableTooltip>
                  </HStack>
                  <HStack>
                    <Checkbox
                      spacing="4"
                      iconSize="lg"
                      fontWeight="bold"
                      isChecked={prescribedItem.regulation24}
                      onChange={(e) => {
                        onChange({
                          ...prescribedItem,
                          regulation24: e.target.checked,
                        })
                      }}
                    >
                      <Text>Regulation 49 (previously Regulation 24)</Text>
                    </Checkbox>
                    <ClickableTooltip label={REGULATION_49_TOOLTIP} fontSize="sm">
                      <InfoIcon color="blue.500" />
                    </ClickableTooltip>
                  </HStack>
                  <HStack>
                    <Checkbox
                      spacing="4"
                      iconSize="lg"
                      fontWeight="bold"
                      isChecked={prescribedItem.brandSubstitutionNotAllowed}
                      onChange={(e) => {
                        onChange({
                          ...prescribedItem,
                          brandSubstitutionNotAllowed: e.target.checked,
                        })
                      }}
                    >
                      <Text>Brand substitution not permitted</Text>
                    </Checkbox>
                    <ClickableTooltip
                      label={BRAND_SUBSTITUTION_NOT_PERMITTED_TOOLTIP}
                      fontSize="sm"
                    >
                      <InfoIcon color="blue.500" />
                    </ClickableTooltip>
                  </HStack>
                </VStack>
                <VStack align="flex-start" spacing={4}>
                  <HStack>
                    <Checkbox
                      spacing="4"
                      iconSize="lg"
                      fontWeight="bold"
                      isChecked={prescribedItem.emergencySupply}
                      onChange={(e) => {
                        onChange({
                          ...prescribedItem,
                          emergencySupply: e.target.checked,
                        })
                      }}
                    >
                      <Text>Emergency Supply</Text>
                    </Checkbox>
                    <ClickableTooltip label={EMERGENCY_SUPPLY_TOOLTIP} fontSize="sm">
                      <InfoIcon color="blue.500" />
                    </ClickableTooltip>
                  </HStack>
                  <HStack>
                    <Checkbox
                      spacing="4"
                      iconSize="lg"
                      fontWeight="bold"
                      isChecked={prescribedItem.noMySL}
                      onChange={(e) => {
                        onChange({
                          ...prescribedItem,
                          noMySL: e.target.checked,
                        })
                      }}
                    >
                      <Text>DO NOT send to Active Script List</Text>
                    </Checkbox>
                    <ClickableTooltip
                      label={DO_NOT_SEND_TO_ACTIVE_SCRIPT_LIST_TOOLTIP}
                      fontSize="sm"
                    >
                      <InfoIcon color="blue.500" />
                    </ClickableTooltip>
                  </HStack>
                  {prescribedItem.productDetails?.aip?.lemi === true ||
                  !prescribedItem.productDetails?.brand ? null : (
                    <HStack>
                      <Checkbox
                        spacing="4"
                        iconSize="lg"
                        fontWeight="bold"
                        isChecked={prescribedItem.brandRequired}
                        onChange={(e) => {
                          const patch = prescribedItem.productDetails
                            ? {
                                ...prescribedItem,
                                brandRequired: e.target.checked,
                                displayName: getProductDisplayName({
                                  product: prescribedItem.productDetails,
                                  brandRequired: e.target.checked,
                                }),
                              }
                            : { ...prescribedItem, brandRequired: e.target.checked }
                          onChange(patch)
                        }}
                      >
                        <Text>Brand is required (taking into consideration the LMBC)</Text>
                      </Checkbox>
                      <ClickableTooltip label={BRAND_IS_REQUIRED_TOOLTIP} fontSize="sm">
                        <InfoIcon color="blue.500" />
                      </ClickableTooltip>
                    </HStack>
                  )}
                </VStack>
              </SimpleGrid>
              <SelectField
                className="treatment_use"
                label="Treatment Use"
                required={['D', 'F', 'E', 'T'].includes(prescriber?.prescriberType || '')}
                options={[
                  { value: 'For dental treatment only' },
                  { value: 'For midwifery use only' },
                  { value: 'For optometry use only' },
                  { value: 'For podiatric treatment only' },
                  {
                    value: 'For treatment of foot conditions only',
                  },
                  { value: 'For ocular treatment only' },
                ]}
                onChange={(e) => {
                  onChange({
                    ...prescribedItem,
                    treatment: e.target.value,
                  })
                }}
              />
            </VStack>
          </Stack>
          {isControlledSubstance && (
            <VStack alignItems="stretch">
              <HStack>
                <Text fontWeight="bold">{getLabelOfAuthority(currentClinic?.state)}</Text>
                <ClickableTooltip label={CONTROLLED_SUBSTANCE_TOOLTIP} fontSize="sm">
                  <InfoIcon color="blue.500" />
                </ClickableTooltip>
              </HStack>
              <Input
                placeholder=""
                value={prescribedItem.controlledSubstanceReference}
                onChange={(e) => {
                  onChange({
                    ...prescribedItem,
                    controlledSubstanceReference: e.target.value,
                  })
                }}
              ></Input>
            </VStack>
          )}
          <VStack alignItems="stretch">
            <HStack>
              <Text fontWeight="bold">Send To Pharmacy</Text>
              <ClickableTooltip label={SEND_TO_PHARMACY_TOOLTIP} fontSize="sm">
                <InfoIcon color="blue.500" />
              </ClickableTooltip>
            </HStack>
            <Select
              value={prescribedItem.sendToPharmacy}
              onChange={(e) => {
                const newValue = e.target.value
                const change = () => {
                  onChange({
                    ...prescribedItem,
                    sendToPharmacy: newValue,
                  })
                  if (newValue) {
                    dispatch(
                      setDeliveryState({
                        recipientEmail: '',
                        recipientMobile: '',
                        sendViaSMS: false,
                        sendViaEmail: false,
                        printPaperToken: true,
                      })
                    )
                  }
                }
                prescribedItem.sendToPharmacy
                  ? change()
                  : openModal({
                      onOk: change,
                      title: 'Update Send To Pharmacy',
                      body: (
                        <p>
                          Selecting this option delivers the prescription details to the patient's
                          My Script List (MySL).
                          <br />
                          <br />
                          <strong>IMPORTANT:</strong> If the patient is not registered with MySL, DO
                          NOT select this option as no electronic token will be generated.
                        </p>
                      ),
                    })
              }}
            >
              <option value=""></option>
              <option value="DosingPoint">Dosing Point</option>
              <option value="StagedSupply">Staged Supply</option>
              <option value="RepeatOnFile">Repeat On File</option>
            </Select>
          </VStack>

          <VStack alignItems="stretch">
            <Text fontWeight="bold">
              <span style={{ color: 'red' }}>* </span>
              Directions (max 250 character)
            </Text>
            <Input
              className="drug_directions"
              placeholder=""
              maxLength={250}
              value={prescribedItem.patientInstructions}
              onChange={(e) => {
                onChange({
                  ...prescribedItem,
                  patientInstructions: e.target.value,
                })
              }}
              onBlur={() => {
                setFormErrors({
                  ...formErrors,
                  patientInstructions: {
                    ...formErrors.patientInstructions,
                    showError: !prescribedItem?.patientInstructions,
                  },
                })
              }}
            ></Input>
            {formErrors.patientInstructions.showError ? (
              <Text color={'red.500'} fontWeight={500}>
                {formErrors.patientInstructions.message}
              </Text>
            ) : null}
          </VStack>
          <VStack alignItems="stretch">
            <Text fontWeight="bold">Reason for Prescribing (optional, max 50 characters)</Text>
            <Input
              placeholder=""
              maxLength={50}
              value={prescribedItem.reasonForPrescribing}
              onChange={(e) => {
                onChange({
                  ...prescribedItem,
                  reasonForPrescribing: e.target.value,
                })
              }}
            ></Input>
          </VStack>
          <VStack alignItems="stretch">
            <Text fontWeight="bold">Notes (optional, max 50 characters)</Text>
            <Textarea
              placeholder=""
              maxLength={50}
              value={prescribedItem.doctorsNotes}
              onChange={(e) => {
                onChange({
                  ...prescribedItem,
                  doctorsNotes: e.target.value,
                })
              }}
            ></Textarea>
          </VStack>
          <HStack>
            <Text fontWeight="bold">CTG Annotation (optional)</Text>
            <Input
              value={prescribedItem.ctgAnnotation}
              onChange={(e) => {
                onChange({
                  ...prescribedItem,
                  ctgAnnotation: e.target.value,
                })
              }}
            />
          </HStack>
        </VStack>
      </Card>
      <SimpleModal />
    </VStack>
  )
}

export default PrescribedItemDetails
