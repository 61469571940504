import { createApi } from '@reduxjs/toolkit/query/react'
import { AxiosRequestConfig } from 'axios'
import { pickBy, identity } from 'lodash'
import { ProductDetails } from '../pages/NewScriptPage/ScriptForm/ProductSearchContextSlice'
import { PrescribedItem, Prescription } from '../types'
import { axiosBaseQuery } from './baseQuery'

interface PrescriptionListQuery {
  page?: string
  limit?: string
  search?: string
  patientId?: string
}

interface ListResponse<T> {
  meta: {
    totalPages: number
    totalItems: number
  }
  data: T[]
}

interface CancelPrescriptionRequest {
  scid: string
  originalGUID?: string
  prescriberId: string
  patientId: string
  items: PrescribedItem[]
  sequenceItemsToDelete: number[] // Sequence Number: Index starts from 1
  isPaperPrescription: boolean
}

interface ReIssuePrescriptionRequest {
  scid: string
  email?: string
  phone?: string
}

interface CreatePrescriptionResponse {
  eRxPrescription: {
    status: string
    payload: any
    errorList: unknown
  }
  prescription: Prescription
}

interface CreatePrescriptionRequestBody {
  prescriberId: string
  patientId: string
  items: DrugItem[]
  isPaperPrescription: boolean
  scid: string
  guid: string
  sendViaEmail: boolean
  sendViaSMS: boolean
  recipientEmail: string
  recipientMobile: string
}

interface CreatePrescriptionRequest {
  newPrescription: CreatePrescriptionRequestBody
  config?: {
    signal?: AxiosRequestConfig['signal']
    headers?: AxiosRequestConfig['headers']
  }
}

export interface DrugItem extends PrescribedItem {
  productDetails: ProductDetails
  displayName: string
}

interface CeasePrescriptionResponse {
  status: string
}

export const prescriptionsApi = createApi({
  reducerPath: 'prescriptionsApi',
  tagTypes: ['Prescriptions'],
  baseQuery: axiosBaseQuery({ baseUrl: '/bff/prescription' }),
  endpoints: (builder) => ({
    getAllPrescriptions: builder.query<ListResponse<Prescription>, PrescriptionListQuery>({
      query: (query) => {
        const urlQuery = new URLSearchParams(pickBy(query, identity)).toString()
        return {
          url: `?${urlQuery}`,
          method: 'get',
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ scid }) => ({ type: 'Prescriptions' as const, id: scid })),
              { type: 'Prescriptions', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Prescriptions', id: 'PARTIAL-LIST' }],
    }),
    ceasePrescription: builder.mutation<CeasePrescriptionResponse, string>({
      query: (scid) => ({
        method: 'PUT',
        url: `/cease/${scid}`,
      }),
      invalidatesTags: (_result, _error, scid) => [
        {
          type: 'Prescriptions',
          id: scid,
        },
      ],
    }),
    cancelPrescription: builder.mutation<CreatePrescriptionResponse, CancelPrescriptionRequest>({
      query: (cancelPrescriptionData) => ({
        url: '/cancel',
        method: 'PUT',
        data: cancelPrescriptionData,
      }),
      invalidatesTags: (_result, _error, { scid }) => [
        {
          type: 'Prescriptions',
          id: scid,
        },
      ],
    }),
    reIssuePrescription: builder.mutation<any, ReIssuePrescriptionRequest>({
      query: (reIssuePrescriptionData) => ({
        url: '/re-issue',
        method: 'PUT',
        data: reIssuePrescriptionData,
      }),
      invalidatesTags: (_result, _error, { scid }) => [
        {
          type: 'Prescriptions',
          id: scid,
        },
      ],
    }),
    createPrescription: builder.mutation<CreatePrescriptionResponse, CreatePrescriptionRequest>({
      query: (request) => ({
        url: '',
        method: 'POST',
        data: request.newPrescription,
        signal: request.config?.signal,
        headers: request.config?.headers,
      }),
      invalidatesTags: () => ['Prescriptions'],
    }),
  }),
})

export const {
  useGetAllPrescriptionsQuery,
  useCeasePrescriptionMutation,
  useCancelPrescriptionMutation,
  useCreatePrescriptionMutation,
  useReIssuePrescriptionMutation,
} = prescriptionsApi
