import { useState } from 'react'

function useApi<Request, Response>(
  clientFunction: (request: Request) => Promise<Response>,
  {
    onSuccess = () => undefined,
    onFailure = () => undefined,
  }: {
    onSuccess?: (resp: Response) => any
    onFailure?: (error: any) => any
  } = {}
) {
  const [loading, setLoading] = useState<boolean>(false)
  const [completed, setCompleted] = useState<boolean>(false)
  const [response, setResponse] = useState<Response | null>(null)
  const [error, setError] = useState<any>(undefined)

  const reset = () => {
    setCompleted(false)
    setLoading(false)
    setError(undefined)
    setResponse(null)
  }

  const trigger = async (request: Request): Promise<Response | undefined> => {
    try {
      setCompleted(false)
      setLoading(true)
      setError(undefined)
      if (!loading) {
        const response = await clientFunction(request)
        setResponse(response)
        onSuccess(response)
        return response
      } else {
        return
      }
    } catch (error) {
      setError(error)
      onFailure(error)
    } finally {
      setLoading(false)
      setCompleted(true)
    }
  }

  return { trigger, reset, loading, response, error, completed }
}

export default useApi
