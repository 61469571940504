import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FunctionComponent } from 'react'
import { useCancelPrescriptionMutation } from '../../../services/prescriptions'
import { GeneralError, Prescription } from '../../../types'

interface CancelScriptModalProps {
  isOpen: boolean
  onClose: () => void
  prescription: Prescription
  sequenceItem?: number
  children?: FunctionComponent<{
    openModal: () => void
  }>
}

const generateError = (result: any) => {
  if (!result.isError || !result.error) {
    return null
  }
  const cancelPrescriptionError = result.error.data as GeneralError
  const errorMessage =
    cancelPrescriptionError?.code === 'E02002'
      ? 'Sorry, the prescription is locked and you can not cancel it at this moment, please try again later.'
      : cancelPrescriptionError?.message

  return { message: errorMessage }
}

const CancelScriptModal: FunctionComponent<CancelScriptModalProps> = ({
  isOpen,
  onClose,
  prescription,
  sequenceItem,
}) => {
  const [cancelPrescription, result] = useCancelPrescriptionMutation()
  const successfulMessage = result.isSuccess ? 'Script has successfully been cancelled' : null
  const error = generateError(result)

  const cancelCurrentPrescription = async () => {
    return cancelPrescription({
      originalGUID: prescription.guid,
      scid: prescription.scid,
      prescriberId: prescription.prescriber.id,
      patientId: prescription.patient.id,
      items: prescription.prescribedItems,
      isPaperPrescription: prescription.isPaperPrescription,
      sequenceItemsToDelete:
        sequenceItem !== undefined
          ? [sequenceItem]
          : prescription.prescribedItems.map((item, index) => item.sequence ?? index + 1),
    })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          result.reset()
          onClose()
        }}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="600px">
          <ModalHeader>Cancel Script</ModalHeader>
          {(error || successfulMessage) && (
            <Alert status={error ? 'error' : 'success'}>
              <AlertIcon />
              <AlertDescription fontSize="sm">
                {error ? error.message || 'Sorry, cancel prescription failed.' : successfulMessage}
              </AlertDescription>
            </Alert>
          )}
          <ModalCloseButton />
          <ModalBody>
            <HStack>
              <Text>Are you sure you want to cancel this prescription?</Text>
            </HStack>
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button
              mr={3}
              onClick={() => {
                result.reset()
                onClose()
              }}
            >
              Back
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                result.reset()
                cancelCurrentPrescription()
              }}
              isLoading={result.isLoading}
              disabled={result.isLoading || !!successfulMessage || !!error}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CancelScriptModal
