import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { FC } from 'react'
import { useLogout } from '../../../../hooks/useLogout'

interface LogoutWarningModalProps {
  content: string
  isOpen: boolean
  onClose: () => void
}

const LogoutWarningModal: FC<LogoutWarningModalProps> = ({ content, isOpen, onClose }) => {
  const logout = useLogout()

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={() => logout()}>
            Logout
          </Button>
          <Button colorScheme="blue" onClick={onClose}>
            Back
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default LogoutWarningModal
