import { HStack, Text, VStack } from '@chakra-ui/react'
import { FunctionComponent } from 'react'
import Card from '../../common/components/Card'
import { ProductDetailsCustom } from './ScriptForm/ProductSearchContextSlice'

interface DrugDetailsProps {
  details?: ProductDetailsCustom
}

const DrugDetails: FunctionComponent<DrugDetailsProps> = ({ details }) => {
  return (
    <Card p="4">
      {details && (
        <VStack alignItems="flex-start">
          <HStack alignItems={['center', 'stretch']}>
            <Text fontWeight="bold">Compound Name:</Text>
            <Text>{details.tradeName}</Text>
          </HStack>
          <HStack alignItems={['center', 'stretch']}>
            <Text fontWeight="bold">Dosage Form:</Text>
            <Text>{details.form}</Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold">Item Strength:</Text>
            <Text>{details.strength}</Text>
          </HStack>
          <HStack alignItems={['center', 'stretch']}>
            <Text fontWeight="bold">Route of Administration</Text>
            <Text>{details.routeAdministration}</Text>
          </HStack>
        </VStack>
      )}
    </Card>
  )
}

export default DrugDetails
