import { createApi } from '@reduxjs/toolkit/query/react'
import { CustomPrescriptionItem } from '../pages/NewScriptPage/CustomPrescriptionItem/CustomPrescriptionItem'
import { generatePaginationQuery } from '../utils/service/service-helper'
import { axiosBaseQuery } from './baseQuery'

interface GetCustomPrescriptionItemsParam {
  page?: string | number
  limit?: string | number
  searchTerm?: string
}

interface UpdateCustomPrescriptionQueryObj {
  newPrescription: Partial<CreateCustomPrescriptionItemReq>
  prescriptionItemId: string
}

export interface CreateCustomPrescriptionItemReq {
  compoundName: string
  dosageForm: string
  itemStrength: string
  routeOfAdministration: string
  quantity?: string
  repeats?: number
  pbsCode?: string
}

export const customPrescriptionItemsApi = createApi({
  reducerPath: 'customerPrescriptionItems',
  tagTypes: ['CustomerPrescriptionItems'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/bff/prescription-items',
  }),
  endpoints: (build) => ({
    getCustomPrescriptionItems: build.query<
      {
        data: CustomPrescriptionItem[]
        meta: { totalPages: number; totalItems: number }
      },
      GetCustomPrescriptionItemsParam
    >({
      query: ({ page, limit, searchTerm }) => {
        const queryStr = generatePaginationQuery(limit, page, searchTerm)
        return {
          url: `?${queryStr}`,
          method: 'get',
        }
      },
      // define which 'tag' is attached to the cached data returned by the query.
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CustomerPrescriptionItems' as const,
                id,
              })),
              { type: 'CustomerPrescriptionItems', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'CustomerPrescriptionItems', id: 'PARTIAL-LIST' }],
    }),
    createCustomPrescriptionItem: build.mutation<
      CustomPrescriptionItem,
      CreateCustomPrescriptionItemReq
    >({
      query: (newPrescription) => {
        if (!newPrescription) {
          throw new Error('New Prescription is required to create custom prescription.')
        }
        return {
          url: '/',
          method: 'post',
          data: newPrescription,
        }
      },
      // Determines which cached data should be either re-fetched or removed from the cache. Expects the same shapes as providesTags.
      invalidatesTags: ['CustomerPrescriptionItems'],
    }),
    updateCustomPrescriptionItem: build.mutation<
      CustomPrescriptionItem,
      UpdateCustomPrescriptionQueryObj
    >({
      query: ({ prescriptionItemId, newPrescription }) => {
        if (!prescriptionItemId) {
          throw new Error('Prescription Id is required to update CustomPrescription.')
        }

        if (!newPrescription) {
          throw new Error('New prescription is required to update prescription.')
        }

        return {
          url: `/${prescriptionItemId}`,
          method: 'put',
          data: newPrescription,
        }
      },
      invalidatesTags: (result, error, { prescriptionItemId }) => [
        { type: 'CustomerPrescriptionItems', id: prescriptionItemId },
      ],
    }),
    deleteCustomPrescriptionItem: build.mutation<null, string>({
      query: (prescriptionItemId) => {
        if (!prescriptionItemId) {
          throw new Error('prescriptionItemId is required to delete custom prescription.')
        }

        return {
          url: `/${prescriptionItemId}`,
          method: 'delete',
        }
      },
      invalidatesTags: (_result, _error, prescriptionItemId) => [
        { type: 'CustomerPrescriptionItems', id: prescriptionItemId },
        { type: 'CustomerPrescriptionItems', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
})

export const {
  useGetCustomPrescriptionItemsQuery,
  useCreateCustomPrescriptionItemMutation,
  useUpdateCustomPrescriptionItemMutation,
  useDeleteCustomPrescriptionItemMutation,
} = customPrescriptionItemsApi
