import {
  Button,
  Card,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import TitleWithLogo from '../../components/TitleWithLogo'
import { CheckIcon, EmailIcon } from '@chakra-ui/icons'
import useApi from '../../common/utils/api/useApi'
import { BillingClient } from './BillingSlice'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { RootState } from '../../app/store'
import { useGAContextData } from '../../utils/google-analytics/useGAContextData'
import GoogleAnalyticsTagManager from '../../utils/google-analytics/GoogleAnalyticsTagManager'

const PricePlanPage = () => {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [disabled, setDisabled] = useState(false)
  const organizationId = useAppSelector(
    (state: RootState) => state.prescriber?.prescriber?.organization.id
  )
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)
  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const GAContextData = useGAContextData()
  useEffect(() => {
    if (!subscription && organizationId && prescriber) {
      dispatch(BillingClient.getSubscription({ prescriberId: prescriber?.id, organizationId }))
    }
  }, [organizationId, dispatch])

  useEffect(() => {
    setDisabled(!prescriber?.active || !!subscription)
  }, [prescriber, subscription])
  const { trigger: triggerCreateCheckoutSession, loading } = useApi(
    BillingClient.createCheckoutSession,
    {
      onSuccess: (checkoutSessionUrl: string) => {
        window.location.href = checkoutSessionUrl
      },
      onFailure: () => {
        toast({
          title: 'You already have a valid subscription',
          status: 'error',
          isClosable: true,
          duration: 3000,
        })
        setDisabled(true)
      },
    }
  )

  const getStarted = (pricePlan: string) => {
    return () => {
      GoogleAnalyticsTagManager.sendGetStartedPaymentEvent({ ...GAContextData, pricePlan })
      triggerCreateCheckoutSession(pricePlan)
    }
  }

  return (
    <VStack overflow="auto" p="24px" minHeight="100%" spacing={[8, 8, 28]}>
      <TitleWithLogo title={'Choose Your Plan'} />
      <SimpleGrid
        width={['80%', '50%', '100%', '80%', '65%']}
        columns={[1, 1, 3]}
        spacing={8}
        alignItems={'center'}
      >
        <Card alignItems={'center'} height={'420px'} paddingY={8}>
          <VStack spacing={'6'} width={'100%'}>
            <Heading size={'lg'} as={'h2'}>
              STANDARD
            </Heading>
            <VStack>
              <Text fontSize="6xl" as="b" fontFamily={'system-ui'}>
                $35
              </Text>
              <Text>per user per month</Text>
            </VStack>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={CheckIcon} color="blue.500" />
                30 scripts per month
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="blue.500" />
                Send script via SMS
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="blue.500" />
                Send script via email
              </ListItem>
            </List>
            <Button
              colorScheme={'blue'}
              disabled={loading || disabled}
              isLoading={loading}
              onClick={getStarted('STANDARD')}
            >
              GET STARTED
            </Button>
          </VStack>
        </Card>
        <Card
          alignItems={'center'}
          height={'450px'}
          paddingBottom={8}
          bgColor={'#032035'}
          color={'white'}
        >
          <Text
            as={'b'}
            fontSize={'12px'}
            width={'100%'}
            bgColor={'darkcyan'}
            textAlign={'center'}
            height={'32px'}
            lineHeight={'32px'}
          >
            MOST POPULAR
          </Text>
          <VStack spacing={'6'} width={'100%'} paddingTop={4}>
            <Heading size={'lg'} as={'h2'}>
              UNLIMITED
            </Heading>
            <VStack>
              <Text fontSize="6xl" as="b" fontFamily={'system-ui'}>
                $65
              </Text>
              <Text>per user per month</Text>
            </VStack>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={CheckIcon} color="darkcyan" />
                Unlimited scripts per month
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="darkcyan" />
                Send script via SMS
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="darkcyan" />
                Send script via email
              </ListItem>
            </List>
            <Button
              colorScheme={'teal'}
              disabled={loading || disabled}
              isLoading={loading}
              onClick={getStarted('UNLIMITED')}
            >
              GET STARTED
            </Button>
          </VStack>
        </Card>
        <Card alignItems={'center'} height={'420px'} paddingY={8}>
          <VStack spacing={'6'} width={'100%'}>
            <Heading size={'lg'} as={'h2'}>
              GROUP
            </Heading>
            <VStack>
              <Text fontSize="40px" as="b" lineHeight={'90px'} fontFamily={'system-ui'}>
                Contact Us
              </Text>
            </VStack>
            <Link href="mailto:support@rxpad.com.au" target="_blank" isExternal>
              <EmailIcon mx="2px" /> support@rxpad.com.au
            </Link>
            <Text height={'128px'} padding={8} textAlign={'center'}>
              Contact us for customised pricing if you run a healthcare organisation.
            </Text>
          </VStack>
        </Card>
      </SimpleGrid>
    </VStack>
  )
}
export default PricePlanPage
