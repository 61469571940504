import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  AlertDialogCloseButton,
  RadioGroup,
  HStack,
  Radio,
  Input,
  Text,
} from '@chakra-ui/react'
import { Prescription } from '../../../types'
import { get } from 'lodash'
import { useReIssuePrescriptionMutation } from '../../../services/prescriptions'

interface CeasePrescriptionModelProps {
  onClose: () => void
  isOpen: boolean
  prescription: Prescription
}

export const ReIssuePrescriptionModal: FunctionComponent<CeasePrescriptionModelProps> = ({
  onClose,
  isOpen,
  prescription,
}) => {
  const [channel, setChannel] = React.useState<'email' | 'phone'>('phone')
  const [electronicAddress, setElectronicAddress] = useState<string>('')
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const [reIssuePrescription, reIssuePrescriptionResult] = useReIssuePrescriptionMutation()

  const onReIssuePrescription = async () => {
    const response = (await reIssuePrescription(
      channel === 'email'
        ? {
            scid: prescription.scid,
            email: electronicAddress,
          }
        : {
            scid: prescription.scid,
            phone: electronicAddress,
          }
    )) as { data: any }

    if (response?.data?.status === 'Success') {
      onClose()
    }
  }

  useEffect(() => {
    if (channel === 'email') {
      setElectronicAddress(get(prescription, 'patient.email', ''))
    } else if (channel === 'phone') {
      setElectronicAddress(get(prescription, 'patient.phone_number', ''))
    }
  }, [channel, prescription])

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Re-send Prescription?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <RadioGroup
              defaultValue="email"
              value={channel}
              onChange={(value: 'email' | 'phone') => setChannel(value)}
            >
              <HStack spacing="6">
                <Radio value="phone">Send via SMS</Radio>
                <Radio value="email">Send via email</Radio>
              </HStack>
            </RadioGroup>
            <Input
              mt={4}
              placeholder={channel}
              value={electronicAddress}
              onChange={(e) => setElectronicAddress(e.target.value)}
            />
            <Text mt={4}>
              Please note, changing these details will not update your patient details
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              isLoading={reIssuePrescriptionResult.isLoading}
              loadingText="Re-sending"
              colorScheme="blue"
              ml={3}
              onClick={onReIssuePrescription}
            >
              Send
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
