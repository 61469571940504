import { Alert, AlertIcon, Button, HStack, Icon, Input, Text, VStack } from '@chakra-ui/react'
import TitleWithLogo from '../../components/TitleWithLogo'
import { useEffect, useState } from 'react'
import useApi from '../../common/utils/api/useApi'
import { InfoIcon, Search2Icon } from '@chakra-ui/icons'
import { Clinic } from '../../types'
import { routes } from '../routes'
import { createOrganization } from '../OrganizationProfilePage/OrganizationSlice'
import { Link, useNavigate } from 'react-router-dom'
import { appDidFinishLaunching } from '../../app/appSlice'
import { useAppDispatch } from '../../app/typedReduxHooks'
import {
  CreatePrescriberRequest,
  PrescriberClient,
} from '../NewScriptPage/ScriptForm/PrescriberSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { STATUS } from '../../constants'
import { setPrescriberCreationPayload } from '../PrescriberProfileCreationPage/PrescriberCreationSlice'
import { ClinicClient } from '../NewScriptPage/ScriptForm/ClinicContextSlice'
import { useFeatureToggle } from '../../common/utils/feature-toggle/useFeatureToggle'
import Page from '../../components/Page'
import ClickableTooltip from '../../components/shared-components/ClickableTooltip'

const HPIO_NUMBER_HINT =
  'HPI-O is a unique 16-digit identifier for your organisation. You will need to ensure your organisation’s HPI-O is linked to RxPad’s CSP Number, otherwise electronic prescribing will not work.  Click here to find out how you can link your HPI-O to the RxPad CSP.'

const OrganizationLinkPage = () => {
  const { isFeatureEnabled: isOrgRegistrationAndPaymentFeatureOn } = useFeatureToggle(
    'ORG_REGISTRATION_AND_PAYMENT'
  )
  const [hpioQuery, setHpioQuery] = useState('')
  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null)
  const [noResult, setNoResult] = useState(false)
  const [isHpiiAlreadyLinked, setIsHpiiAlreadyLinked] = useState(false)
  const navigate = useNavigate()
  const { CreatePrescriber: createPrescriberLoading } = useSelector(
    (state: RootState) => state.prescriber.status
  )
  const { prescriberCreationPayload } = useSelector((state: RootState) => state.prescriberCreation)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!prescriberCreationPayload) {
      navigate(routes.createPrescriberProfile.path)
    }
  })

  const { trigger: checkHPIIAlreadyLinked, loading: checkHPIIAlreadyLinkedLoading } = useApi(
    PrescriberClient.checkHPIIAlreadyLinked,
    {
      onSuccess: (response) => {
        setIsHpiiAlreadyLinked(response.isPrescriberAlreadyLinked)
      },
      onFailure: () => {
        setIsHpiiAlreadyLinked(true)
      },
    }
  )

  const { trigger: getClinicByHPIO, loading: searchLoading } = useApi(
    ClinicClient.getClinicByHPIO,
    {
      onSuccess: (clinic) => {
        setNoResult(false)
        setSelectedClinic(clinic)
        prescriberCreationPayload?.hpii &&
          checkHPIIAlreadyLinked({ hpii: prescriberCreationPayload.hpii, clinicId: clinic.id })
      },
      onFailure: () => {
        setSelectedClinic(null)
        setNoResult(true)
      },
    }
  )
  const { trigger: triggerCreateOrganization, loading: createLoading } = useApi(
    createOrganization,
    {
      onSuccess: async (organization) => {
        if (!prescriberCreationPayload) {
          return
        }
        await dispatch(
          PrescriberClient.CreatePrescriber({
            ...prescriberCreationPayload,
            clinicId: organization.clinic.id,
            organizationId: organization.id,
          } as CreatePrescriberRequest)
        )
        dispatch(setPrescriberCreationPayload(null))
        await dispatch(appDidFinishLaunching())
        isOrgRegistrationAndPaymentFeatureOn
          ? navigate(routes.pricePlan.path)
          : navigate(routes.newScript.path)
      },
    }
  )

  const displayAddress = (selectedClinic: Clinic) => {
    let address = ''
    address += selectedClinic?.address_1 ? selectedClinic?.address_1 + ', ' : ''
    address += selectedClinic?.address_2 ? selectedClinic?.address_2 + ', ' : ''
    address += selectedClinic?.suburb ? selectedClinic?.suburb + ', ' : ''
    address += selectedClinic?.state ? selectedClinic?.state + ', ' : ''
    address += selectedClinic?.postcode ? selectedClinic?.postcode : ''
    return address
  }

  const fetchSearchResult = async () => {
    if (!hpioQuery) return
    await getClinicByHPIO(hpioQuery.trim())
  }

  const linkOrgTonClinic = () => {
    if (!selectedClinic) return
    triggerCreateOrganization({ clinicId: selectedClinic.id })
  }

  return (
    <Page alignItems="center">
      <VStack overflow="auto" p="24px" minHeight="100%" spacing="12" maxWidth="600px" width="100%">
        <TitleWithLogo title="Link Your Organisation" />
        <VStack spacing={2} alignItems="start" width="100%">
          <HStack>
            <Text fontWeight="bold">Find your clinic by HPI-O number </Text>
            <ClickableTooltip label={HPIO_NUMBER_HINT}>
              <InfoIcon color="blue.500" />
            </ClickableTooltip>
          </HStack>
          <HStack spacing={4} width="100%">
            <Input
              bgColor="white"
              placeholder="HPI-O number"
              value={hpioQuery}
              onChange={(e) => setHpioQuery(e.target.value)}
            />
            <Button
              size="sm"
              colorScheme="teal"
              onClick={fetchSearchResult}
              alignSelf="stretch"
              height="inherit"
              isLoading={searchLoading}
              minWidth={'54px'}
            >
              <HStack spacing={1}>
                <Icon as={Search2Icon}></Icon>
                <Text>Find</Text>
              </HStack>
            </Button>
          </HStack>
        </VStack>
        {selectedClinic ? (
          <VStack width="100%" spacing={8}>
            <VStack
              width={'100%'}
              alignItems="start"
              spacing={2}
              padding={6}
              shadow="md"
              rounded="md"
              backgroundColor="gray.50"
            >
              <Text fontSize="1.5em" fontWeight="black">
                {selectedClinic.name}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {selectedClinic.email}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {displayAddress(selectedClinic)}
              </Text>
            </VStack>
            <VStack width={'80%'}>
              <Button
                colorScheme={'linkedin'}
                onClick={linkOrgTonClinic}
                isLoading={
                  createLoading ||
                  checkHPIIAlreadyLinkedLoading ||
                  createPrescriberLoading === STATUS.PENDING
                }
                disabled={
                  !prescriberCreationPayload ||
                  isHpiiAlreadyLinked ||
                  createLoading ||
                  checkHPIIAlreadyLinkedLoading ||
                  createPrescriberLoading === STATUS.PENDING
                }
                width={'100%'}
              >
                Link Now
              </Button>
              {isHpiiAlreadyLinked && (
                <Alert status="warning" rounded="md">
                  <AlertIcon />
                  <Text>Your HPI-I number has already been linked to this clinic.</Text>
                </Alert>
              )}
            </VStack>
          </VStack>
        ) : noResult ? (
          <VStack width="100%" alignItems="start">
            <Alert status="warning" rounded="md">
              <AlertIcon />
              <Text>HPI-O not found in RxPad. </Text>
            </Alert>
            <Text>
              <Link
                to={routes.createClinic.path}
                style={{ color: 'dodgerblue', fontWeight: 'bold' }}
              >
                Click Here
              </Link>{' '}
              to manually create a new organisation.
            </Text>
            https://help.rxpad.com.au/en/articles/7923812-how-do-i-connect-my-clinic-s-hpi-o-to-rxpad-s-csp
            <Text fontStyle="italic" fontSize="sm" color="gray.500" style={{ marginTop: '30px' }}>
              <strong>DISCLAIMER: </strong>If you are an Individual Prescriber, please ensure you
              have obtained consent from your nominated organisation before linking your individual
              prescriber profile with the organisational HPI-O Number. If you are a Clinic Owner /
              Practice Manager, please ensure your HPI-O Number is linked to the RxPad CSP-
              otherwise, your clinicians will be unable to prescribe through RxPad. Click here for
              instructions on how to link your clinic's HPIO to our CSP.
            </Text>
          </VStack>
        ) : null}
      </VStack>
    </Page>
  )
}
export default OrganizationLinkPage
