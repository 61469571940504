import { Button, Heading, HStack, Input, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { FieldErrorsImpl, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { Clinic } from '../../../types'
import { useEffect, useState } from 'react'
import { FORM_STAGE } from '../constant'
import { useAppDispatch } from '../../../app/typedReduxHooks'
import { fetchOrganizationData } from '../../OrganizationProfilePage/OrganizationSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { PrescriberClient } from '../../NewScriptPage/ScriptForm/PrescriberSlice'
import { PrescriberCreation } from '../../../types/prescriber'
import { STATUS } from '../../../constants'
import { insertSpaceEvery4Characters } from '../../../utils/text-formatter'
import { appDidFinishLaunching } from '../../../app/appSlice'
import { routes } from '../../routes'
import { Link } from 'react-router-dom'
import { useFeatureToggle } from '../../../common/utils/feature-toggle/useFeatureToggle'

interface SelectOrganizationFormProps {
  setValue: UseFormSetValue<PrescriberCreation>
  errors: FieldErrorsImpl<PrescriberCreation>
  getValues: UseFormGetValues<PrescriberCreation>
  setFormStage: (stage: FORM_STAGE) => void
}

const clinicTableMapping: { label: string; field: keyof Clinic }[] = [
  { label: 'Email', field: 'email' },
  { label: 'Phone number', field: 'phone_number' },
  { label: 'Hpio number', field: 'hpio_number' },
  { label: 'Address line 1', field: 'address_1' },
  { label: 'Address line 2', field: 'address_2' },
  { label: 'Suburb', field: 'suburb' },
  { label: 'Postcode', field: 'postcode' },
  { label: 'State', field: 'state' },
]

function SelectOrganizationForm({
  errors,
  setValue,
  getValues,
  setFormStage,
}: SelectOrganizationFormProps) {
  const { isFeatureEnabled: isOrgRegistrationAndPaymentFeatureOn } = useFeatureToggle(
    'ORG_REGISTRATION_AND_PAYMENT'
  )
  const [disabledNext, setDisabledNext] = useState(true)
  const [organizationSearchQuery, setOrganizationSearchQuery] = useState('')
  const [haveSearched, setHaveSearched] = useState(false)
  const organization = useSelector((state: RootState) => state.organization?.data)
  const dispatch = useAppDispatch()

  const { CreatePrescriber: createPrescriberLoading } = useSelector(
    (state: RootState) => state.prescriber.status
  )
  const { fetchById: fetchOrgStatus } = useSelector((state: RootState) => state.organization.status)
  const isValidOrganization = fetchOrgStatus === STATUS.SUCCESS && organization?.clinic

  const onNext = async () => {
    await dispatch(PrescriberClient.CreatePrescriber(getValues()))
    await dispatch(appDidFinishLaunching())
  }

  const onBack = () => {
    setFormStage(FORM_STAGE.CREATE_PROFILE)
  }

  useEffect(() => {
    setDisabledNext(!isValidOrganization)
    setValue('organizationId', organization?.id ?? '')
    setValue('clinicId', organization?.clinic?.id ?? '')
  }, [isValidOrganization, organization, setValue])
  useEffect(() => {
    if (organizationSearchQuery.length === 0) {
      setHaveSearched(false)
    }
  }, [organizationSearchQuery])
  const fetchSearchResult = async () => {
    if (organizationSearchQuery.length > 0) {
      await dispatch(fetchOrganizationData(organizationSearchQuery))
      setHaveSearched(true)
    }
  }

  return (
    <>
      <VStack
        spacing={10}
        width={['100%', '80%', '50%']}
        gap={6}
        justifyContent="space-between"
        style={{ marginBottom: '36px' }}
      >
        <VStack spacing={2} alignItems="start">
          <Text fontWeight="bold">Organisation invitation code</Text>
          <HStack>
            <Input
              bgColor="white"
              placeholder="Search organisation"
              value={organizationSearchQuery}
              onChange={(e) => setOrganizationSearchQuery(e.target.value)}
            ></Input>
            <Button
              size="sm"
              colorScheme="teal"
              onClick={fetchSearchResult}
              alignSelf="stretch"
              height="inherit"
              isLoading={fetchOrgStatus === STATUS.PENDING}
            >
              Validate
            </Button>
          </HStack>
          {isOrgRegistrationAndPaymentFeatureOn ? (
            <Link to={routes.linkOrganization.path} style={{ color: 'dodgerblue' }}>
              Can't find your organisation? Create a new one here
            </Link>
          ) : null}
        </VStack>

        <VStack spacing={4}>
          {haveSearched ? (
            isValidOrganization ? (
              <>
                <Heading as="h3" size="md">
                  Organisation Details
                </Heading>
                <HStack>
                  <Text fontWeight="bold">Organisation name</Text>
                  <Text>{organization?.name}</Text>
                </HStack>
                <SimpleGrid columns={[1, 2]} rowGap={2} columnGap={8}>
                  {clinicTableMapping.map(({ label, field }) => (
                    <HStack key={field}>
                      <Text fontWeight="bold">{label}</Text>
                      <Text>
                        {field === 'hpio_number'
                          ? insertSpaceEvery4Characters(organization?.clinic?.[field])
                          : organization?.clinic?.[field]}
                      </Text>
                    </HStack>
                  ))}
                </SimpleGrid>
              </>
            ) : (
              <Text color="red.500">
                Your invitation code is invalid. Please contact your organisation’s administrator.
              </Text>
            )
          ) : (
            <Text>Please search your organisation by invitation code.</Text>
          )}
        </VStack>
      </VStack>

      <SimpleGrid
        columns={[1, 2]}
        gap={4}
        width={['100%', '80%', '30%']}
        style={{ marginTop: 'auto' }}
      >
        <Button colorScheme="blue" onClick={onBack}>
          Back
        </Button>
        <Button
          colorScheme="blue"
          onClick={onNext}
          isLoading={createPrescriberLoading === STATUS.PENDING}
          disabled={
            createPrescriberLoading === STATUS.PENDING ||
            disabledNext ||
            Object.keys(errors).length > 0
          }
        >
          Next
        </Button>
      </SimpleGrid>
    </>
  )
}

export default SelectOrganizationForm
