import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { Prescription } from '../../../types'
import { format, parseISO } from 'date-fns'
import { store } from '../../../app/store'
import { titleMsPrescribersId } from '../../NewScriptPage/SendScriptPanel/PaperPrescription'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const openEOP = (docDefinition: any) => {
  pdfMake.createPdf(docDefinition).open()
}

export const printEvidenceOfPrescription = (prescription: Prescription) => {
  const {
    scid,
    patient: { given_names: patientGivenNames, family_name: patientFamilyName },
    prescriber: {
      id,
      givenNames: prescriberGivenNames,
      familyName: prescriberFamilyName,
      otherPhoneNumber,
    },
    prescribedItems: [{ displayName, tradeName, numberOfRepeatsAuthorised }],
    createdDate,
  } = prescription

  const prescriberTitle = titleMsPrescribersId.includes(id) ? 'Ms' : 'Dr'

  const {
    clinic: {
      currentClinic: {
        name: practiceName,
        address_1,
        address_2,
        phone_number,
        state,
        suburb,
        postcode,
      } = {},
    },
  } = store.getState()

  const prescribedDate = parseISO(createdDate.toString())

  const dd = {
    defaultStyle: {
      fontSize: 9,
    },
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [95, 95],
    content: [
      {
        columnGap: 16,
        columns: [
          {
            width: 450,
            fontSize: 14,
            stack: [
              {
                text: [
                  'Summary of electronic prescription for ',
                  { text: `${patientGivenNames} ${patientFamilyName}`, bold: true },
                ],
                fontSize: 16,
              },
              '\n',
              {
                text: displayName || tradeName,
                fontSize: 14,
                bold: true,
              },
              '\n',
              {
                table: {
                  widths: [300, '*'],
                  body: [
                    [{ text: 'Details', colSpan: 2 }, {}],
                    [{ text: 'Prescribed Date' }, { text: format(prescribedDate, 'dd/MM/yyyy') }],
                    [{ text: 'Repeats Authorised' }, { text: numberOfRepeatsAuthorised }],
                  ],
                },
              },
              '\n',
              {
                text: 'This is an electronic prescription token only. The legal prescription must be downloaded for dispensing.',
                fontSize: 10,
              },
              '\n',
              {
                text: [
                  { text: 'Privacy Notice: ', bold: true },
                  'The privacy and security of your personal information is important to us, and is protected by law. We need to collect this information so we can process and manage your applications and payments, and provide services to you. We only share your information with other parties where you have agreed, or where the law allows or requires it. For more information, go to servicesaustralia.gov.au/privacy',
                ],
                fontSize: 10,
              },
            ],
          },
          {
            width: '*',
            fontSize: 14,
            alignment: 'right',
            stack: [
              { text: `${prescriberTitle} ${prescriberGivenNames} ${prescriberFamilyName}` },
              { text: practiceName },
              { text: address_1 },
              { text: address_2 },
              { text: `${suburb} ${state} ${postcode}` },
              { text: `Phone: ${phone_number}` },
              { text: `Other Phone: ${otherPhoneNumber ?? ''}` },
              '\n',
              { qr: scid, version: 2 },
              '\n',
              { text: scid, fontSize: 11 },
            ],
          },
        ],
      },
      '\n\n\n',
      {
        text: 'NOT TO BE SIGNED',
        alignment: 'center',
        fontSize: 56,
        color: 'grey',
      },
    ],
  }
  openEOP(dd)
}
