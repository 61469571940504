import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import useApi from '../../../common/utils/api/useApi'
import { BillingClient } from '../BillingSlice'

interface ReactivateSubscriptionModalProps {
  isOpen: boolean
  onClose: () => void
  billingPeriodEndDate: string
  subscriptionId: string
  organizationId: string
}
export const ReactivateSubscriptionModal: FC<ReactivateSubscriptionModalProps> = ({
  isOpen,
  onClose,
  billingPeriodEndDate,
  subscriptionId,
  organizationId,
}) => {
  const [successfulMessage, setSuccessfulMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    trigger: triggerReactivateSubscription,
    loading,
    completed,
  } = useApi(BillingClient.reactivateSubscription, {
    onSuccess: () => {
      setErrorMessage('')
      setSuccessfulMessage('Your subscription has been re-activated')
    },
    onFailure: (error) => {
      setSuccessfulMessage('')
      setErrorMessage(error.message)
    },
  })
  const handleOnClick = async () => {
    await triggerReactivateSubscription({ subscriptionId, organizationId })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="600px">
          <ModalHeader>Re-activate Subscription</ModalHeader>
          {(errorMessage || successfulMessage) && (
            <Alert status={errorMessage ? 'error' : 'success'}>
              <AlertIcon />
              <AlertDescription fontSize="sm">
                {errorMessage
                  ? errorMessage || 'Sorry, re-activate subscription failed.'
                  : successfulMessage}
              </AlertDescription>
            </Alert>
          )}
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems={'stretch'}>
              <Text>
                Your plan had been canceled, but is still available until the end of your billing
                period on {billingPeriodEndDate}
              </Text>
              <Text>Are you sure you want to re-activate this subscription?</Text>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button mr={3} onClick={onClose}>
              Back
            </Button>
            <Button
              colorScheme="red"
              onClick={handleOnClick}
              isLoading={loading}
              disabled={loading || completed}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
