import { FC } from 'react'
import { ReactComponent as LogoIcon } from '../../assets/rxpad_logo.svg'
import { ReactComponent as WhiteLogoIcon } from '../../assets/rxpad_logo_white.svg'
import { Container } from '@chakra-ui/react'

export interface LogoProps {
  maxWidth?: number
}

export const WhiteLogo: FC<LogoProps> = ({ maxWidth }) => {
  return (
    <Container maxW={maxWidth}>
      <WhiteLogoIcon />
    </Container>
  )
}

export const Logo: FC<LogoProps> = ({ maxWidth }) => {
  return (
    <Container maxW={maxWidth}>
      <LogoIcon />
    </Container>
  )
}
