import { chakra, Box } from '@chakra-ui/react'
import { FunctionComponent, ReactNode } from 'react'

type CardVariant = 'white' | 'light' | 'dark' | 'paper'

interface CardProps {
  className?: string
  variant?: CardVariant
  borderless?: boolean
  children?: ReactNode
}

const Card: FunctionComponent<CardProps> = (props) => {
  const { variant = 'white', borderless, children } = props
  return (
    <Box
      {...props}
      className={props.className + ` ${variant} ${borderless ? 'borderless' : ''}`}
      sx={{
        borderRadius: '16px',
        '&.white': {
          bgColor: 'white',
          border: '1px solid gray',
          borderColor: 'gray.200',
        },
        '&.light': {
          bgColor: 'gray.100',
          border: '1px solid gray',
          borderColor: 'gray.200',
        },
        '&.dark': {
          color: 'white',
          bgColor: 'gray.800',
          border: '1px solid gray',
          borderColor: 'gray.300',
        },
        '&.paper': {
          bgColor: '#FAF8F3',
          borderColor: 'gray.300',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        '&.borderless': {
          border: 'none',
        },
      }}
    >
      {children}
    </Box>
  )
}

export default chakra(Card)
