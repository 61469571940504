import { Link, Text, VStack } from '@chakra-ui/react'

export const NoRemainingTokensNotificationContent = () => {
  return (
    <VStack alignItems={'stretch'}>
      <Text>You have reached your monthly prescription limit.</Text>
      <Text paddingBottom={8}>
        To start sending prescriptions again, please contact your organisation administrator (if
        known). Otherwise please contact RxPad Support through the Intercom chatbot or email us at{' '}
        <Link href="mailto:support@rxpad.com.au" target="_blank" isExternal>
          support@rxpad.com.au
        </Link>
        .
      </Text>
    </VStack>
  )
}
