/*
  There are two things to do when you want to add a feature
  1. Add one more key to FEATURE_KEYS variable.
  2. Add env vars into .env.local for local testing, .github/workflows/ci.yml for github action
    and .aws-codebuild/buildspec.yml to enable the feature on DEV env. Don't add the env var to
    .aws-codebuild/buildspec_prod.yml for we want to keep the feature off on prod until we finish
    the feature. Please remove the entire feature toggle once you finish the feature.
*/
interface Feature {
  featureName: string
  envVarName: string
}

type Features = {
  [Key in typeof FEATURE_KEYS[number]]?: Feature
}

const FEATURE_KEYS = ['ORG_REGISTRATION_AND_PAYMENT', 'REFERRAL_CODE', 'RTPM'] as const

export const FEATURES: Features = FEATURE_KEYS.reduce(
  (features: Features, featureKey) => ({
    ...features,
    [featureKey]: {
      featureName: featureKey,
      envVarName: `REACT_APP_FEATURE_TOGGLE_${featureKey}_ENABLED`,
    },
  }),
  {}
)
