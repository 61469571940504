import { forOwn, isString } from 'lodash'

type paginationParamTypes = string | number

export const generatePaginationQuery = (
  limit?: paginationParamTypes,
  page?: paginationParamTypes,
  search?: string
) => {
  const query: { [key: string]: string } = {}
  forOwn({ limit, page, search }, (value, key) => {
    if (value) {
      query[key] = isString(value) ? value : String(value)
    }
  })

  return new URLSearchParams(query)
}
