import { PoisonClassification, StateSchedule } from '../../../ScriptForm/ProductSearchContextSlice'
import { FC } from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'

interface ProductStateSchedulesProps {
  stateSchedules: (StateSchedule | null)[]
  poisonClassification?: PoisonClassification
}
const PoisonSchedules: FC<ProductStateSchedulesProps> = ({
  stateSchedules,
  poisonClassification,
}) => {
  return (
    <>
      {poisonClassification ? (
        <HStack alignItems={['center', 'stretch']}>
          <Text fontWeight="bold">{poisonClassification?.poisonClassType}:</Text>
          <Text>{poisonClassification?.poisonClasses?.join(', ')}</Text>
        </HStack>
      ) : null}
      {stateSchedules.length > 0 ? (
        <VStack alignItems="flex-start">
          <Text fontWeight="bold">State Poison Schedule:</Text>
          {stateSchedules.map((schedule) =>
            schedule ? (
              <HStack pl="16px" alignItems={['center', 'stretch']} key={schedule.state}>
                <Text fontWeight="bold">{schedule.state}:</Text>
                <Text>{schedule.schedules}</Text>
              </HStack>
            ) : null
          )}
        </VStack>
      ) : null}
    </>
  )
}

export default PoisonSchedules
