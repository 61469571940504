import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { Prescriber } from '../../types'

export const useGAContextData = () => {
  const prescriber: Prescriber | null = useSelector(
    ({ prescriber }: RootState) => prescriber?.prescriber
  )
  // const organization = useSelector<RootState>(({ organization }) => organization?.data)
  const subscription = useSelector(({ billing }: RootState) => billing?.subscription?.data)

  return {
    userId: prescriber?.userId,
    organizationId: prescriber?.organization?.id,
    subscriptionId: subscription?.id,
    productType: subscription?.productName,
    productId: subscription?.productId,
  }
}
