import styled from '@emotion/styled'
import mapValues from 'lodash/mapValues'
import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { HStack, Select, Text, VStack } from '@chakra-ui/react'
import { DATE_OF_BIRTH, MONTH_LIST } from '../constants'
import { Label } from './InputField'

const Divider = styled.div`
  margin: 0 5px;
  font-size: 1.5rem;
`

export const mergeDateOfBirth = ({
  date,
  month,
  year,
}: {
  date: number
  month: number
  year: number
}) => format(new Date(year, month, date), DATE_OF_BIRTH)

const DateOfBirthSelectGroup: React.FC<any> = ({
  setValue,
  errors,
  register,
  defaultDateOfBirth,
}) => {
  const [dob, setDob] = useState({
    year: '',
    month: '',
    date: '',
  })

  useEffect(() => {
    if (defaultDateOfBirth) {
      const dob = parse(defaultDateOfBirth, DATE_OF_BIRTH, new Date())
      if (dob) {
        setDob({
          year: dob.getFullYear().toString(),
          month: dob.getMonth().toString(),
          date: dob.getDate().toString(),
        })
      }
    }
  }, [defaultDateOfBirth])

  const { ref } = register('date_of_birth', {
    required: 'Date of Birth is required',
    validate: (value: string) => {
      const isValid = /^\d{4}-\d{2}-\d{2}$/.test(value.trim())
      return isValid ? true : 'Failed to validate Date of Birth format'
    },
  })

  const handleDOBChange = (label: string) => (e: any) => {
    const value = e.target.value
    const newDOB = {
      ...dob,
      [label]: value,
    }
    setDob(newDOB)

    if (Object.values(newDOB).every((v) => v)) {
      const dateOfBirth = mergeDateOfBirth(mapValues(newDOB, (v) => Number(v)))
      setValue('date_of_birth', dateOfBirth)
    } else {
      setValue('date_of_birth', '')
    }
  }

  const errorMessage: string = (errors?.date_of_birth?.message as string) || ''

  return (
    <VStack alignItems="stretch" w="100%">
      <Label label="Date of Birth" isRequired />
      <HStack divider={<Divider>/</Divider>}>
        <Select className="dob_date" onChange={handleDOBChange('date')} ref={ref} value={dob.date}>
          <option value="" />
          {Array.from({ length: 31 }, (_, i) => i + 1).map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </Select>
        <Select className="dob_month" onChange={handleDOBChange('month')} value={dob.month}>
          <option value="" />
          {MONTH_LIST.map((label, index) => (
            <option value={index} key={label}>
              {label}
            </option>
          ))}
        </Select>
        <Select className="dob_year" onChange={handleDOBChange('year')} value={dob.year}>
          <option value="" />
          {Array.from(
            { length: Number(new Date().getFullYear()) - 1904 },
            (_, i) => Number(new Date().getFullYear()) - i
          ).map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </Select>
      </HStack>
      {errorMessage && (
        <Text fontSize="sm" fontWeight="700" color="red.600">
          {errorMessage}
        </Text>
      )}
    </VStack>
  )
}

export default DateOfBirthSelectGroup
