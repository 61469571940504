import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import parse from 'html-react-parser'
import { FunctionComponent } from 'react'
import Card from '../../../common/components/Card'
import useReadMore from '../../../hooks/useReadMore'
import { Molecule, ProductDetails } from '../ScriptForm/ProductSearchContextSlice'
import FullPIDetailsModal from './components/FullPIDetailsModal'
import PoisonSchedules from './components/ProductStateSchedules'
import ProductTooltip from './components/ProductTooltip'

const moleculeToString = ({
  moleculeName,
  moleculeStrength,
  moleculeStrengthUnit,
  moleculePerVol,
  moleculePerVolUnit,
}: Molecule): string => {
  let moleculeString = moleculeName
  if (moleculeStrength) {
    moleculeString += ` ${moleculeStrength}${moleculeStrengthUnit || ''}`
  }
  if (moleculePerVol) {
    moleculeString += `/${moleculePerVol}${moleculePerVolUnit}`
  }
  return moleculeString
}

interface DrugDetailsProps {
  details?: ProductDetails
}

const ProductDetailsPanel: FunctionComponent<DrugDetailsProps> = ({ details }) => {
  const molecules = details
    ? details.vis.map((vis) => vis.molecules.map(moleculeToString).join(', ')).join(', ')
    : ''

  const fullProductInformation = details?.fullProductInformation

  const { handleClickReadMore, isFold, displayedText } = useReadMore(molecules, 10)

  if (!details) {
    return null
  }

  return (
    <Card p="4">
      <VStack alignItems="flex-start">
        <HStack alignItems={['center', 'stretch']}>
          <Text fontWeight="bold">Product Name:</Text>
          <Text>{parse(details.productName)}</Text>
        </HStack>
        <VStack alignItems="flex-start">
          <Text fontWeight="bold">Molecules:</Text>
          <Box pl="4">
            <Text>{parse(displayedText)}</Text>
            {isFold && (
              <Button onClick={handleClickReadMore} variant="link">
                Read More
              </Button>
            )}
          </Box>
        </VStack>
        <HStack>
          <Text fontWeight="bold">ROAs:</Text>
          <Text>
            {details.roas?.join(', ') ||
              details.vis
                .map((vi) => vi.roa)
                .flatMap((item) => item)
                ?.join(', ')}
          </Text>
        </HStack>
        <PoisonSchedules
          poisonClassification={details.poisonClassification}
          stateSchedules={details.stateSchedules}
        />
        {details.rtpmStates?.length > 0 && (
          <VStack alignItems="flex-start">
            <Text fontWeight="bold">Real-Time Prescription Monitoring Required For:</Text>
            <Text pl="16px">{details.rtpmStates?.join(', ')}</Text>
          </VStack>
        )}
        {details.indications && (
          <VStack alignItems="flex-start">
            <Text fontWeight="bold">Indications:</Text>
            <Text pl="16px">{details.indications?.join(', ')}</Text>
          </VStack>
        )}
        {details.aip?.lemi ? (
          <ProductTooltip
            text="Prescription will display the brand name only"
            hoverOverText="To comply with Active Ingredient Prescribing requirements, medicines on the List of Excluded Medicinal Items (LEMI) or with four or more active ingredients are required to be prescribed with brand name only"
          />
        ) : null}
        {details.aip?.lmbc ? (
          <ProductTooltip
            text="Prescription should display the brand name as well as active ingredient name"
            hoverOverText="To comply with Active Ingredient Prescribing requirements, medicines on the List of Medicines for Brand Consideration (LMBC) should be prescribed by brand name as well as active ingredient name"
          />
        ) : null}
        {fullProductInformation && (
          <FullPIDetailsModal fullProductInformation={fullProductInformation} />
        )}
      </VStack>
    </Card>
  )
}

export default ProductDetailsPanel
