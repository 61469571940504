import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiClient from '../../utils/api/ApiClient'

export const ReferClient = {
  getReferSummary: createAsyncThunk('summary', async (): Promise<ReferSummary> => {
    const { data } = await ApiClient.get('/bff/refer/summary')
    return data
  }),
}

type ReferSummary = {
  totalReferrals: number
  totalReferralBonus: number
  remainingReferralBonus: number
}

export type ReferState = {
  summary: ReferSummary
}

const referSlice = createSlice<ReferState, Record<string, any>>({
  name: 'refer',
  initialState: {
    summary: {
      totalReferrals: 0,
      totalReferralBonus: 0,
      remainingReferralBonus: 0,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ReferClient.getReferSummary.fulfilled, (state, action) => {
      if (action.payload) {
        state.summary.totalReferrals = action.payload.totalReferrals ?? 0
        state.summary.totalReferralBonus = action.payload.totalReferralBonus ?? 0
        state.summary.remainingReferralBonus = action.payload.remainingReferralBonus ?? 0
      }
    })
  },
})

export default referSlice.reducer
