import { ProductDetails } from '../../ProductSearchContextSlice'

export interface ProductCheckResult {
  passed: boolean
  message?: string
}

export const preCheckProduct = (product?: ProductDetails): ProductCheckResult => {
  if (!product) {
    return {
      passed: false,
    }
  }
  if (product.aip?.lemi === true && !product.brand) {
    return {
      passed: false,
      message: product.aip?.lemiRecommendation,
    }
  }
  if (product.aip?.lmbc === true) {
    return {
      passed: true,
      message:
        'To comply with Active Ingredient Prescribing requirements, medicines on the List of Medicines for Brand Consideration (LMBC) should be prescribed by brand name as well as active ingredient name',
    }
  }
  return { passed: true }
}
