import { FC, useEffect } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import Page from '../../components/Page'
import PrescriberProfilePage from '../PrescriberProfilePage/PrescriberProfilePage'
import OrganizationProfilePage from '../OrganizationProfilePage'
import { SubscriptionInfo } from '../Billing/SubscriptionInfo'
import { RootState } from '../../app/store'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { BillingClient } from '../Billing/BillingSlice'

const SettingsPage: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const organizationId = useAppSelector(
    (state: RootState) => state.prescriber?.prescriber?.organization.id
  )
  const prescriberId = useAppSelector((state) => state.prescriber.prescriber?.id)
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)
  useEffect(() => {
    if (!subscription && organizationId && prescriberId) {
      dispatch(BillingClient.getSubscription({ prescriberId, organizationId }))
    }
  }, [organizationId, dispatch])

  return (
    <Page>
      <Tabs size="lg">
        <TabList>
          <Tab fontWeight="bold">My Profile</Tab>
          <Tab fontWeight="bold">Organisation</Tab>
          {subscription?.stripeSubscriptionId && <Tab fontWeight="bold">Subscription</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <PrescriberProfilePage />
          </TabPanel>
          <TabPanel>
            <OrganizationProfilePage />
          </TabPanel>
          {subscription?.stripeSubscriptionId && (
            <TabPanel>
              <SubscriptionInfo subscription={subscription} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Page>
  )
}

export default SettingsPage
