import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import useApi from '../../../common/utils/api/useApi'
import GoogleAnalyticsTagManager from '../../../utils/google-analytics/GoogleAnalyticsTagManager'
import { useGAContextData } from '../../../utils/google-analytics/useGAContextData'
import { BillingClient } from '../BillingSlice'

interface CancelSubscriptionModalProps {
  isOpen: boolean
  onClose: () => void
  billingPeriodEndDate: string
  subscriptionId: string
  organizationId: string
}
export const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({
  isOpen,
  onClose,
  billingPeriodEndDate,
  subscriptionId,
  organizationId,
}) => {
  const [successfulMessage, setSuccessfulMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const GA4ContextData = useGAContextData()

  const {
    trigger: triggerCancelSubscription,
    loading: cancelLoading,
    completed,
  } = useApi(BillingClient.cancelSubscription, {
    onSuccess: () => {
      setErrorMessage('')
      setSuccessfulMessage('Your subscription has been canceled')
    },
    onFailure: (error) => {
      setSuccessfulMessage('')
      setErrorMessage(error.message)
    },
  })
  const handleOnClick = async () => {
    GoogleAnalyticsTagManager.sendCancelSubscriptionEvent(GA4ContextData)
    await triggerCancelSubscription({ subscriptionId, organizationId })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="600px">
          <ModalHeader>Cancel Subscription</ModalHeader>
          {(errorMessage || successfulMessage) && (
            <Alert status={errorMessage ? 'error' : 'success'}>
              <AlertIcon />
              <AlertDescription fontSize="sm">
                {errorMessage
                  ? errorMessage || 'Sorry, cancel subscription failed.'
                  : successfulMessage}
              </AlertDescription>
            </Alert>
          )}
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems={'stretch'}>
              <Text>
                If you cancel now, you can still access your subscription until the end of your
                billing period on {billingPeriodEndDate}
              </Text>
              <Text>Are you sure you want to cancel this subscription?</Text>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button mr={3} onClick={onClose}>
              Back
            </Button>
            <Button
              colorScheme="red"
              onClick={handleOnClick}
              isLoading={cancelLoading}
              disabled={cancelLoading || completed}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
