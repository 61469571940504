import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  AlertDescription,
  AlertIcon,
  Alert,
  ModalOverlay,
  Modal,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  FormLabel,
  ModalFooter,
  Button,
  FormControl,
  Input,
} from '@chakra-ui/react'
import ApiClient from '../utils/api/ApiClient'
import { RootState } from '../app/store'
const STATUS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  FAILURE: 'failure',
  LOADING: 'loading',
}

const AuthenticationModal: FC<{ isOpen: boolean; onClose: () => void; onSuccess: () => void }> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const [submitStatus, setSubmitStatus] = useState(STATUS.DEFAULT)
  const { email } = useSelector((state: RootState) => state.authentication)

  const { handleSubmit, register } = useForm()

  const onSubmit = async (values: any) => {
    setSubmitStatus(STATUS.LOADING)
    try {
      await ApiClient.post(`/auth/verify`, values)
      onSuccess()
      setSubmitStatus(STATUS.SUCCESS)
    } catch (e: any) {
      console.error(`Failed to verify Email and Password, the error is ${e.message}`)
      setSubmitStatus(STATUS.FAILURE)
    }
  }

  return (
    <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Authenticate your Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form onSubmit={handleSubmit(onSubmit)} id="verifyForm">
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="email"
                {...register('email', {
                  required: 'This is required',
                  value: email,
                })}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                id="password"
                placeholder="Password"
                {...register('password', {
                  required: 'This is required',
                })}
              />
            </FormControl>
          </form>

          {submitStatus === STATUS.FAILURE && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              <AlertDescription>
                Ops, incorrect username or password. You can not submit until passing verification.
              </AlertDescription>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            type="submit"
            form="verifyForm"
            isLoading={submitStatus === STATUS.LOADING}
            loadingText="Submitting"
          >
            Verify
          </Button>
        </ModalFooter>
      </ModalContent>
      You are required to reauthenticate your account when you select a Schedule 8 medicine.
    </Modal>
  )
}

export default AuthenticationModal
