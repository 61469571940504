import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  useDisclosure,
  Heading,
} from '@chakra-ui/react'
import parse from 'html-react-parser'
import { ProductDetails } from '../../../ScriptForm/ProductSearchContextSlice'

interface FullPIDetailsModal {
  fullProductInformation: ProductDetails['fullProductInformation']
}

const FullPIDetailsModal: React.FC<FullPIDetailsModal> = ({ fullProductInformation }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const prepareFullPIText = (text?: string) => {
    if (!text) {
      return ''
    }
    try {
      return text?.replaceAll(
        /(https:\/\/\w+.mims.com\/au\/public\/v2\/images\/\w+\/\w+\.gif)/gi,
        "<img src='$1' alt='medicine details'/>"
      )
    } catch (error) {
      console.error(error)
      return text
    }
  }

  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        colorScheme="blue"
        textDecoration="underline"
        size="sm"
      >
        Click to View Full Product Information
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Full Product Information Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {fullProductInformation.map(({ header, text, subContent }) => {
              return (
                <VStack alignItems="flex-start" mb={5}>
                  <Heading as="h5" size="sm">
                    {header}
                  </Heading>
                  {subContent ? (
                    subContent.map(({ text, header }) => (
                      <VStack alignItems="flex-start">
                        <Heading as="h6" size="xs">
                          {header}
                        </Heading>
                        <Text>{parse(prepareFullPIText(text))}</Text>
                      </VStack>
                    ))
                  ) : (
                    <Text>{parse(prepareFullPIText(text))}</Text>
                  )}
                </VStack>
              )
            })}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FullPIDetailsModal
