import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import { PrescriberClient } from '../../NewScriptPage/ScriptForm/PrescriberSlice'
import LogoutWarningModal from '../components/LogoutWarningModal'
import UserAgreementModal from '../components/UserAgreementModal'
import getMimsEula from './services/getMimsEula'
import { EULA_SERVICE_NAME } from '../constant'
import GoogleAnalyticsTagManager from '../../../utils/google-analytics/GoogleAnalyticsTagManager'
import { useGAContextData } from '../../../utils/google-analytics/useGAContextData'

const warningContent = `Please accept our agreement to continue. It outlines the terms and conditions of using
          MIMS's services. If you choose not to accept, you will be logged out of the system. Thank
          you for your cooperation.`
// Eula: End User License Agreement
const MimsEulaModal: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const GAContextData = useGAContextData()

  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const [isOpen, setIsOpen] = useState(false)
  const [isLogoutWarningModalOpen, setIsLogoutWarningModalOpen] = useState(false)
  const [latestMimsEula, setLatestMimsEula] = useState<any | null>(null)

  useEffect(() => {
    if (latestMimsEula) {
      return
    }

    const fetchLatestMimsEula = async () => {
      const mimsEula = await getMimsEula()
      setLatestMimsEula(mimsEula)
    }

    fetchLatestMimsEula()
  }, [latestMimsEula])

  useEffect(() => {
    const eula = prescriber?.mimsEula
    const eulaData = JSON.parse(eula || '{}')
    if (
      eulaData.revisionDate &&
      latestMimsEula?.revisionDate &&
      latestMimsEula?.revisionDate === eulaData.revisionDate
    ) {
      setIsOpen(false)
      return
    }
    if (
      eula === null ||
      (latestMimsEula?.revisionDate && eulaData.revisionDate !== latestMimsEula.revisionDate)
    ) {
      setIsOpen(true)
    }
  }, [prescriber?.mimsEula, latestMimsEula?.revisionDate])

  return (
    <>
      <UserAgreementModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        canBeBypass={false}
        onAccept={async () => {
          GoogleAnalyticsTagManager.sendFinishOnboardingEvent(GAContextData)
          await dispatch(
            PrescriberClient.AcceptAgreements({
              service: EULA_SERVICE_NAME.MIMS,
              mimsEndUserLicenseAgreement: {
                accepted: true,
                acceptedAt: new Date().getTime(),
                revisionDate: latestMimsEula.revisionDate,
              },
            })
          )
          setIsOpen(false)
        }}
        onDecline={() => setIsLogoutWarningModalOpen(true)}
      >
        <iframe
          title="MIMS EULA"
          width="100%"
          height="600px"
          src={`${process.env.REACT_APP_API_BASE_URL}/products/eula/html`}
        />
      </UserAgreementModal>
      <LogoutWarningModal
        content={warningContent}
        isOpen={isLogoutWarningModalOpen}
        onClose={() => setIsLogoutWarningModalOpen(false)}
      />
    </>
  )
}

export default MimsEulaModal
