import { FEATURES } from './features'

export const useFeatureToggle = (
  featureKey: keyof typeof FEATURES
): { isFeatureEnabled: boolean } => {
  const feature = FEATURES[featureKey]
  if (!feature) {
    return { isFeatureEnabled: false }
  }
  const toggleValue = process.env[feature.envVarName] || ''
  return { isFeatureEnabled: ['true', 'on', 'yes'].includes(toggleValue.toLowerCase()) }
}
