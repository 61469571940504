import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiClient from '../../../utils/api/ApiClient'
import { AustralianState } from '../../../types/au-states'
import { resetScript } from './PrescribedItem/PrescribedItemSlice'
import { UUID } from '../../../types/uuid'

export interface ProductSearchState {
  productSearchTerm: string
  productResults: ProductResult[]
  selectedProduct?: ProductDetails
}

const initialState: ProductSearchState = {
  productSearchTerm: '',
  productResults: [],
  selectedProduct: undefined,
}

export interface ProductResult {
  productType: 'generic' | 'branded'
  productId: UUID
  productName: string
}
export interface ProductPack {
  productPackId: '34488a01-1b45-4164-9c32-a533011832e3'
  productPackName: string
  productPackItems: ProductPackItem[]
}

export interface ProductPackItem {
  itemName: string
  dosageForm: string
  hl7DosageForm: string
}

export interface ProductImage {
  productImageId: string
  productImageName: string
}

export type PbsType =
  | 'PBS'
  | 'RPBS'
  | 'PBS/RPBS'
  | 'Authority'
  | 'Restricted'
  | 'Section 100'
  | 'Private'
export type MimsPrescriberType = 'MP' | 'NP' | 'MW' | 'OP' | 'DP' //
export interface PbsConcise {
  pbsCode: string
  pbsType: PbsType
  pbsProgram?: PbsProgram
  prescriberTypes: MimsPrescriberType[]
  restrictions: PbsRestriction[]
  repeats: number
  maxQtyPacks: number
  maxQtyUnits: number
  pbsManufacturerCodes: string[]
}

export interface PbsProgram {
  programCode: 'HB' | 'HS' | 'CA' | 'GE'
  programDescription: string
}
export interface PbsRestriction {
  restrictionType: string
  restrictionPreview: string | null
  streamlinedCode: string | null
  restrictionText?: string
}

export interface Molecule {
  moleculeId: string
  moleculeName: string
  moleculeStrength: string
  moleculeStrengthUnit: string
  moleculePerVol: string
  moleculePerVolUnit: string
}
export interface VirtualItemDetails {
  viId: UUID
  viName: string
  dosageForm: string[]
  hl7DosageForm: string[]
  roa: string[]
  molecules: Molecule[]
}

export interface ProductDetails {
  productId: UUID
  productName: string
  productType: 'generic' | 'branded'
  brand?: {
    brandId: string
    brandName: string
  }
  productPacks?: ProductPack[]
  offMarket?: boolean
  roas?: string[]
  vis: VirtualItemDetails[]
  amt: AMTTypes[]
  productImages?: ProductImage[]
  indications?: string[]
  acgs?: {
    acgId: string
    acgName: string
  }[]
  pregnancyCategory?: {
    categoryCode: string
    additionalNote: [null]
  }
  pbs: PbsConcise[]
  aip?: any
  stateSchedules: (StateSchedule | null)[]
  rtpmStates: RtpmStates[]
  poisonClassification?: PoisonClassification
  fullProductInformation: [
    {
      header: string
      text: string
      subContent?: [
        {
          header: string
          text: string
        }
      ]
    }
  ]
}

export interface ProductDetailsCustom {
  tradeName: string
  form: string
  strength: string
  routeAdministration: string
}

export interface PoisonClassification {
  poisonClasses: string[]
  poisonClassType: string
}

export interface StateSchedule {
  state: AustralianState
  schedules: 'S4D' | 'S11' | 'S4B'
}
export interface RtpmStates {
  rtpmStates: AustralianState
}
// Search Products
export interface SearchProductRequest {
  queryTerm: string
  pageOffset: number
  pageNumber: number
}
export interface SearchProductResponse {
  products: ProductResult[]
}
const searchProduct = async (request: SearchProductRequest): Promise<SearchProductResponse> => {
  const { queryTerm, pageOffset, pageNumber } = request
  const response = await ApiClient.get(
    `/products/search?queryTerm=${queryTerm}&pageOffset=${pageOffset}&pageNumber=${pageNumber}`
  )

  return response.data
}

// Get Product Details
export interface GetProductDetailsRequest {
  id: UUID
  productType: 'generic' | 'branded'
}
export interface GetProductDetailsResponse {
  product: ProductDetails
}

const getProductDetails = async (
  request: GetProductDetailsRequest
): Promise<GetProductDetailsResponse> => {
  const response = await ApiClient.get(
    `/products/${request.id}?product_type=${request.productType}`
  )
  return response.data
}

// Get Full PBS Data
export interface GetFullPbsDataRequest {
  pbsCode: string
}
export type GetFullPbsDataResponse = PbsConcise
const getFullPbsData = async (request: GetFullPbsDataRequest): Promise<GetFullPbsDataResponse> => {
  const response = await ApiClient.get(`/products/pbs/${request.pbsCode}`)
  return response.data
}

export interface TradeProductPack {
  tppCode: string
  tppName: string
}

export interface MedicinalProductPack {
  mppCode: string
  mppName: string
}

export interface AMTTypes {
  tpCode: string
  tpName: string
  tpps: TradeProductPack[]
  mpps: MedicinalProductPack[]
}

export const ProductSearchClient = {
  searchProduct,
  getProductDetails,
  getFullPbsData,
}

export const ProductSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    // Product Search
    setSearchTerm: (state, { payload: searchTerm }: PayloadAction<string>) => {
      state.productSearchTerm = searchTerm
    },
    setProductResults: (state, { payload: productResults }: PayloadAction<ProductResult[]>) => {
      state.productResults = [...state.productResults, ...productResults]
    },
    selectProduct: (
      state,
      { payload: productDetails }: PayloadAction<ProductDetails | undefined>
    ) => {
      state.selectedProduct = productDetails
    },
    resetProductResults: (state) => {
      state.productResults = []
    },
  },
  extraReducers(builder) {
    builder.addCase(resetScript, (state) => {
      Object.assign(state, initialState)
    })
  },
})

export const { setSearchTerm, setProductResults, selectProduct, resetProductResults } =
  ProductSearchSlice.actions

export default ProductSearchSlice.reducer
