import { endOfMonth, format, isValid } from 'date-fns'
import { AustralianState, Patient } from '../../types'
import { ImportedPatient } from '../../types/patient'
import { validateHumanName } from '../../utils/validation-helpers/Common'
import { isValidDvaCardColour } from '../../utils/validation-helpers/DvaValidate'
import {
  addressValidator,
  convertDateString,
  dvaFileNumberValidator,
  emailValidator,
  medicareIrnValidator,
  medicareNumberValidator,
  phoneNumberValidator,
  postcodeValidator,
} from './utils/patientFieldsValidator'

const validatePatientData = (patient: ImportedPatient): boolean | string[] => {
  const fieldsValidationRules: {
    [key in keyof Partial<Patient>]: {
      required?: boolean
      validator?: (value: string) => boolean | string
    }
  } = {
    given_names: {
      required: true,
      validator: (value: string) => {
        const result = validateHumanName(value)
        return result.isValid || result.displayValue
      },
    },
    family_name: {
      required: true,
      validator: (value: string) => {
        const result = validateHumanName(value)
        return result.isValid || result.displayValue
      },
    },
    gender: {
      required: true,
      validator: (value: string) => {
        return (
          /[FMIN]/.test(value) ||
          'Please set a valid gender, We only support one of the values ( F, M, I, N ) corresponding to "Female", "Male", "Intersex or Indeterminate" and "Not Stated".'
        )
      },
    },
    date_of_birth: {
      required: true,
      validator: (value: string) => {
        const isValidDate =
          /^(?:(?:[\d]+\.[\d]*])|(?:\d{4}-\d{2}-\d{2})|(?:\d{2}\d{2}\d{4})|(?:\d{4}\d{2}\d{2}))$/.test(
            value
          ) && isValid(new Date(value))
        return (
          isValidDate ||
          'Failed to validate Date of Birth format, please use YYYY-MM-DD or DD/MM/YYYY or YYYY/MM/DD format,such as 1978-01-31 or 31/01/1978 or 1978/01/31'
        )
      },
    },
    address_1: {
      required: true,
      validator: (value: string) => {
        return addressValidator(value)
      },
    },
    address_2: {
      validator: (value: string) => {
        return addressValidator(value)
      },
    },
    suburb: {
      required: true,
      validator: (value: string) => {
        const result = validateHumanName(value)
        return result.isValid || result.displayValue
      },
    },
    postcode: {
      required: true,
      validator: (value: string) => {
        return postcodeValidator(value)
      },
    },
    state: {
      required: true,
      validator: (value: string) => {
        return (
          Object.values(AustralianState).includes(value as AustralianState) ||
          `Please set a valid State, such as ${Object.values(AustralianState)}`
        )
      },
    },
    phone_number: {
      validator: (value: string) => {
        return phoneNumberValidator(value, patient.email)
      },
    },
    email: {
      validator: (value: string) => {
        return emailValidator(value, patient.phone_number)
      },
    },
    medicare_no: {
      validator: (value: string) => {
        return medicareNumberValidator(value, patient.dva_file_no)
      },
    },
    medicare_irn: {
      validator: (value: string) => {
        return medicareIrnValidator(value, patient.dva_file_no)
      },
    },
    dva_file_no: {
      validator: (value: string) => {
        return dvaFileNumberValidator(value, patient.medicare_no, patient.medicare_irn)
      },
    },
    dva_card_colour: {
      validator: (value: string) => {
        const dvaCardNumber = patient.dva_file_no
        if (dvaCardNumber) {
          return (
            isValidDvaCardColour(String(value)) || 'Please set a valid Dva Card Colour: G, W or O'
          )
        }
        return true
      },
    },
    medicare_valid_to: {
      validator: (value: string) => {
        if (value) {
          const isValidDate =
            /^(?:(?:[\d]+\.[\d]*])|(?:\d{4}-\d{2}-\d{2})|(?:\d{2}\d{2}\d{4})|(?:\d{4}\d{2}\d{2}))$/.test(
              value
            ) && isValid(new Date(value))
          return (
            isValidDate ||
            'Failed to validate medicare expire date format, please use YYYY-MM-DD or DD/MM/YYYY or YYYY/MM/DD format,such as 1978-01-31 or 31/01/1978 or 1978/01/31'
          )
        }
        return true
      },
    },
  }
  const errors: string[] = []
  for (const [key, value] of Object.entries(fieldsValidationRules)) {
    const fieldValue = `${patient[key as keyof Partial<Patient>] ?? ''}`
    if (value.required && !fieldValue) {
      errors.push(`Error - ${key} is required`)
      continue
    }
    if (value.validator) {
      const isFieldValid = value.validator(fieldValue)
      if (isFieldValid !== true) {
        errors.push(`Error - field: ${key},  value: ${fieldValue}, reason: ${isFieldValid}`)
      }
    }
  }
  //TODO: just return true if no errors, should consider how to dispaly errors in the future
  return errors.length === 0
}

const convertStringToBoolean = (value: string) => {
  return /(^[1]$|^(true)$)/i.test(value)
}

const convertToFormatData = (patients: ImportedPatient[]): Partial<Patient>[] =>
  (patients as Partial<Patient>[]).map((patient) => {
    return {
      ...patient,
      postcode: Number(patient.postcode),
      hospital_category: !!patient.hospital_provider_number,
      ctg_flag: convertStringToBoolean(`${patient.ctg_flag}`),
      paper_only: convertStringToBoolean(`${patient.paper_only}`),
      medicare_valid_to:
        patient.medicare_valid_to &&
        format(endOfMonth(new Date(patient.medicare_valid_to)), 'yyyy-MM-dd'),
    }
  })

export const convertToImportData = (patients: Partial<Patient>[]): ImportedPatient[] =>
  patients.map((patient) => {
    const importedPatient = {
      ...patient,
      postcode: `${patient.postcode}`,
      ctg_flag: patient.ctg_flag ? '1' : 'No',
      paper_only: patient.paper_only ? '1' : 'No',
    }
    delete importedPatient.hospital_category
    delete importedPatient.ihi_records
    delete importedPatient.ihi_number
    delete importedPatient.record_status
    delete importedPatient.ihi_status
    return importedPatient as ImportedPatient
  })

export const transformAndValidatePatients = (data: Partial<Patient>[]) => {
  const transformedData: ImportedPatient[] = transformDataToPatients(data)
  const { validPatients, invalidPatients } = transformedData.reduce(
    (results, patient) => {
      const isValidPatient = validatePatientData(patient)
      if (isValidPatient) {
        results.validPatients.push(patient)
      } else {
        results.invalidPatients.push(patient)
      }
      return results
    },
    { validPatients: [] as ImportedPatient[], invalidPatients: [] as ImportedPatient[] }
  )
  const formattedPatients = convertToFormatData(validPatients)
  return { formattedPatients, invalidPatients }
}

const transformDataToPatients = (data: Partial<Patient>[]): ImportedPatient[] => {
  return data.map((item): ImportedPatient => {
    const {
      given_names,
      family_name,
      email,
      phone_number,
      gender,
      date_of_birth,
      medicare_no,
      medicare_irn,
      dva_file_no,
      dva_card_colour,
      address_1,
      address_2,
      suburb,
      postcode,
      state,
      paper_only,
      entitlement_no,
      concession_pension_no,
      ctg_flag,
      hospital_provider_number,
      racfid,
      medicare_valid_to,
    } = item
    const patient = {
      given_names,
      family_name,
      email,
      phone_number,
      gender,
      date_of_birth,
      medicare_no,
      medicare_irn,
      dva_file_no,
      dva_card_colour,
      medicare_valid_to,
      address_1,
      address_2,
      suburb,
      postcode,
      state,
      entitlement_no,
      concession_pension_no,
      ctg_flag,
      hospital_provider_number,
      racfid,
      paper_only,
    }
    const trimedData: ImportedPatient = Object.fromEntries(
      Object.entries(patient).map(([key, value]) => [key, `${value ?? ''}`.trim()])
    )
    trimedData.date_of_birth = convertDateString(trimedData.date_of_birth)
    if (trimedData?.phone_number && trimedData.phone_number?.length < 10) {
      trimedData.phone_number = '0' + trimedData.phone_number
    }
    if (trimedData?.postcode && trimedData.postcode?.length < 4) {
      trimedData.postcode = '0' + trimedData.postcode
    }
    trimedData.medicare_valid_to = convertDateString(trimedData.medicare_valid_to)
    return trimedData
  })
}

export const generateDisplayedError = (error: any): string => {
  if (error?.reason?.name === 'ConflictException') {
    return 'This patient could not be imported - it appears this patient record already exists in your account.'
  }
  return error?.reason?.response?.message
}

export const filiterOutDuplicatePatients = (formattedPatients: Partial<Patient>[]) => {
  const uniquePatientsInfo = new Set<string>()
  return formattedPatients.reduce(
    (result, patient) => {
      const { given_names, family_name, gender, date_of_birth } = patient
      const patientInfo = `${given_names}_${family_name}_${gender}_${date_of_birth}`.toLowerCase()
      if (!uniquePatientsInfo.has(patientInfo)) {
        uniquePatientsInfo.add(patientInfo)
        result.uniqueInfoPatients.push(patient)
      } else {
        result.duplicateInfoPatients.push(patient)
      }
      return result
    },
    {
      uniqueInfoPatients: [] as Partial<Patient>[],
      duplicateInfoPatients: [] as Partial<Patient>[],
    }
  )
}

export const filiterOutIHIduplicatePatients = (ihiValidPatients: Partial<Patient>[]) => {
  const uniqueIHI = new Set<string>()
  return ihiValidPatients.reduce(
    (result, patient) => {
      if (patient.ihi_number && !uniqueIHI.has(patient.ihi_number)) {
        uniqueIHI.add(patient.ihi_number)
        result.uniqueIHIPatients.push(patient)
      } else {
        result.duplicateIHIPatients.push(patient)
      }
      return result
    },
    {
      uniqueIHIPatients: [] as Partial<Patient>[],
      duplicateIHIPatients: [] as Partial<Patient>[],
    }
  )
}
