import {
  Button,
  Heading,
  HStack,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { useState, KeyboardEvent } from 'react'
import useApi from '../../common/utils/api/useApi'
import { AllergenItem, ExperimentsApiClient } from './AllergensSlice'

export const ExperimentsPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [allergens, setAllergens] = useState<AllergenItem[]>([])
  const [errorMsg, setErrorMsg] = useState<string>('')
  const { trigger: getAllergens, loading } = useApi(ExperimentsApiClient.searchAllergens, {
    onSuccess: (resp) => {
      setErrorMsg('')
      setAllergens(resp.allergens)
    },
  })
  const searchAllergens = async () => {
    if (searchTerm?.trim()?.length <= 2) {
      setErrorMsg('Search keyword should more than 2 letters')
      return
    }
    await getAllergens({
      queryTerm: searchTerm.trim(),
    })
  }

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await searchAllergens()
    }
  }
  return (
    <VStack alignItems={'start'} p={8} width={'100%'} spacing={16}>
      <Heading>Search Allergens</Heading>
      <Stack width={'50%'} spacing={8}>
        <HStack spacing={4}>
          <Stack fontSize={'xl'}>
            <strong>Allergen keyword :</strong>
          </Stack>
          <Input
            width={'50%'}
            placeholder=""
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            borderColor={'lightsteelblue'}
            onKeyDown={handleKeyPress}
          />
          <Button
            colorScheme="blue"
            width={'20%'}
            isLoading={loading}
            disabled={loading}
            onClick={searchAllergens}
          >
            Search
          </Button>
        </HStack>
        {errorMsg?.length > 0 && (
          <Text color={'red.600'} alignSelf={'center'}>
            {errorMsg}
          </Text>
        )}
      </Stack>
      {allergens?.length > 0 ? (
        <TableContainer overflowY={'auto'} maxWidth={'100%'}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Allergen Name</Th>
                <Th>Allergen Type</Th>
              </Tr>
            </Thead>
            <Tbody>
              {allergens.map((allergen) => (
                <Tr>
                  <Text fontSize="md" wordBreak="break-word" whiteSpace="pre-wrap">
                    {allergen.allergenName}
                  </Text>
                  <Td>{allergen.allergenType}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Stack width={'800px'} height={'300px'} alignItems={'center'} justifyContent={'center'}>
          <Text fontSize={'2xl'}>No results found...</Text>
        </Stack>
      )}
    </VStack>
  )
}
